import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-realtors-page',
  templateUrl: './realtors-page.component.html',
  styleUrls: ['./realtors-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RealtorsPageComponent implements OnInit {
  realtorPlans = [];
  constructor(private router: Router, private proxyService: ProxyServiceService, private loginService: LoginService) { }


  ngOnInit() {
    this.proxyService.getMemberships().subscribe((values) => {
      values.forEach(element => {
        if (element.type === 1) { // 1 = Realtor
          element.description = element.description.split(',');
          this.realtorPlans.push(element);
        }
      });
    });

    if (this.loginService.token !== "") {
      this.router.navigateByUrl('/agent-signup');
    }

    this.loginService.profileUpdated.subscribe(profileInfo => {
      this.router.navigateByUrl('/agent-signup');
    });
  }

  realtorSignup() {
    // this.loginService.agreeBeforeSignup(() => {
    //   this.router.navigate(['/agent-signup']);
    // });
    this.loginService.signUpNoAgreement();
  }
}