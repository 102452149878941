import { Component, OnInit, HostListener, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import { TokenCacheService } from '../services/token-cache.service';
import { LoginService } from '../services/login.service';
import { DialogService } from 'primeng/api';
import { MenuItem } from "primeng/api";
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FooterSectionComponent implements OnInit {
  user: any;
  enableEditProfile: boolean;

  editToggle = false;
  @ViewChild("userTag", { static: false }) userTag: ElementRef;
  items: MenuItem[];

  constructor(
    public loginService: LoginService,
    private tokenCacheService: TokenCacheService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.user = this.tokenCacheService.userCache;
  }

  toggleDropdown() {
    this.editToggle = !this.editToggle;
  }

  signUp() {
    this.loginService.signUpNoAgreement();
  }

  login() {
    this.loginService.login();
  }

  logout() {
    this.loginService.logout();
  }
  ngOnDestroy() {
    this.loginService.cleanupService();
  }

}
