import { trigger, style, transition, query, animate, stagger, state } from '@angular/animations';
export const SavedSearchAnimations = {
    slideUpIn: trigger('slideUpIn', [
        transition('* <=> *', [
            query(
                ':enter',
                [
                    style({ opacity: 0, transform: 'translateY(100px)' }),
                    stagger(
                        '250ms',
                        animate(
                            '750ms ease-out',
                            style({ opacity: 1, transform: 'translateY(0px)' })
                        )
                    )
                ],
                { optional: true }
            ),
            query(':leave', [
                style({transform: 'scale(1)', opacity: 1}),
                animate('250ms', style({
                    transform: 'scale(0)',
                    opacity: 0
                }))
            ], {
                optional: true
            })
        ])
    ])
};
