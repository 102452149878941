import { AgentSelectionDialogComponent } from './dialogs/agent-selection-dialog/agent-selection-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgModule } from '@angular/core';

import { MenuModule } from 'primeng/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HeaderSectionComponent } from './header-section/header-section.component';
import { BannerSectionComponent } from './home-page/banner-section/banner-section.component';
import { FilterSearchComponent } from './home-page//filter-search/filter-search.component';
import { FeatureListingsSectionComponent } from './search-results-page/feature-listings-section/feature-listings-section.component';
import { ReviewSectionComponent } from './home-page//review-section/review-section.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { ContactSectionComponent } from './contact-page/contact-section/contact-section.component';
import { CommonBannerSectionComponent } from './common-banner-section/common-banner-section.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SingleListingPageComponent } from './single-listing-page/single-listing-page.component';
import { ListingSectionComponent } from './single-listing-page/listing-section/listing-section.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { AboutSectionComponent } from './about-us-page/about-section/about-section.component';
import { CarouselComponent } from './utils/carousel/carousel.component';
import { MapsComponentComponent } from './maps-component/maps-component.component';
import { VideoBannerComponent } from './home-page/video-banner/video-banner.component';
import { DownloadAppPageComponent } from './download-app-page/download-app-page.component';
import { SearchResultsPageComponent } from './search-results-page/search-results-page.component';
import { BuyersPageComponent } from './buyers-page/buyers-page.component';
import { RealtorsPageComponent } from './realtors-page/realtors-page.component';
import { SellersPageComponent } from './sellers-page/sellers-page.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AlertMessageDialogComponent } from './dialogs/alert-message-dialog/alert-message-dialog.component';
import { CommonModule } from '@angular/common';
import { ScheduleAppDialogComponent } from './dialogs/schedule-app-dialog/schedule-app-dialog.component';
import { CalendarCarouselComponent } from './calendar-carousel/calendar-carousel.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MsalModule } from '@azure/msal-angular';
import { PhonePipe } from '../app/utils/pipes/phone.pipe';
import { GlobalConfirmationComponent } from './global-confirmation/global-confirmation.component';
import { ScheduleSavingDialogComponent } from './single-listing-page/schedule-saving-dialog/schedule-saving-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { VideoPopupComponent } from './home-page/video-banner/video-popup/video-popup.component';
import { TokenCacheService } from './services/token-cache.service';
import { AgentSignupComponent } from './agent-signup/agent-signup.component';
import { MainLoaderComponent } from './main-loader/main-loader.component';
import { AgentSignupSavingDialogComponent } from './agent-signup/agent-signup-saving-dialog/agent-signup-saving-dialog.component';
import { AgentSignupConfirmationComponent } from './agent-signup-confirmation/agent-signup-confirmation.component';
import { NgxBraintreeModule } from 'ngx-braintree';
import { ConfirmAppointmentComponent } from './dialogs/confirm-appointment/confirm.appointment.component';
import { ScheduleConfirmationPageComponent } from './schedule-confirmation-page/schedule-confirmation-page.component';
import { SearchFilterComponent } from './search-results-page/search-filter/search-filter.component';
import { MiddleSectionComponent } from './home-page/middle-section/middle-section.component';
import { LoginService } from './services/login.service';
import { PrimeNgModule } from './prime-ng/prime-ng.module';
import { NumeralModule } from 'ngx-numeral';
import { CommonSearchComponent } from './common-search/common-search.component';
import { TermsAndConditionsComponent } from './dialogs/terms-and-conditions/terms-and-conditions.component';
import { DialogService } from 'primeng/api';
import { QrcodeSignupComponent } from './qrcode-signup/qrcode-signup.component';
import { QrcodeRiderComponent } from './qrcode-rider/qrcode-rider.component';
import { ConfirmProfessionalScheduleComponent } from './dialogs/confirm-professional-schedule/confirm-professional-schedule.component';
import { QRCodeModule } from 'angular2-qrcode';
import { QrcodeAgentComponent } from './qrcode-agent/qrcode-agent.component';
import { QrcodeMyagentComponent } from './qrcode-myagent/qrcode-myagent.component';
import { SchedulePremessageDialogComponent } from './dialogs/schedule-premessage-dialog/schedule-premessage-dialog.component';
import { PromocodeDialogComponent } from './dialogs/promocode-dialog/promocode-dialog.component';
import { QrcodeAccessConfirmationComponent } from './qrcode-access-confirmation/qrcode-access-confirmation.component';
import { SavedSearchesPageComponent } from './saved-searches-page/saved-searches-page.component';
import { ProfessionalSearchDialogComponent } from './dialogs/professional-search-dialog/professional-search-dialog.component';
import { PromoPageComponent } from './promo-page/promo-page.component';
import { PromocertPageComponent } from './promocert-page/promocert-page.component';
import { RequestInfoGuestDialogComponent } from './dialogs/request-info-guest-dialog/request-info-guest-dialog.component';
import { AgentSignupPageComponent } from './agent-signup-page/agent-signup-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { Ng5SliderModule } from 'ng5-slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MapService } from './services/map.service';
import { ChartsModule } from 'ng2-charts';
import { NerenPageComponent } from './neren-page/neren-page.component';
import { LightningSetupComponent } from './lightning-setup/lightning-setup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentConfirmComponent } from './appointment-confirm/appointment-confirm.component';
import { FeedbackformComponent } from './feedbackform/feedbackform.component';
import { LsHomeComponent } from './lightning-setup/ls-home/ls-home.component';
import { LsEditComponent } from './lightning-setup/ls-edit/ls-edit.component';
import { LsSetupComponent } from './lightning-setup/ls-setup/ls-setup.component';
import { ScheduleComponent } from './lightning-setup/ls-setup/popup/schedule/schedule.component';
import { NgxLoadingModule } from 'ngx-loading';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderSectionComponent,
    BannerSectionComponent,
    FilterSearchComponent,
    FeatureListingsSectionComponent,
    ReviewSectionComponent,
    FooterSectionComponent,
    ContactSectionComponent,
    ContactPageComponent,
    BreadCrumbComponent,
    CommonBannerSectionComponent,
    RegisterPageComponent,
    LoginPageComponent,
    SingleListingPageComponent,
    ListingSectionComponent,
    AboutUsPageComponent,
    AboutSectionComponent,
    CarouselComponent,
    MapsComponentComponent,
    VideoBannerComponent,
    DownloadAppPageComponent,
    SearchResultsPageComponent,
    BuyersPageComponent,
    RealtorsPageComponent,
    SellersPageComponent,
    AlertMessageDialogComponent,
    ScheduleAppDialogComponent,
    CalendarCarouselComponent,
    AgentSelectionDialogComponent,
    PhonePipe,
    NotFoundComponent,
    VideoPopupComponent,
    AgentSignupComponent,
    ConfirmAppointmentComponent,
    AgentSignupSavingDialogComponent,
    AgentSignupConfirmationComponent,
    MainLoaderComponent,
    GlobalConfirmationComponent,
    ScheduleSavingDialogComponent,
    ScheduleConfirmationPageComponent,
    SearchFilterComponent,
    MiddleSectionComponent,
    CommonSearchComponent,
    TermsAndConditionsComponent,
    QrcodeSignupComponent,
    QrcodeRiderComponent,
    ConfirmProfessionalScheduleComponent,
    QrcodeAgentComponent,
    QrcodeMyagentComponent,
    SchedulePremessageDialogComponent,
    PromocodeDialogComponent,
    QrcodeAccessConfirmationComponent,
    SavedSearchesPageComponent,
    ProfessionalSearchDialogComponent,
    PromoPageComponent,
    PromocertPageComponent,
    RequestInfoGuestDialogComponent,
    AgentSignupPageComponent,
    SearchPageComponent,
    NerenPageComponent,
    LightningSetupComponent,
    AppointmentConfirmComponent,
    FeedbackformComponent,
    LsHomeComponent,
    LsEditComponent,
    LsSetupComponent,
    ScheduleComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxBraintreeModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    DynamicDialogModule,
    MenuModule,
    GooglePlaceModule,
    NgxLoadingModule.forRoot({}),
    MsalModule.forRoot({
      auth: {
        clientId: '8870b782-a80e-47b1-9b0d-77576cdc2751',
        //redirectUri: 'http://localhost:4200',
        //redirectUri: "https://secure.domii.io",
        redirectUri: "https://staging.domii.io",
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
      },
      framework: {
        isAngular: true
      },
      cache: {
        cacheLocation: 'sessionStorage'
      }
    }, {
      consentScopes: ['https://IA9B2C.onmicrosoft.com/backend/user_impersonation']
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC5RNSiVUXdUXka6H9l_Qpp0vvC9G4FMnY',
      apiVersion: '3',
      libraries: ['geometry', 'places', 'drawing']

    }),
    NgbModule,
    AgmOverlays,
    AgmJsMarkerClustererModule,
    Ng5SliderModule,
    PrimeNgModule,
    NumeralModule.forRoot(),
    QRCodeModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    ScrollToModule.forRoot(),
    ChartsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  entryComponents: [
    ScheduleComponent,
    AlertMessageDialogComponent,
    ScheduleAppDialogComponent,
    VideoPopupComponent,
    AgentSignupSavingDialogComponent,
    ScheduleSavingDialogComponent,
    TermsAndConditionsComponent,
    ConfirmProfessionalScheduleComponent,
    SchedulePremessageDialogComponent,
    ProfessionalSearchDialogComponent,
    PromocodeDialogComponent,
    RequestInfoGuestDialogComponent
  ],
  providers: [TokenCacheService, DialogService, LoginService, MapService],
  bootstrap: [AppComponent]
})
export class AppModule { }
