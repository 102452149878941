import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { AppInfoService } from '../services/app-info.service';
import { TokenCacheService } from '../services/token-cache.service';
import { DialogService } from 'primeng/api';
import { PromocodeDialogComponent } from '../dialogs/promocode-dialog/promocode-dialog.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-qrcode-myagent',
  templateUrl: './qrcode-myagent.component.html',
  styleUrls: ['./qrcode-myagent.component.less']
})
export class QrcodeMyagentComponent implements OnInit {

  public routeSub: any;
  public agentUniqueId: string = null;
  public professionalInfo: any = null;
  public userLoggedIn: boolean = false;
  public invalidResponse: boolean = false;
  public fetchingData: boolean = false;
  public user: any;
  public validatedPromoCode: string = '';
  public myAgentQRCodeLoadingMessage: string = 'Loading QRCode Access Page';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private serviceProxy: ProxyServiceService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private tokenCacheService: TokenCacheService
    ) { }

  ngOnInit() {

    this.user = this.tokenCacheService.userCache;

    this.validatedPromoCode = sessionStorage['validatedPromoCode'] || '';

    this.loginService.profileUpdated.subscribe((profileInfo: any) => {
      this.userLoggedIn = this.loginService.token !== '';

      this.serviceProxy.associateBuyerWithAgent(this.agentUniqueId, false).subscribe((res: any) => {

        // TODO: test for freemium listing pages, or remove
        /*res.MembershipLevel = -100;
        res.AgentListings = [];
        res.AgentListings.push({ RetsId: '72566952', MlsId: null, ListingId: null });*/

        // check whether the user has freemium membership and has at least one listing
        if (res.MembershipLevel === -100 && res.AgentListings.length > 0) {
          var firstAgentListing = res.AgentListings[0];
          var listingId = '';
          var idType = 'listingId';

          // first, check if RetsID is available
          if (firstAgentListing.RetsId) {
            listingId = firstAgentListing.RetsId;
            idType = 'retsId';
          }
          // second, check if the MlsID is available
          else if (firstAgentListing.MlsId) {
            listingId = firstAgentListing.MlsId;
            idType = 'mlsId';
          }
          // third, then go for the regular listing Id
          else if (firstAgentListing.ListingId) {
            listingId = firstAgentListing.ListingId;
            idType = 'listingId';
          }

          this.router.navigate(['listing/', listingId], { queryParams: { agentId: this.agentUniqueId, idType: idType }});
          return;
        }

        this.fetchingData = false;
        this.professionalInfo = res;
      }, (error: any) => {
        this.invalidResponse = true;
        this.fetchingData = false;
        this.agentUniqueId = null;
      });

    }, (error: any) => {
      this.fetchingData = false;
      this.agentUniqueId = null;
    });

    this.routeSub = this.activatedRoute.params.subscribe(params => {
      // check if this is an edit profile workflow
      
      this.agentUniqueId = params.id;
      this.fetchingData = true;
      this.loginService.checkForProfileInfo();
    });

    // the only way to hide the hubspot from within this component
    setTimeout(() => {
      $("#hubspot-messages-iframe-container").remove();
    }, 500);
  }

  connectToAgent() {

      const sessionValue = sessionStorage['qrcode-signup'];

      if (!this.validatedPromoCode) {
        this.validatedPromoCode = sessionStorage['validatedPromoCode'] || '';
      }

      sessionStorage.removeItem('validatedPromoCode');

      this.serviceProxy.associateBuyerWithAgent(this.agentUniqueId, this.userLoggedIn, this.validatedPromoCode).subscribe((res: any) => {
        this.fetchingData = false;
        this.professionalInfo = res;

        this.router.navigate(['/qrcode-access-confirmation'], {
          state: {
            associatedAgent: this.professionalInfo
          }
        });

      }, (error: any) => {
        this.invalidResponse = true;
        this.fetchingData = false;
        this.agentUniqueId = null;
      });
      
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  signUp() {
    this.loginService.signUpNoAgreement();
  }

  login() {
    this.loginService.login();
  }

  logout() {
    sessionStorage.removeItem('qrcode-signup');
    sessionStorage.removeItem('validatedPromoCode');
    this.loginService.logout();
  }

  launchPromocode() {
    const promoDialog = this.dialogService.open(PromocodeDialogComponent, {
      styleClass: 'promocode-msg-dialog',
      data: {
        uniqueAgentId: this.agentUniqueId,
      },
      header: 'Promo Code Validation',
      transitionOptions: '1ms',
    });

    promoDialog.onClose.subscribe((promocodePayload: any) => {
      if (promocodePayload && promocodePayload.Code !== '') {
        this.validatedPromoCode = promocodePayload.Code;
        sessionStorage['validatedPromoCode'] = this.validatedPromoCode;
      }
    });
  }
}
