import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-schedule-confirmation-page',
  templateUrl: './schedule-confirmation-page.component.html',
  styleUrls: ['./schedule-confirmation-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleConfirmationPageComponent implements OnInit {

  property: any;
  agent: any;
  date: Date;
  payload: any;
  user: any;
  useAgent: boolean;

  constructor(private router: Router, private msalService: MsalService) { 
    this.payload = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {

    if (!this.payload) {
      this.router.navigate(['/']);
    }
    else {
      this.property = this.payload.property;
      this.agent = this.payload.agent;
      this.useAgent = this.payload.useAgent;
      this.date = this.payload.date;
      this.user = this.msalService.getAccount();
    }
  }

}
