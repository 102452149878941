import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogService } from 'primeng/api';
import { VideoPopupComponent } from './video-popup/video-popup.component';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-video-banner',
  templateUrl: './video-banner.component.html',
  styleUrls: ['./video-banner.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class VideoBannerComponent implements OnInit {

  mainUrl: 'https://scheduleyourappt.com';

  constructor(
    private loginService: LoginService,
    private dialogService: DialogService, 
    private router: Router) { }

  ngOnInit() {
  }

  onRealtorVideoClick() {
    this.showVideo('For Real Estate Agents', 'https://ia9incextra.blob.core.windows.net/blob/realtor_video.mp4');
  }

  onBuyerVideoClick() {
    this.showVideo('For Buyers', 'https://ia9incextra.blob.core.windows.net/blob/buyer_video.mp4');
  }

  onIAProfessionalVideoClick() {
    this.showVideo('For IA9 Professionals', 'https://ia9incextra.blob.core.windows.net/blob/professional_video.mp4');
  }

  showVideo(title: string, videoLink: string) {
    const dialogRef = this.dialogService.open(VideoPopupComponent, {
      header: title,
      transitionOptions: '1ms',
      styleClass: "realtor-video",
      dismissableMask: true,
      data: {
        videoPath: videoLink
      }
    });
  }

  signUpAsAgent() {
    this.loginService.signUpAsAgent();
  }

  signUpAsBuyer() {
    this.loginService.signUp();
  }
}
