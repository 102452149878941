import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ProxyServiceService } from '../services/proxy-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/api';
import { AlertMessageDialogComponent } from '../dialogs/alert-message-dialog/alert-message-dialog.component';

@Component({
  selector: 'app-promo-page',
  templateUrl: './promo-page.component.html',
  styleUrls: ['./promo-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PromoPageComponent implements OnInit {

  public routeSub: any;
  public userLoggedIn: boolean;
  public promoCodeValue: string;
  public validatedCashback: any;

  constructor(private loginService: LoginService,
    private proxyService: ProxyServiceService,
    private router: Router,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.loginService.profileUpdated.subscribe((profileInfo: any) => {
      this.userLoggedIn = this.loginService.token !== '';

      if (this.userLoggedIn && sessionStorage.getItem('promo-registration') !== null) {
        sessionStorage.removeItem('promo-registration');
        this.performPromoCodePosting();
      }

    }, (error: any) => {
      this.validatedCashback = null;
    });

    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.promoCodeValue = params.promocode;

      this.proxyService.validatePromoCode(encodeURI(this.promoCodeValue), '')
        .subscribe((response) => {
          this.validatedCashback = response;
        },
        error => {
          this.validatedCashback = null;
        });
    });

  }

  signIn() {
    // set this temporary item on session storage to determine that the page refresh
    // will execute because it came from triggering the registration upon a login
    sessionStorage.removeItem('promo-registration');
    this.loginService.login();
  }

  onPromoCodeRegister() {
    // set this temporary item on session storage to determine that the page refresh
    // will execute because it came from triggering the registration upon a login
    if (this.userLoggedIn) {
      this.performPromoCodePosting();
    }
    else {
      sessionStorage.setItem('promo-registration', 'promo-registration');
      this.loginService.login();
    }
  }

  performPromoCodePosting() {
    this.proxyService.postPromoCode(encodeURI(this.validatedCashback.Code)).
        subscribe((response) => {
          // redirect and create certificate
          this.router.navigate(["/promocert/" + response.CertificateId], {
            state: response
          });
        },
        error => {

          const promoRegisterError = this.dialogService.open(AlertMessageDialogComponent, {
            styleClass: 'alert-msg-dialog',
            data: {
              icon: 'pi-exclamation-triangle',
              message: 'Sorry, we couldn\'t register you.',
              subtitle: error.error.split(":")[1].replace(/"/g, ''),
              buttonOptions: {
                ok: {
                  label: 'OK',
                  command: () => { promoRegisterError.close(); }
                }
              }
            },
            header: error.statusText,
            transitionOptions: '1ms',
          });
        });
  }
}
