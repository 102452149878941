import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class NgxLoadingServiceService {
  private loading = new BehaviorSubject<Array<boolean>>([]);
  constructor(private toastr: ToastrService) { }
  getLoading(): Observable<Array<boolean>> {
    return this.loading.asObservable();
  }
  startLoading() {
    const v = this.loading.getValue();
    v.push(true)
    this.loading.next(v);
  }
  endLoading() {
    const v = this.loading.getValue();
    if (v.length) {
      v.pop();
    }
    this.loading.next(v);
  }
  handleError(errRes) {
    let errorMessage: string;
    if (errRes.error && errRes.error.description) {
      errorMessage = `${errRes.error.description}`;
    } else {
      errorMessage = 'There was an issue, please try again or check back later.';
    }
    console.error(errRes);
    this.toastr.error(errorMessage);
    return throwError(errorMessage);
  }
}
