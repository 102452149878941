import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProxyServiceService } from '../services/proxy-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LightningValidateResponse, LightningData } from "./lightning-setup.model";
import { NgxLoadingServiceService } from '../services/ngx-loading-service.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-lightning-setup',
  templateUrl: './lightning-setup.component.html',
  styleUrls: ['./lightning-setup.component.less']
})
export class LightningSetupComponent implements OnInit, OnDestroy {
  lightningData: LightningData;
  errorMessage: string = null;
  notNeren: boolean;
  constructor(
    private proxyService: ProxyServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: NgxLoadingServiceService,
    private toastrService: ToastrService) {
  }
  loadLightningData(propertyId, agentId, mlsId, code, result) {
    this.proxyService.lightningValidate(propertyId, agentId, mlsId, code).subscribe((res: LightningValidateResponse) => {
      console.log(res);
      this.loadingService.startLoading();
      if (res && res.isSuccess) {
        this.loadingService.endLoading();
        this.lightningData = res.data;
        this.proxyService.setLightningData(res.data);
      }
    }, error => {
      console.log(error);
      if (error.error) {
        this.notNeren = error.error.statusCode === 1011 ? true : false;
        this.errorMessage = error.error.description ? error.error.description : 'There was an issue, please try again or check back later.';
      } else {
        this.errorMessage = 'There was an issue, please try again or check back later.';
      }
      this.toastrService.error(this.errorMessage);
      this.loadingService.endLoading();
    });

  }
  ngOnInit() {
    this.route.queryParams.subscribe(result => {
      const agentId = result['aid'];
      const mlsId = result['mid'];
      const propertyId = result['oid'];
      const code = result['code'];
      this.proxyService.getLightningData().subscribe(res => {
        console.log(res);
        if (!res) {
          this.loadLightningData(propertyId, agentId, mlsId, code, result);
        }
      });
    });
  }

  ngOnDestroy() {
    this.proxyService.setLightningData(null);
  }

}
