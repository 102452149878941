import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-listing-page',
  templateUrl: './single-listing-page.component.html',
  styleUrls: ['./single-listing-page.component.less']
})
export class SingleListingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
