import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: "root"
})
export class CalendarCarouselService {
  weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
  daysAhead = 9;
  dateObjCollection: any[];
  selectedDate: Date = null;
  combinedDate: Date = null;
  times: any[];

  constructor() {
    let date = new Date();
    this.times = [];
    this.dateObjCollection = [];

    // if it's already beyond 8pm of the current day, make sure we start on the next day.
    if (date.getHours() >= 20) {
      date = new Date(date.setDate(date.getDate() + 1));
    }

    for (let i = 0; i < this.daysAhead; i++) {
      let monthValue = this.months[date.getMonth()];
      let dayOfWeekValue = this.weekdays[date.getDay()];
      let dayValue = date.getDate();
      let currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      );
      this.dateObjCollection.push({
        month: monthValue,
        day: dayValue,
        dayOfWeek: dayOfWeekValue,
        isSelected: false,
        date: currentDate
      });
      let updatedDate = date.setDate(date.getDate() + 1);
      date = new Date(updatedDate);
    }
  }

  getDatesCollection() {
    return this.dateObjCollection;
  }

  getSelectedDate() {
    return this.selectedDate;
  }

  resetCalendar() {
    this.selectedDate = null;
    this.dateObjCollection.forEach((dateObj: any) => {
      dateObj.isSelected = false;
    });
    this.times = [];
    this.combinedDate = null;
  }

  setSelectedDate(date: any) {
    this.dateObjCollection.forEach((dateObj: any) => {
      dateObj.isSelected = false;
    });
    date.isSelected = true;
    this.selectedDate = date.date;

    this.getTimesFromSelectedDate();
  }

  setCombinedDate(date: Date) {
    this.combinedDate = date;
  }

  getTimesFromSelectedDate() {
    this.times = [];

    let actualDate = new Date();
    if (
      this.selectedDate.getDate() === actualDate.getDate() &&
      this.selectedDate.getDay() === actualDate.getDay() &&
      this.selectedDate.getFullYear() === actualDate.getFullYear()
    ) {
      // ok, it's the same day, how check for the current time
      let hours = actualDate.getHours();
      let actualHours = 0;
      if (hours < 12) {
        actualHours = hours === 0 ? 12 : hours;
      } else {
        actualHours = hours;

        if (actualDate.getMinutes() > 30) {
          actualHours += 1;
        }
      }

      // until 8PM
      while (actualHours <= 20) {
        let timeLabel =
          (actualHours > 12 ? actualHours - 12 : actualHours) +
          ":00 " +
          (actualHours < 12 ? "AM" : "PM");
        this.times.push({
          label: timeLabel,
          value: new Date(
            this.selectedDate.getFullYear(),
            this.selectedDate.getMonth(),
            this.selectedDate.getDate(),
            actualHours,
            0,
            0,
            0
          )
        });
        actualHours++;
      }
    } else {

      // from 9am to 8PM
      let selectedDayHours = 9;
      while (selectedDayHours <= 20) {
        this.times.push({
          label:
            (selectedDayHours > 12 ? selectedDayHours - 12 : selectedDayHours) +
            ":00 " +
            (selectedDayHours < 12 ? "AM" : "PM"),
          value: new Date(
            this.selectedDate.getFullYear(),
            this.selectedDate.getMonth(),
            this.selectedDate.getDate(),
            selectedDayHours,
            0,
            0,
            0
          )
        });
        selectedDayHours++;
      }
    }
  }

  getFormattedCombinedDate() {
    return (
      moment(this.combinedDate).format("L") +
      " " +
      moment(this.combinedDate).format("HH:mm")
    );
  }
}
