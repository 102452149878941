import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProxyServiceService } from '../../services/proxy-service.service';
import { IPropertyListingModel } from '../../models/property-listing';
import { Router } from '@angular/router';
import { SearchType } from 'src/app/common-search/common-search-type-enum';

@Component({
  selector: 'app-feature-listings-section',
  templateUrl: './feature-listings-section.component.html',
  styleUrls: ['./feature-listings-section.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureListingsSectionComponent implements OnInit {

  filterOptions: any[] = [];
  propertyListings: IPropertyListingModel[];
  filterQueryString = '';
  searchType: SearchType;
  isPerformingSearch: boolean;

  constructor(public service: ProxyServiceService, private router: Router) {}

  ngOnInit() {

    if (history.state.data) {
      this.service.getPropertyListings(history.state.data).subscribe();
    }

    this.searchType = SearchType.Property;

    this.service.currentListings.subscribe(
      (proplist: IPropertyListingModel[]) => {

        // when the list is precisely 1, go straight to the details page
        if (proplist.length === 1 && sessionStorage.getItem('backFromListing') === null) {
          sessionStorage.setItem('singleListingResult', 'singleListingResult');
          this.router.navigate(['listing/', proplist[0].listingId]);
        } else {
          this.isPerformingSearch = false;
          this.propertyListings = proplist;
          sessionStorage.removeItem('backFromListing');
        }
      }
    );

    this.service.getRETSFilters().subscribe((response: any) => {
      this.filterOptions = response;
    });
  }

  selectProp(listingId) {
    this.router.navigate(['listing/', listingId]);
  }

  onFilterTriggered(filterString: string) {
    this.filterQueryString = filterString;
    this.isPerformingSearch = true;
    this.service.getPropertyListings('', this.filterQueryString).subscribe();
  }

  onSearchPerformed(event: any) {
    this.isPerformingSearch = true;
    this.service.getPropertyListings(event.value, this.filterQueryString, event.metadata).subscribe();
  }
}
