import { Component, OnInit, HostListener, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import * as $ from 'jquery';
import { TokenCacheService } from '../services/token-cache.service';
import { LoginService } from '../services/login.service';
import { DialogService } from 'primeng/api';
import { Router } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class HeaderSectionComponent implements OnInit {
  user: any;
  enableEditProfile: boolean;

  editToggle = false;
  @ViewChild('userTag', { static: false }) userTag: ElementRef;
  items: MenuItem[];
  isDevEnv: boolean;

  constructor(
    public loginService: LoginService,
    private tokenCacheService: TokenCacheService,
    private router: Router,
    private broadcastService: BroadcastService
  ) { }

  ngOnInit() {
    this.isDevEnv = isDevMode();
    this.user = this.tokenCacheService.userCache;
    this.checkForProfileEditingCapabilities();

    this.loginService.profileUpdated.subscribe((profileInfo: any) => {
      this.checkForProfileEditingCapabilities();
    });

    $('.nav-switch').on('click', function (event) {
      $('.main-menu').slideToggle(400);
      event.preventDefault();
    });
  }

  checkForProfileEditingCapabilities() {
    // return if there's no profile info; otherwise proceed
    // if (this.loginService.profileInfo == null) {
    //   return;
    // }

    this.enableEditProfile = true;
    //this.loginService.isProfessionalUser();

    if (this.router.isActive('agent-signup', true)) {
      this.router.navigate(['agent-signup']);
    }
    const labelStyle = !this.enableEditProfile ? { display: 'none' } : { display: 'block' };
    this.items = [
      {
        label: 'Edit Profile', icon: 'pi pi-fw pi-user', style: labelStyle, command: (user) => {
          // this.router.navigate(['agent-signup']);
          if (this.isDevEnv) {
            window.open('https://account-staging.domii.io/profile', '_self');
          } else {
            window.open('https://account.domii.io/profile', '_self');
          }
        }
      }
    ];
  }

  toggleDropdown() {
    this.editToggle = !this.editToggle;
    console.log(this.editToggle);
  }

  signUp() {
    if (this.isDevEnv) {
      window.open('https://account-staging.domii.io', '_self');
    } else {
      window.open('https://account.domii.io', '_self');
    }
    //this.loginService.signUpNoAgreement();
  }

  login() {
    this.loginService.login();
  }

  logout() {
    this.loginService.logout();
  }

  ngOnDestroy() {
    this.loginService.cleanupService();
  }

  @HostListener('document:mousedown', ['$event'])
  clickOut(event) {
    if (!this.userTag || !this.userTag.nativeElement.contains(event.target)) {
      this.editToggle = false;
    }
  }
}
