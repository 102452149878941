import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TokenCacheService } from '../services/token-cache.service';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { DialogService } from 'primeng/api';
import { AgentSignupSavingDialogComponent } from './agent-signup-saving-dialog/agent-signup-saving-dialog.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { Observable, Observer } from 'rxjs';
import * as moment from 'moment';
import { AlertMessageDialogComponent } from '../dialogs/alert-message-dialog/alert-message-dialog.component';

declare const fbq: any;

@Component({
  selector: "app-agent-signup",
  templateUrl: "./agent-signup.component.html",
  styleUrls: ["./agent-signup.component.less"],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class AgentSignupComponent implements OnInit, OnDestroy {
  // TODO: move out of here
  clientTokenUrl =
    "https://ia9payment.azurewebsites.net/api/BraintreeFunction?code=Q2Gasxi/9qqRc4w/lMS8TtQpLO2VT4AOyNv6PRbLH443uw6d44AdKQ==";

  fetchingProfile: boolean = false;
  userLoggedIn: boolean = false;
  formValidated: boolean = false;
  paymentFormValidated: boolean = false;
  profileEdit: boolean = false;
  checkAlreadyRegisteredFlag: boolean = false;
  isUserProfessional: boolean = false;
  isPaymentProvided: boolean = false;

  agentLicenses: any[] = [];
  membershipValue = null;

  agent: any = {
    agentName: "",
    agentEmail: ""
  };

  minDate: Date;
  maxDate: Date;
  minYear: number;
  maxYear: number;

  // FOR NOW
  locationServicesList: any[] = [
    { name: "GPS (the location of your phone)", value: 0 },
    { name: "Physical Address (based on the address entered below)", value: 1 },
    { name: "Zip (based on the Zip code entered below)", value: 2 }
  ];
  selectedLocationValue: number = 0;

  professionalTypesList: any[] = [];

  selectedProfessionalType: any;
  selectedProfessionalState: any;

  states: any[] = [];

  membershipsCollection: any[] = [];
  plansCollection: any[] = [];

  private routeSub: any;
  private paymentResponse = { message: "", nonce: "" };
  private token: string = "";
  encodedProfileImage: any = "";
  encodedCompanyImage: any = "";
  companyName: string = "";
  primaryAddress: string = "";
  secondaryAddress: string = "";
  city: string = "";
  zipCode: string = "";
  uniqueId: string = "";
  selectedMlsAffiliation: any;
  mlsAffiliates: any[];
  private hubspotPayload: any;

  private chargeAmount: number;

  constructor(
    private msalService: MsalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serviceProxy: ProxyServiceService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private tokenCacheService: TokenCacheService
  ) { }

  ngOnInit() {
    this.loginService.profileUpdated.subscribe((profileInfo: any) => {
      this.userLoggedIn = this.loginService.token !== "";
      this.isUserProfessional = this.loginService.isProfessionalUser();

      this.serviceProxy.getProfessionalTypes().subscribe(values => {
        this.professionalTypesList = values.map(x => ({
          name: x.name,
          value: x.type
        }));

        // fetch the professional's profile
        if (this.profileEdit) {
          this.serviceProxy.getProfessionalProfileForEdit().subscribe(
            response => {
              this.bindProfessionalProfile(response);
              //.then(() => {
              //   this.serviceProxy.getMemberships(this.selectedMlsAffiliation === undefined ? undefined : this.selectedMlsAffiliation.vendorName).subscribe(values => {
              //     values.forEach(element => {
              //       this.membershipsCollection.push(element);
              //     });

              //     this.plansCollection = this.membershipsCollection;

              //     // default it to 1 // realtor
              //     this.membershipsCollection = this.plansCollection.filter(
              //       m => m.type === 1
              //     );

              //     // default to -100, $0
              //     this.membershipValue = this.membershipsCollection.find(
              //       m => m.membershipLevel === -100
              //     );
              //     console.log(this.membershipValue);
              //   });
              // });
              this.fetchingProfile = false;
            },
            error => {
              this.fetchingProfile = false;

              // show an error if it failed
              const fetchProfileError = this.dialogService.open(
                AlertMessageDialogComponent,
                {
                  styleClass: "alert-msg-dialog",
                  data: {
                    icon: "pi-exclamation-triangle",
                    message: "Sorry, we couldn't retrieve your Domii profile.",
                    subtitle:
                      "There was an issue retrieving your Domii professional profile. Please try again.",
                    buttonOptions: {
                      ok: {
                        label: "OK",
                        command: () => {
                          fetchProfileError.close();
                        }
                      }
                    }
                  },
                  header: "Error Retrieving Profile",
                  transitionOptions: "1ms"
                }
              );
            }
          );
        } else {
          this.fetchingProfile = false;
          this.selectedProfessionalType = this.professionalTypesList.find(
            p => p.value === 1
          );
          //this.checkForAlreadyRegistered();
        }
      });
    });

    this.serviceProxy.getMlsAffiliates().subscribe(
      response => {
        this.mlsAffiliates = response;
      }
    );

    this.routeSub = this.activatedRoute.params.subscribe(params => {
      // check if this is an edit profile workflow
      this.profileEdit = params.id === "edit";

      this.fetchingProfile = this.loginService.token !== "";
      this.loginService.checkForProfileInfo();
    });

    this.paymentResponse.message = "";
    this.states = this.serviceProxy.getStates();

    const user: any = this.msalService.getAccount();
    if (user) {
      this.agent.agentName =
        user.idToken.given_name + " " + user.idToken.family_name;
      this.agent.agentEmail = user.idToken.emails[0];
    }

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.minDate = new Date();
    this.minDate.setMonth(month);
    this.minDate.setFullYear(year);
    this.minDate.setDate(today.getDate() + 14); // two weeks in the future
    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year + 20);
    this.minYear = year;
    this.maxYear = year + 20;
  }

  resetPassword() {
    window.location.href = this.serviceProxy.mainResetUrl;
  }

  editPersonalProfile() {
    window.location.href = this.serviceProxy.personalProfileUrl;
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  // this is to trigger the B2C signup without popping up the Terms and Conditions popup
  signUpNotLoggedIn() {
    this.loginService.signUpNoAgreement();
  }

  // checks for whether the user is already a registered member
  // and conditionally checks whether they are missing payment information
  // checkForAlreadyRegistered() {
  //   // if it's not an edit, but it's already registered
  //   // but check if you haven't asked them yet
  //   if (!this.checkAlreadyRegisteredFlag) {
  //     if (this.loginService.isProfessionalUser()) {
  //       // this is just to block the UI behind the dialog
  //       this.fetchingProfile = true;

  //       const profRef = this.dialogService.open(AlertMessageDialogComponent, {
  //         styleClass: "alert-msg-dialog already-registered-dialog",
  //         data: {
  //           icon: "pi-exclamation-triangle",
  //           message: "Already Registered",
  //           subtitle:
  //             "Looks like you're already registered. Do you want to edit your profile instead?",
  //           buttonOptions: {
  //             ok: {
  //               label: "Sure",
  //               command: () => {
  //                 profRef.close();
  //                 this.router.navigate(["/agent-signup/edit"]);
  //               }
  //             },
  //             cancel: {
  //               label: "Go Back Home",
  //               command: () => {
  //                 profRef.close();
  //                 this.router.navigate(["/"]);
  //               }
  //             }
  //           }
  //         },
  //         header: "Existing Profile",
  //         transitionOptions: "1ms"
  //       });
  //     }

  //     this.checkAlreadyRegisteredFlag = !this.checkAlreadyRegisteredFlag;
  //   }
  // }

  // shows the messages to display during validation of the agent profile information
  profileValidationMessages(): string[] {
    let profileValidationMessageList: string[] = [];

    if (this.selectedProfessionalType == null) {
      profileValidationMessageList.push("Professional Type");
    }

    if (!this.checkForValidLicenses()) {
      profileValidationMessageList.push("License Information");
    }

    return profileValidationMessageList;
  }

  // show the messages to dislay during validation of the payment information
  paymentValidationMessages(): string[] {
    let paymentValidationMessages: string[] = [];

    if (this.membershipValue == null) {
      paymentValidationMessages.push("Membership Level");
    }

    if (this.token == "" && this.membershipValue.membershipLevel !== -100) {
      paymentValidationMessages.push("Credit Card Validation");
    }

    return paymentValidationMessages;
  }

  checkForValidLicenses(): boolean {
    return (
      this.agentLicenses.length > 0 &&
        this.agentLicenses.every(
          license =>
            license.licenseNumber !== "" &&
            license.licenseState !== "" &&
            license.licenseExpiration !== ""
        ) && this.selectedProfessionalType.value === 1 ? this.selectedMlsAffiliation !== undefined : true
    );
  }

  onSelectedExpirationDate(date, rowIndex: number) {
    this.agentLicenses[rowIndex].licenseExpiration = moment(date).add(1, "h").format(
      "MM/DD/YYYY HH:mm"
    );
  }

  addMLSLicenseControl() {
    this.agentLicenses.push({
      licenseState: "",
      licenseNumber: "",
      licenseExpiration: "",
      photo: ""
    });
    this.onFormValidation();
  }

  removeLicense(index: number) {
    this.agentLicenses.splice(index, 1);
    this.onFormValidation();
  }

  onProfessionalChange(event: any) {
    this.membershipsCollection = this.plansCollection.filter(
      m => m.type === this.selectedProfessionalType.value
    );
    this.membershipsCollection.forEach(p => (p.selected = false));

    this.membershipValue = null;
    this.onFormValidation();
  }

  onMembershipBlockSelected(membership: any) {
    this.membershipValue = membership;

    this.membershipsCollection.forEach(m => (m.selected = false));
    membership.selected = true;
    if (this.membershipValue.membershipLevel === -100) {
      this.onPaymentFormValidation();
    } else {
      this.onPaymentFormValidation();
    }
  }

  // this enables the Create and Edit Profile buttons
  onFormValidation() {
    this.formValidated =
      this.selectedProfessionalType != null && this.checkForValidLicenses();
  }

  // enables the save payment button
  onPaymentFormValidation() {
    this.paymentFormValidated =
      this.isUserProfessional &&
        this.membershipValue.membershipLevel === -100 ? true : this.membershipValue !== null && this.token !== "";
  }

  // this is to register the user as an Domii professional. No payment information is sent along in the first pass
  registerAgent() {
    const dialogRef = this.dialogService.open(
      AgentSignupSavingDialogComponent,
      {
        styleClass: "global-confirmation-dialog",
        width: "70%",
        data: {
          mainTitle: "Registration in Progress. Please wait",
          message: "Please do not close this window or refresh your browser"
        },
        transitionOptions: "1ms"
      }
    );

    if (this.membershipsCollection.length === 0) {
      this.serviceProxy.getMemberships(this.selectedProfessionalType.value, this.selectedMlsAffiliation === undefined ? undefined : this.selectedMlsAffiliation.vendorName).subscribe(values => {
        values.forEach(element => {
          this.membershipsCollection.push(element);
        });

        this.plansCollection = this.membershipsCollection;

        // // default it to 1 // realtor
        // this.membershipsCollection = this.plansCollection.filter(
        //   m => m.type === 1
        // );

        // default to -100, $0
        // this.membershipValue = this.membershipsCollection.find(
        //   m => m.membershipLevel === -100
        // );
      });
    }
    // massage some data
    const processedLicenses = this.processLicenses(this.agentLicenses);

    this.serviceProxy
      .registerAgent({
        agenttype: this.selectedProfessionalType.value,
        paymentToken: "", // default, empty payment token
        membershipLevel: -1, // default, no membership
        planId: -1, // default, no plan
        locationOption: this.selectedLocationValue,
        companyName: this.companyName,
        address1: this.primaryAddress,
        address2: this.secondaryAddress,
        city: this.city,
        state: this.selectedProfessionalState
          ? this.selectedProfessionalState.value
          : "",
        zip: this.zipCode,
        mlsAffiliation: this.selectedMlsAffiliation ? this.selectedMlsAffiliation.vendorName : null,
        photo: this.encodedProfileImage,
        license: processedLicenses
      })
      .subscribe(
        (response: any) => {
          // when done...
          // mark the flag that the user is already a professional
          this.isUserProfessional = true;
          dialogRef.close();
        },
        error => {
          dialogRef.close();
          const regProfileError = this.dialogService.open(
            AlertMessageDialogComponent,
            {
              styleClass: "alert-msg-dialog",
              data: {
                icon: "pi-exclamation-triangle",
                message: "Sorry, we couldn't perform the registration",
                subtitle:
                  "There was an issue during registering your Domii professional profile. Please try again.",
                buttonOptions: {
                  ok: {
                    label: "OK",
                    command: () => {
                      regProfileError.close();
                    }
                  }
                }
              },
              header: "Error Registering Profile",
              transitionOptions: "1ms"
            }
          );
        }
      );

    //this.populateHubspotPayload();
  }

  //TODO: Check this for phase 2
  populateHubspotPayload() {
    this.hubspotPayload = JSON.stringify({
      email: this.agent.agentEmail,
      firstname: this.agent.agentName.split(" ")[0],
      lastname: this.agent.agentName.split(" ")[1],
      hs_context: {
        hutk: this.getCookie("hubspotutk"),
        pageUrl: "https://ia9inc.com/agent-signup/",
        pageName: "Agent Signup"
      }
    });
  }

  checkForSelectedMembership() {
    return this.membershipsCollection.find(m => m.selected);
  }

  createPurchase = (nonce: string, chargeAmount: number) => {
    this.chargeAmount = chargeAmount;
    fbq("track", "AddPaymentInfo");
    fbq("track", "Purchase", { value: chargeAmount, currency: "USD" });
    return this.serviceProxy.createPurchase(
      nonce,
      chargeAmount,
      this.agent,
      this.membershipValue.planValue
    );
  };

  onPaymentStatus(response): void {
    if (response.message) {
      this.paymentResponse.message = response.message;
    } else {
      this.token = response.target.defaultPaymentMethod.token;
      this.paymentResponse.message = "Validation Sucessful!";
    }

    this.onPaymentFormValidation();
  }

  onProfilePhotoSelected(event: any, photo: string, rowIndex: number) {
    const file = event.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (photo === "license") {
        this.agentLicenses[rowIndex].photo = reader.result;
      } else if (photo === "profile") {
        this.encodedProfileImage = reader.result;
      } else if (photo === "companyPhoto") {
        this.encodedCompanyImage = reader.result;
      }

      this.onFormValidation();
    };

    reader.readAsDataURL(file);
  }

  // this is for updating the payment information.
  // in order to update the payment, the professional info MUST be updated as well
  updatePaymentPlusProfile() {
    // save the profile (AGAIN) with the token and the membership information
    const updateProfilePaymentRef = this.dialogService.open(
      AgentSignupSavingDialogComponent,
      {
        styleClass: "global-confirmation-dialog",
        width: "70%",
        data: {
          mainTitle: "Updating Profile in progress. Please wait",
          message: "Please do not close this window or refresh your browser"
        },
        transitionOptions: "1ms"
      }
    );

    // massage some data
    const processedLicenses = this.processLicenses(this.agentLicenses);

    if (this.membershipValue.membershipLevel === -100) {
      this.token = "free";
    }

    this.serviceProxy
      .updateProfessionalProfile({
        agenttype: this.selectedProfessionalType.value,
        mlsAffiliation: this.selectedMlsAffiliation.vendorName,
        paymentToken: this.token,
        membershipLevel: this.membershipValue.membershipLevel,
        planId: this.membershipValue.planValue,
        locationOption: this.selectedLocationValue,
        companyName: this.companyName,
        address1: this.primaryAddress,
        address2: this.secondaryAddress,
        city: this.city,
        state: this.selectedProfessionalState
          ? this.selectedProfessionalState.value
          : "",
        zip: this.zipCode,
        photo: this.encodedProfileImage,
        companyPhoto: this.encodedCompanyImage,
        license: processedLicenses
      })
      .subscribe(
        (response: any) => {
          updateProfilePaymentRef.close();

          this.tokenCacheService.registrationComplete = true;
          fbq("track", "SubmitApplication");
          fbq("track", "CompleteRegistration", {
            value: this.chargeAmount,
            currency: "USD"
          });
          this.router.navigate(["/agent-signup-confirmation"]);
        },
        error => {
          updateProfilePaymentRef.close();

          const refError = this.dialogService.open(
            AlertMessageDialogComponent,
            {
              styleClass: "alert-msg-dialog",
              data: {
                icon: "pi-exclamation-triangle",
                message: "Sorry, we couldn't update your profile",
                subtitle:
                  "There was an issue updating your Domii professional profile. Please try again.",
                buttonOptions: {
                  ok: {
                    label: "OK",
                    command: () => {
                      refError.close();
                    }
                  }
                }
              },
              header: "Error Updating Profile",
              transitionOptions: "1ms"
            }
          );
        }
      );
  }

  // this is to update the professional profile, once there is a payment stored
  updateProfessionalProfile() {
    const updateDialogRef = this.dialogService.open(
      AgentSignupSavingDialogComponent,
      {
        styleClass: "global-confirmation-dialog",
        width: "70%",
        data: {
          mainTitle: "Updating Profile in progress. Please wait",
          message: "Please do not close this window or refresh your browser"
        },
        transitionOptions: "1ms"
      }
    );

    // massage some data
    const processedLicenses = this.processLicenses(this.agentLicenses);

    this.serviceProxy
      .updateProfessionalProfile({
        agenttype: this.selectedProfessionalType.value,
        paymentToken: this.token,
        membershipLevel: this.membershipValue.membershipLevel,
        planId: this.membershipValue.planId,
        locationOption: this.selectedLocationValue,
        companyName: this.companyName,
        address1: this.primaryAddress,
        address2: this.secondaryAddress,
        city: this.city,
        state: this.selectedProfessionalState
          ? this.selectedProfessionalState.value
          : "",
        zip: this.zipCode,
        mlsAffiliation: this.selectedMlsAffiliation.vendorName,
        photo: this.encodedProfileImage,
        companyPhoto: this.encodedCompanyImage,
        license: processedLicenses,
      })
      .subscribe(
        (response: any) => {
          updateDialogRef.close();

          const refSuccess = this.dialogService.open(
            AlertMessageDialogComponent,
            {
              styleClass: "alert-msg-dialog",
              data: {
                icon: "pi-check-circle",
                message: "Domii Profile Updated",
                subtitle:
                  "You have successfully updated your professional profile!",
                buttonOptions: {
                  ok: {
                    label: "OK",
                    command: () => {
                      refSuccess.close();
                      //window.location.href = "agent-signup/edit";
                    }
                  }
                }
              },
              header: "Saving Domii Profile",
              transitionOptions: "1ms"
            }
          );
        },
        error => {
          updateDialogRef.close();

          const refError = this.dialogService.open(
            AlertMessageDialogComponent,
            {
              styleClass: "alert-msg-dialog",
              data: {
                icon: "pi-exclamation-triangle",
                message: "Sorry, we couldn't update your profile",
                subtitle:
                  "There was an issue updating your Domii professional profile. Please try again.",
                buttonOptions: {
                  ok: {
                    label: "OK",
                    command: () => {
                      refError.close();
                    }
                  }
                }
              },
              header: "Error Updating Profile",
              transitionOptions: "1ms"
            }
          );
        }
      );

    updateDialogRef.onClose.subscribe(() => {
      this.formValidated = false;
    });
  }

  // this is the code to map the received editing profile to the page
  bindProfessionalProfile(profile: any) {
    return new Promise((resolve) => {
      this.primaryAddress = profile.Address1;
      this.secondaryAddress = profile.Address2;
      this.city = profile.City;
      this.selectedProfessionalState = this.serviceProxy
        .getStates()
        .find(s => s.value === profile.State);
      this.selectedProfessionalType = this.professionalTypesList.find(
        p => p.value === profile.AgentType
      );

      this.companyName = profile.CompanyName;
      this.zipCode = profile.Zip;
      this.token = profile.PaymentToken;
      this.selectedLocationValue = profile.LocationOption;
      this.uniqueId = profile.UniqueId;
      this.selectedMlsAffiliation = { vendorName: profile.MlsAffiliation },

        // payment provided
        this.isPaymentProvided = this.token !== "";

      // photo
      this.encodedProfileImage = profile.Photo;

      this.encodedCompanyImage = profile.CompanyPhoto;

      // licenses
      this.agentLicenses = [];
      profile.License.forEach(license => {
        this.agentLicenses.push({
          licenseState: this.serviceProxy
            .getStates()
            .find(s => s.value === license.LicenseState),
          licenseNumber: license.LicenseNumber,
          licenseExpiration:
            license.LicenseExpiration != null
              ? new Date(license.LicenseExpiration)
              : "",
          photo: license.Photo
        });
      });

      // memberships cannot be populated at this time as they cannot be edited
      this.membershipsCollection = this.plansCollection.filter(
        m => m.type === profile.AgentType
      );
      // const foundMembership = this.membershipsCollection.find(
      //   m => m.membershipLevel == profile.MembershipLevel
      // );
      this.membershipValue = {
        membershipLevel: profile.MembershipLevel,
        planId: profile.PlanId
      };
      resolve();
    });
  }

  // Given a cookie key `name`, returns the value of
  // the cookie or `null`, if the key is not found.
  // name: hubspotutk for hubspot
  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map(c => c.trim())
        .filter(cookie => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  // this processes the licenses provided for sending it through the payload
  processLicenses(licenses: any[]) {
    const processedLicenses = [];
    licenses.forEach(l => {
      processedLicenses.push({
        licenseState: l.licenseState ? l.licenseState.value : "",
        licenseNumber: l.licenseNumber,
        licenseExpiration: l.licenseExpiration,
        photo: l.photo
      });
    });
    return processedLicenses;
  }
}
