import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, Observer } from "rxjs";
import { AppInfoService } from '../services/app-info.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: "app-buyers-page",
  templateUrl: "./buyers-page.component.html",
  styleUrls: ["./buyers-page.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class BuyersPageComponent implements OnInit {
  paymentResponse: any;
  constructor(
    private http: HttpClient,
    public appInfo: AppInfoService,
    private loginService: LoginService
    ) {}

  ngOnInit() {}

  signupAsBuyer() {
    this.loginService.signUp();
  }
}
