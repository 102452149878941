import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { ProxyServiceService } from 'src/app/services/proxy-service.service';
import { IPropertyListingModel } from 'src/app/models/property-listing';
import { CalendarCarouselService } from 'src/app/services/calendar-carousel.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-confirm-appointment',
  templateUrl: './confirm.appointment.component.html',
  styleUrls: ['./confirm.appointment.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmAppointmentComponent implements OnInit {
  @Output() selectingBackConfirmAppt: EventEmitter<boolean> = new EventEmitter(
    false
  );

  @Output() submitScheduleEvent: EventEmitter<boolean> = new EventEmitter();

  @Input() agent: any;
  @Input() currProperty: IPropertyListingModel;
  @Input() useAgent: boolean;
  agentSelectionOptions: SelectItem[];
  selectedAgent: any = null;
  
  date: any;
  confirmationTitle: string;
  submitBtnLabel: string;
  nextValue: boolean;

  constructor(
    private dateTimeService: CalendarCarouselService
  ) { }

  ngOnInit() {
    this.date = this.dateTimeService.combinedDate;
    // Need to add data of the Realtor selected

    if (this.agent) {
      this.selectedAgent = this.agent;
      this.agentSelectionOptions = [
        { label: this.agent.FirstName + " " + this.agent.LastName, value: this.agent },
        { label: 'Request a Different Agent', value: null }
      ]
    }

    this.confirmationTitle = this.date != null ? 'You are requesting a showing at the following address:' : 'You are requesting more information about the following address:';
    this.submitBtnLabel = 'Confirm Request';
  }

  submitSchedule() {
    // Send the object to the Service/Post
    this.submitScheduleEvent.emit(this.selectedAgent !== null);
  }

  goBackAgent() {
    this.selectingBackConfirmAppt.emit(false);
  }
}
