import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import { SearchType } from './common-search-type-enum';
import * as $ from 'jquery';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrls: ['./common-search.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CommonSearchComponent implements OnInit, OnChanges {

  @Input() searchType: SearchType;
  @Input() isPerformingSearch: boolean;
  @Input() prepopulatedProfessionalField: any;
  @Input() searchCriteria: string;
  @Input() filteredProfessionals: any[] = [];
  @Output() onSearchPerformed: EventEmitter<any> = new EventEmitter<any>();
  @Output() onProfessionalSearchPerformed: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAutoCompletePerformed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('propertySearchFieldElement', { static: false }) propertySearchFieldElement: ElementRef;

  searchTypeEnumProfessional = SearchType.Professional;
  searchTypeEnumProperty = SearchType.Property;
  handleGoogleSearchInput;

  propertySearchPayload: any = {
    propertySearchField: '',
    lat: 0,
    lng: 0,
    tokenizedAddress: ''
  };

  professionalSearchPayload: any = {
    professionalSearchAddressField: '',
    professionalSearchField: null,
    lat: 0,
    lng: 0,
    tokenizedAddress: ''
  }

  options: any = {
    types: [],
    componentRestrictions: {country: "US"}
  };

  propertySearchPlaceholder: string = 'Search By: Address, City, State, or Zip Code';
  professionalSearchPlaceholder: string = 'i.e. Real Estate Agent, Banker, Lawyer, Insurance, Inspector';
  professionalSearchAddressFieldPlaceholder: string = 'Type in address where service is needed';

  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  replaceStreet(address: string) {
    const streetTypes = {
      "Road": "Rd",
      "road": "Rd",
      "Street": "St",
      "street": "St",
      "Lane": "Ln",
      "lane": "Ln",
      "Terrace": "Ter",
      "terrace": "Ter",
      "Circle": "Cir",
      "circle": "Cir",
      "Drive": "Dr",
      "drive": "Dr",
      "Avenue": "Ave",
      "avenue": "Ave",
      "Place": "Pl",
      "place": "Pl",
      "Court": "Ct",
      "court": "Ct"
    };

    let streetRegex = new RegExp(Object.keys(streetTypes).join('|'),"gi");

    address = address.replace(streetRegex, function(matched){
      return streetTypes[matched.toLowerCase()];
    });

    return address.substring(0, address.length - 5); // remove the USA part
  }

  public onPropertySearchInput() {
    this.handleGoogleSearchInput = false;
  }

  public handleAddressChange(address: Address) {
    this.propertySearchPayload.propertySearchField = address.formatted_address;
    this.propertySearchPayload.propertySearchField = this.replaceStreet(this.propertySearchPayload.propertySearchField);
    this.propertySearchPayload.lat = address.geometry.location.lat();
    this.propertySearchPayload.lng = address.geometry.location.lng();
    this.propertySearchPayload.tokenizedAddress = this.getTokenizedAddress(address);
    this.handleGoogleSearchInput = true;
  }

  public getGoogleSearchType(address: Address) {
    let googleSearchType = 'default';
    let searchLocation = '';

    if (address.types && address.types.length > 0) {
      if (address.types.indexOf('street_address') >= 0 || address.types.indexOf('premise') >= 0) {
        googleSearchType = 'street_address';

        // append to this type of search the lat, long and radius to search
        if (address.geometry && address.geometry.location) {
          googleSearchType += '&near=' + address.geometry.location.lat() + ',' + address.geometry.location.lng() + '&radius=5';
        }
      }
    }

    return '&searchType=' + googleSearchType;
  }

  public getTokenizedAddress(address: Address) {
    let tokenizedAddress = '';

    if (address.adr_address) {
      let addressTokens = $.parseHTML(address.adr_address);
      addressTokens.forEach((a) => {
        switch(a.className) {
          case 'locality':
            tokenizedAddress += '&City=' + a.innerText;
            break;
          case 'region':
            tokenizedAddress += '&State=' + a.innerText;
            break;
          case 'postal-code':
              tokenizedAddress += '&Zip=' + a.innerText;
              break;
        }
      });
    }

    return tokenizedAddress + this.getGoogleSearchType(address);
  }

  public handleProfessionalAddressChange(address: Address) {
    this.professionalSearchPayload.professionalSearchAddressField = address.formatted_address;
    this.professionalSearchPayload.lat = address.geometry.location.lat();
    this.professionalSearchPayload.lng = address.geometry.location.lng();
    this.professionalSearchPayload.tokenizedAddress = this.getTokenizedAddress(address);
    this.handleGoogleSearchInput = true;
  }

  constructor() { }

  ngOnInit() {
    this.isPerformingSearch = false;
    this.professionalSearchPayload.professionalSearchField = null;

    if (!this.searchCriteria)
    {
      return;
    }

    // initial input, to start with a value injected from the outside
    if (this.searchCriteria !== null && this.searchCriteria !== '') {
      switch(this.searchType) {
        case SearchType.Professional:
          this.professionalSearchPayload.professionalSearchField = this.searchCriteria;
          break;
        case SearchType.Property:
          this.propertySearchPayload.propertySearchField = this.searchCriteria;
          break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prepopulatedProfessionalField && changes.prepopulatedProfessionalField.currentValue) {
      this.prepopulatedProfessionalField = changes.prepopulatedProfessionalField.currentValue;
      this.professionalSearchPayload.professionalSearchField = this.prepopulatedProfessionalField;
    }
  }

  onPropertySearchEnter() {
    if (!this.handleGoogleSearchInput) {
      return;
    }

    // proceed with the rest of the logic
    this.onSearchPerformed.emit({ 
      type: SearchType.Property, 
      value: this.propertySearchPayload.propertySearchField,
      metadata: this.propertySearchPayload.tokenizedAddress
    });
  }

  onProfessionalSearchEnter() {
    this.onProfessionalSearchPerformedEvent();
  }

  onProfessionalAddressSearchEnter() {
    this.onProfessionalSearchPerformedEvent();
  }
  
  professionalSearchFieldCleared() {
    this.professionalSearchPayload.professionalSearchField = null;
  }

  onProfessionalSearchPerformedEvent() {
    if (!this.handleGoogleSearchInput) {
      return;
    }

    // proceed with the rest of the logic
    this.onProfessionalSearchPerformed.emit({ 
      type: SearchType.Professional,
      value: this.professionalSearchPayload,
      metadata: this.professionalSearchPayload.tokenizedAddress
    });
  }

  onFilterProfessionals(event: any) {
    this.onAutoCompletePerformed.emit({ query: event.query });
  }

  onSearchClick() {
    let searchPayload: any;
    switch(this.searchType) {
      case SearchType.Professional:
        // proceed with the rest of the logic
        this.onProfessionalSearchPerformed.emit(
          { 
            type: SearchType.Professional,
            value: this.professionalSearchPayload,
            metadata: this.professionalSearchPayload.tokenizedAddress
          });
        break;
      case SearchType.Property:
        this.onSearchPerformed.emit({ 
          type: SearchType.Property, 
          value: this.propertySearchPayload.propertySearchField,
          metadata: this.propertySearchPayload.tokenizedAddress
        });
        break;
    }
  }

  isSubmitBtnDisabled() {
    return !this.handleGoogleSearchInput || this.isPerformingSearch;
  }

  getProfessionalIcon(type: number) {
    switch(type) {
      case ProfessionalType.Realtor:
      return 'realtor';
      case ProfessionalType.Banker:
      return 'banker';
      case ProfessionalType.Lawyer:
      return 'lawyer';
      case ProfessionalType.Insurance:
      return 'insurance';
      case ProfessionalType.Inspector:
      return 'inspector';
    }
  }
}

export enum ProfessionalType {
  Realtor = 1,
  Banker,
  Lawyer,
  Insurance,
  Inspector
};
