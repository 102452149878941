import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppInfoService } from 'src/app/services/app-info.service';

@Component({
  selector: 'app-review-section',
  templateUrl: './review-section.component.html',
  styleUrls: ['./review-section.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewSectionComponent implements OnInit {

  sync1: any;
  sync2: any;
  slidesPerPage: number;
  syncedSecondary: boolean;

  constructor(public appInfo: AppInfoService) { }

  ngOnInit() {
  }

}
