import { Component, OnInit, Input } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.less']
})
export class VideoPopupComponent implements OnInit {

  @Input() videoPath: string;

  constructor(private dialogConfig: DynamicDialogConfig) { }

  ngOnInit() {
    this.videoPath = this.dialogConfig.data.videoPath;
  }

}
