import { Component, OnInit } from '@angular/core';
import { AppInfoService } from '../services/app-info.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-download-app-page',
  templateUrl: './download-app-page.component.html',
  styleUrls: ['./download-app-page.component.less']
})
export class DownloadAppPageComponent implements OnInit {

  constructor(
    public appInfo: AppInfoService,
    public loginService: LoginService
  ) { }

  ngOnInit() {
  }

  signupAsBuyer() {
    this.loginService.signUp();
  }

}
