import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent implements OnInit {

  showUserForm = false;
  userType = 'realtor';

  constructor() { }

  ngOnInit() {
  }

  onSelectedUserType(type: string) {
    this.showUserForm = !this.showUserForm;
    this.userType = type;
  }

}
