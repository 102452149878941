import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { CalendarCarouselService } from '../services/calendar-carousel.service';

@Component({
  selector: "app-calendar-carousel",
  templateUrl: "./calendar-carousel.component.html",
  styleUrls: ["./calendar-carousel.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class CalendarCarouselComponent implements OnInit {
  dateObjCollection: any[] = [];

  sliderTransform: string = "";
  currentPosition = 0;
  currentIndex = 0;
  leftNavEnabled: boolean = true;
  rightNavEnabled: boolean = false;
  calWidth = 122;
  datesContainerWidth = 0;
  pageSize = 4;
  selectedTime: Date = null;

  @Input() agent: any = null;
  @Output() selectConfirmAppt: EventEmitter<any> = new EventEmitter();

  constructor(public calendarService: CalendarCarouselService) {}

  ngOnInit() {
    this.datesContainerWidth = this.calWidth * this.pageSize;
    this.dateObjCollection = this.calendarService.getDatesCollection();
  }

  onDateSelected(date: any) {
    this.calendarService.setSelectedDate(date);
  }

  moveRight() {
    this.currentPosition = this.currentPosition - this.calWidth;
    this.currentIndex++;
    this.sliderTransform = `translateX(${this.currentPosition}px)`;

    this.leftNavEnabled = this.currentIndex <= 0;
    this.rightNavEnabled =
      this.currentIndex > this.dateObjCollection.length - 5;
  }

  moveLeft() {
    this.currentPosition = this.currentPosition + this.calWidth;
    this.currentIndex--;
    this.sliderTransform = `translateX(${this.currentPosition}px)`;

    this.leftNavEnabled = this.currentIndex <= 0;
    this.rightNavEnabled =
      this.currentIndex > this.dateObjCollection.length - 5;
  }

  selectAgent() {
    // combine the date and time
    let date = this.calendarService.getSelectedDate();
    let combinedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.selectedTime.getHours(), 0, 0);
    this.calendarService.setCombinedDate(combinedDate);
    this.selectConfirmAppt.emit({
      confirmAppt: true,
      useAgent: true,
      lastUsedAgent: this.agent
    });
  }
}
