import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewEncapsulation,
  SimpleChanges
} from "@angular/core";
import { LoginService } from "src/app/services/login.service";

@Component({
  selector: "app-agent-selection-dialog",
  templateUrl: "./agent-selection-dialog.component.html",
  styleUrls: ["./agent-selection-dialog.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class AgentSelectionDialogComponent implements OnInit {

  @Input() agent: any = null;
  @Input() disableBack: boolean;
  @Output() selectingConfirmAppt: EventEmitter<any> = new EventEmitter();
  @Output() goBackToSchedule: EventEmitter<boolean> = new EventEmitter(false);
  radioYesOrNo: string;
  agentYesNo: string;

  constructor(private loginService: LoginService) {
  }

  ngOnInit() {
    
    // default
    this.radioYesOrNo = "yes";
    this.agentYesNo = "yes";
  }

  get nextValue() {
    return this.radioYesOrNo === "yes" ? false : true;
  }

  sendToSignUp() {
    this.loginService.signUp();
  }

  backToSchedule() {
    this.goBackToSchedule.emit(true);
  }

  selectingConfirmApp() {
    this.selectingConfirmAppt.emit({
      confirmAppt: true,
      useAgent: this.agentYesNo === 'yes',
      lastUsedAgent: this.agent
    });
  }
}
