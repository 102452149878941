import { Component, OnInit, Input } from '@angular/core';

const SMALL_PIC_SIZE = 165;
let BIG_PIC_SIZE = 57.5;

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.less"]
})
export class CarouselComponent implements OnInit {
  @Input() photosArray = [];
  widthSmallPhotos = 0;
  photos = [];
  currentPhotoindex = 0;
  smallPicLength: number;
  bigPhotoPos: number;
  picWidth: number;
  constructor() {}

  ngOnInit() {
    if (this.photosArray !== null && this.photosArray !== undefined) {
      this.photos = this.photosArray;
      this.smallPicLength = (SMALL_PIC_SIZE + 20) * this.photosArray.length;
      this.picWidth = document.getElementById('owl-stage-outer').clientWidth;
      BIG_PIC_SIZE = this.picWidth;
    }
  }

  prevPhoto() {
    if (SMALL_PIC_SIZE <= this.widthSmallPhotos) {
      this.widthSmallPhotos -= (SMALL_PIC_SIZE + 20);
      if (this.currentPhotoindex > 0) {
        this.currentPhotoindex -= 1;
        this.selectPic(this.currentPhotoindex);
      }
      return this.widthSmallPhotos;
    }
  }

  nextPhoto() {
    if (this.smallPicLength > this.widthSmallPhotos) {
      this.widthSmallPhotos += (SMALL_PIC_SIZE + 20);
      console.log(`Position: ${this.currentPhotoindex} of ${this.photos.length}`)
      if (this.currentPhotoindex < this.photos.length) {
        this.currentPhotoindex += 1;
        this.selectPic(this.currentPhotoindex);
      }
      return this.widthSmallPhotos;
    }
  }

  selectPic(photoIndex: number) {
    this.bigPhotoPos = photoIndex * BIG_PIC_SIZE;
  }
}
