import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-global-confirmation',
  templateUrl: './global-confirmation.component.html',
  styleUrls: ['./global-confirmation.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalConfirmationComponent implements OnInit {

  @Input() mainTitle: string;
  @Input() message: string;
  
  constructor() { }

  ngOnInit() {
  }

}
