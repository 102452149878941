import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.less']
})
export class RegisterPageComponent implements OnInit {

  isTypeOfUserSet: boolean;
  isRealtor: boolean; 

  constructor() { }

  ngOnInit() {
  }

  onSelectedUserType(result: boolean) {
    this.isTypeOfUserSet = true;
    this.isRealtor = result;
  }

}
