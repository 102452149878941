import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';
import { ProfessionalType } from 'src/app/common-search/common-search.component';

@Component({
  selector: 'app-confirm-professional-schedule',
  templateUrl: './confirm-professional-schedule.component.html',
  styleUrls: ['./confirm-professional-schedule.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmProfessionalScheduleComponent implements OnInit {

  buttonOptions: any;
  user: string;
  professional: any;
  constructor(private dialogConfig: DynamicDialogConfig) { }

  ngOnInit() {
    this.professional = this.dialogConfig.data.professional;
    this.user = this.dialogConfig.data.user;
    this.buttonOptions = this.dialogConfig.data.buttonOptions;
  }

  getProfessionalIcon(type: number) {
    switch(type) {
      case ProfessionalType.Realtor:
      return 'realtor';
      case ProfessionalType.Banker:
      return 'banker';
      case ProfessionalType.Lawyer:
      return 'lawyer';
      case ProfessionalType.Insurance:
      return 'insurance';
      case ProfessionalType.Inspector:
      return 'inspector';
    }
  }
}
