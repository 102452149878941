import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProxyServiceService } from 'src/app/services/proxy-service.service';
import { IPropertyListingModel } from 'src/app/models/property-listing';
import { Observable, Subscription, Observer } from 'rxjs';
import { DialogService } from 'primeng/api';
import { ScheduleAppDialogComponent } from 'src/app/dialogs/schedule-app-dialog/schedule-app-dialog.component';
import { CalendarCarouselService } from 'src/app/services/calendar-carousel.service';
import { ScheduleSavingDialogComponent } from '../schedule-saving-dialog/schedule-saving-dialog.component';
import { AlertMessageDialogComponent } from 'src/app/dialogs/alert-message-dialog/alert-message-dialog.component';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { TokenCacheService } from 'src/app/services/token-cache.service';
import { LoginService } from 'src/app/services/login.service';
import { map } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SchedulePremessageDialogComponent } from 'src/app/dialogs/schedule-premessage-dialog/schedule-premessage-dialog.component';
import { RequestInfoGuestDialogComponent } from 'src/app/dialogs/request-info-guest-dialog/request-info-guest-dialog.component';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-listing-section',
  templateUrl: './listing-section.component.html',
  styleUrls: ['./listing-section.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class ListingSectionComponent implements OnInit, OnDestroy {
  simpleListingData: IPropertyListingModel;
  simpleListingSub: Subscription;
  thisYear = new Date().getFullYear();
  imgAgent: string;
  datesCollection: any[];
  profileInfo: any;
  agentQRCode: string;
  agentUniqueId: string;
  listingIdType: string;
  agentCompanyPhoto: string;
  invalidPropertyMsg: boolean;
  invalidUniqueAgentId: boolean;
  listingId: string;
  lastUsedAgentId: string;
  sliceStart = 0;
  sliceEnd = 3;
  leftEnabled = false;
  graphEnabled = false;
  rightEnabled = true;
  selectedTime = 9;
  showingDisabled = true;
  agentData: any;
  agentSearchInput: string;
  agentDropdownEnabled = false;
  agentSearchResults = [];
  selectedAgent: any;
  isFirstAvailable = false;
  moment = moment;
  showConfirmationDialogue = false;
  disclaimerData: string = 'Copyright (' + new Date().getFullYear() + ') New England, Real Estate Network Inc. All rights reserved. This information is deemed reliable but not guaranteed. The data relating to real estate displayed on this Site comes in part from the IDX Program of NEREN. The information being provided is for consumers\' personal non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data last updated ' + new Date().toLocaleDateString() + '.';
  freemiumListingPage: boolean;
  mortgageGraphData: ChartDataSets[] = [{
      data: [],
      label: 'Interest',
      stack: 'a'
    },
    {
      data: [],
      label: 'Principal',
      stack: 'a'
    },
    {
      data: [],
      label: 'Balance',
      type: 'line',
      borderColor: '#a8e6cf',
      backgroundColor: 'rgba(168, 230, 207, 0.25)',
      pointBackgroundColor: '#a8dee6',
      pointRadius: 5,
      hidden: true
    }
  ];
  chartOptions: ChartOptions = {
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Balance'
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value: number, index, values) => {
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value).split('.00')[0];
          }
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(tooltipItem.yLabel).split('.00')[0];
        }
      }
    }
  };
  chartType: ChartType = 'bar';
  chartLabels: Label[] = [];
  interestRate = '3.5';
  loanAmount: number;
  loanTerm = 30;
  mortgageData = {
    interestTotal: 0,
    principalTotal: 0,
    data: []
  };
  totalInterest: number;
  isRental = false;

  // bathrooms data
  bathroomsData: string = '';
  selectedDateIndex: number;
  companyName = '';
  listingIsNeren = false;
  vendorIsNNE = false;
  vendorIsPIN = false;
  stateIsMI: boolean = false;
  agencyCompliance: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private proxyService: ProxyServiceService,
    private dialogService: DialogService,
    private tokenCacheService: TokenCacheService,
    public calendarService: CalendarCarouselService,
    private loginService: LoginService,
    private fb: FormBuilder,
    private broadcastService: BroadcastService
  ) {}

  ngOnInit() {

    this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
      this.proxyService.getProfessionalProfileForEdit().subscribe(result => {
        if (result.MlsAffiliation === 'NEREN') {
          this.companyName = result.CompanyName;
          console.log(`NEREN agent: ${result.CompanyName}`);
        }
      });
    });

    this.imgAgent = '';
    this.listingId = this.route.snapshot.paramMap.get('id');

    // collect the agent's unique qrcode from the url (if provided)
    this.agentQRCode = this.route.snapshot.queryParams && this.route.snapshot.queryParams.qr ?
      this.route.snapshot.queryParams.qr : null;

    // collect the agent's unique id from the url (if provided)
    this.agentUniqueId = this.route.snapshot.queryParams && this.route.snapshot.queryParams.agentId ?
      this.route.snapshot.queryParams.agentId : null;

    // collect the type of listing that was provided (RETS, MLS or a default listing Id) if provided
    this.listingIdType = this.route.snapshot.queryParams && this.route.snapshot.queryParams.idType ?
      this.route.snapshot.queryParams.idType : 'listingId';

    if (this.agentUniqueId) {
      this.executeAgentUniqueIdWorkflow();
    } else {
      this.getPropertyInit().subscribe(result => {
        if (!result) {
          this.listingIdType = 'mlsId';
          this.getPropertyInit().subscribe();
        }
      });
    }

    this.loginService.profileUpdated.subscribe(payload => {
      this.selectLastUsed();
    });

    this.datesCollection = this.calendarService.getDatesCollection();

    // since the header handles the login, tap into the profileupdated event
    // and capture the profileinfo
    this.loginService.profileUpdated.subscribe((profile: any) => {
      this.profileInfo = profile;
    });
  }

  getPropertyInit(): Observable < boolean > {
    return new Observable((observer: Observer < boolean > ) => {
      this.simpleListingSub = this.proxyService
        .getPropById$(this.listingId, this.listingIdType)
        .subscribe((dataPropListing: any) => {
            if (dataPropListing.length > 0) {
              this.invalidPropertyMsg = false;
              this.simpleListingData = dataPropListing[0];
              this.listingIsNeren = this.simpleListingData.vendor === 'nne' || this.simpleListingData.vendor === 'neren' ? true : false;
              this.vendorIsNNE = this.simpleListingData.vendor === 'nne' ? true : false;
              this.vendorIsPIN = this.simpleListingData.vendor === 'pin' ? true : false;
              // dataPropListing;
              this.loanAmount = this.simpleListingData.listPrice;

              this.isRental = this.simpleListingData.property.type === 'RNT' ? true : false;

              if (!this.isRental) {
                this.calculateMortgagePayments(this.loanAmount, 30, this.interestRate);
              }

              this.processSpecialFields();

              if (!this.simpleListingData.geo.lat) {
                this.proxyService.getLatAndLng(
                  this.simpleListingData
                ).subscribe(latLngData => {
                    if (latLngData) {
                      this.simpleListingData.geo.lat = latLngData.lat;
                      this.simpleListingData.geo.lng = latLngData.lng;
                    }
                  },
                  // This is could be change on a Dialog error message
                  (error: HttpErrorResponse) => console.log('error : ', error)
                );
              }

              if(this.simpleListingData.hasOwnProperty('compliance')){
                let compensationType = (this.simpleListingData.compliance.buyerAgencyCompensationType === 'Percent' || this.simpleListingData.compliance.buyerAgencyCompensationType === '%') ? '%' : '';
                if(this.simpleListingData.compliance.buyerAgencyCompensation === null && this.simpleListingData.compliance.buyerAgencyCompensationDescription !== ''){
                  this.agencyCompliance = `${this.simpleListingData.compliance.buyerAgencyCompensationDescription}`;
                }else{
                  this.agencyCompliance = `${this.simpleListingData.compliance.buyerAgencyCompensation}${compensationType}`;
                }
              }

              if(this.simpleListingData.address.state==='MI'){
                this.stateIsMI = true;
              }
              observer.next(true);
              observer.complete();
            } else {
              this.simpleListingData = null;
              this.invalidPropertyMsg = true;
              observer.next(false);
              observer.complete();
            }
          },
          (error) => {
            this.invalidPropertyMsg = true;
          });
    });
  }

  toggelAgentSelection() {
    this.agentDropdownEnabled = !this.agentDropdownEnabled;
  }

  agentSearch() {
    this.proxyService.getIA9Agents(this.agentSearchInput).subscribe(result => {
      this.agentSearchResults = result;
    });
  }

  selectAgent(agent: any) {
    this.selectedAgent = agent;
    this.toggelAgentSelection();
    this.isFirstAvailable = false;
    this.validateShowing();
  }

  selectLastUsed() {
    //console.log(this.loginService.profileInfo);
    this.proxyService.getSingleIA9Agent(this.loginService.profileInfo.LastAgentUsed[0].AgentPersonId).subscribe(agent => {
      this.selectedAgent = agent;
      this.validateShowing();
    }, error => {
        console.log('No last used agent');
    });
    this.agentDropdownEnabled = false;
    this.isFirstAvailable = false;
  }

  selectNextAvailable() {
    this.selectedAgent = 'next';
    this.isFirstAvailable = true;
    this.agentData = null;
    this.toggelAgentSelection();
    this.validateShowing();
  }

  selectTime(time: number) {
    this.selectedTime = time + 1;
    this.validateShowing();
  }

  calendarLeft() {
    if (this.sliceStart - 3 <= 0) {
      this.sliceEnd = 3;
      this.sliceStart = 0;
      this.leftEnabled = false;
    } else {
      this.sliceStart -= 3;
      this.sliceEnd -= 3;
      this.rightEnabled = true;
    }
  }

  calendarRight() {
    if (this.sliceEnd + 3 > this.datesCollection.length || this.sliceEnd + 3 === this.datesCollection.length) {
      this.sliceEnd = this.datesCollection.length;
      this.sliceStart = this.datesCollection.length - 3;
      this.rightEnabled = false;
    } else {
      this.sliceStart += 3;
      this.sliceEnd += 3;
      this.leftEnabled = true;
    }
  }

  showGraph() {
    this.graphEnabled = true;
    this.calculateMortgagePayments();
  }

  calculateMortgagePayments(principal: number = this.loanAmount, years: number = this.loanTerm, interest: string = this.interestRate) {
    this.mortgageGraphData.forEach((element) => {
      element.data = [];
    });
    this.mortgageGraphData[2].hidden = true;
    this.chartLabels = [];
    this.totalInterest = 0;
    this.mortgageData.data = [];
    const rate = (parseFloat(interest) / 100 / 12);
    const x = Math.pow(1 + rate, years * 12);
    const monthlyPayment = (principal * x * rate) / (x - 1);
    let balance = principal;
    let year = moment().year() - 1;

    for (let index = 0; index < years; index++) {
      let totalYearlyInterest = 0;
      let totalYearlyPrincipal = 0;
      this.mortgageData.data.push({
        year: index + 1,
        data: []
      });
      year += 1;
      //console.log(`Year: ${i+1}`);
      for (let i = 0; i < 12; i++) {
        const monthlyInterest = balance * rate;
        const monthlyPrincipal = monthlyPayment - monthlyInterest;
        //console.log(`Interest:   ${Math.round(monthlyInterest)}   Principal: ${Math.round(monthlyPrincipal)}`);
        balance += monthlyInterest;
        balance -= monthlyPayment;
        totalYearlyInterest += monthlyInterest;
        totalYearlyPrincipal += monthlyPrincipal;
        this.mortgageData.data[index].data.push({
          interest: monthlyInterest,
          principal: monthlyPrincipal
        });
      }
      //console.log(`Year Interest: ${totalYearlyInterest}   Year Principal: ${totalYearlyPrincipal}   Year Total: ${totalYearlyInterest + totalYearlyPrincipal}`);
      this.mortgageGraphData[0].data.push(Math.round(totalYearlyInterest));
      this.mortgageGraphData[1].data.push(Math.round(totalYearlyPrincipal));
      this.mortgageGraphData[2].data.push(Math.round(balance));

      this.totalInterest += totalYearlyInterest;
      // this.mortgageData[index].interestTotal = totalYearlyInterest;
      // this.mortgageData[index].principalTotal = totalYearlyPrincipal;
      this.chartLabels.push(year.toString());
    }

    //console.log(this.mortgageData);
    //console.log(`Total Paid: ${monthlyPayment * (years * 12)}`);
  }

  mortgageDataTransform(mode: string) {
    if (mode === 'runningTotal') {
      let interestData = [];
      let principalData = [];
      this.mortgageGraphData[2].hidden = false;
      this.mortgageGraphData[0].data.forEach((element, index) => {
        if (index !== 0) {
          interestData.push(element + interestData[index - 1]);
          principalData.push(this.mortgageGraphData[1].data[index] + principalData[index - 1]);
        } else {
          interestData.push(element);
          principalData.push(this.mortgageGraphData[1].data[0]);
        }
      });
      this.mortgageGraphData[0].data = interestData;
      this.mortgageGraphData[1].data = principalData;
      //console.log(interestData);
    }
  }

  get imageAgent() {
    const img =
      this.imgAgent.length > 0 ?
      this.imgAgent :
      'assets/img/default-user-avatar-thumbnail.png';
    return img;
  }

  ngOnDestroy() {
    this.simpleListingSub.unsubscribe();
  }

  // this workflow executes only when the agentId query parameter is provided via URL
  executeAgentUniqueIdWorkflow() {
    this.freemiumListingPage = true;

    if (this.listingId) {
      this.simpleListingSub = this.proxyService
        .getPropById$(this.listingId, this.listingIdType || 'listingId')
        .subscribe((dataPropListing: any) => {
            if (dataPropListing) {
              this.simpleListingData = dataPropListing[0];

              this.loanAmount = this.simpleListingData.listPrice;

              this.isRental = this.simpleListingData.property.type === 'RNT' ? true : false;

              if (!this.isRental) {
                this.calculateMortgagePayments(this.loanAmount, 30, this.interestRate);
              }

              this.processSpecialFields();

              if (!this.simpleListingData.geo.lat) {
                this.proxyService.getLatAndLng(
                  this.simpleListingData
                ).subscribe(latLngData => {
                    if (latLngData) {
                      this.simpleListingData.geo.lat = latLngData.lat;
                      this.simpleListingData.geo.lng = latLngData.lng;
                    }
                  },
                  // This is could be change on a Dialog error message
                  (error: HttpErrorResponse) => console.log('error : ', error)
                );
              }
            } else {
              this.invalidPropertyMsg = true;
            }
          },
          (error) => {
            this.invalidPropertyMsg = true;
          });
    } else {
      this.invalidPropertyMsg = true;
    }

    if (this.agentUniqueId) {
      this.proxyService.getAgentProfileFromUniqueId(this.agentUniqueId).subscribe(
        (agent) => {

          this.agentCompanyPhoto = agent.CompanyPhoto;
          this.agentData = agent;
        },
        (error) => {
          this.invalidUniqueAgentId = true;
        });
    } else {
      this.invalidUniqueAgentId = true;
    }
  }

  onBackClick() {
    sessionStorage.setItem('backFromListing', 'backFromListing');

    if (sessionStorage.getItem('singleListingResult') !== null) {
      sessionStorage.removeItem('singleListingResult');
    }
    this.router.navigate(['search-results']);
  }

  pickAnotherDate() {
    this.launchScheduleApptDialog();
  }

  selectDate(dateSchedule: any, index: number) {
    this.calendarService.setSelectedDate(dateSchedule);
    this.datesCollection = this.calendarService.getDatesCollection();
    this.validateShowing();
    this.selectedDateIndex = index + this.sliceStart;
  }

  validateShowing() {
    if (this.selectedAgent && this.selectedTime && this.calendarService.selectedDate) {
      this.showingDisabled = false;
    }
  }

  scheduleAppointment() {
    if (this.loginService.userObject) {
      let scheduleDateObj = this.calendarService.selectedDate;
      let scheduledDate = this.calendarService.selectedDate.toJSON();
      let scheduleObj = {
        LeadType: 1, // Make this part dynamic depends of the type of the agent or professionals
        LeadSource: 'Web',
        // if they've picked 'Use Agent' then use the last used agent id
        // else if it's not a schedule, but a request for info, also used the last used agent id,
        // otherwise null (no agent)
        AgentId: this.agentData ? this.agentData.AgentId : this.selectedAgent !== 'next' && this.selectedAgent ? this.selectedAgent.agentID : null,
        NotAgentId: null,
        LeadReason: 'Appt',
        MlsAgent: this.simpleListingData.agent.id,
        ListingState: this.simpleListingData.address.state,
        RequestDate: scheduledDate,
        RequestHours: this.selectedTime ? this.selectedTime : 9.0,
        Lat: this.simpleListingData.geo.lat,
        Lng: this.simpleListingData.geo.lng,
        MlsId: this.simpleListingData.mlsId,
        ListingId: this.simpleListingData.listingId
      };
      this.proxyService.scheduleAppointment(scheduleObj).subscribe(
        (response: any) => {
          this.router.navigate(['/schedule-confirmation'], {
            state: {
              property: this.simpleListingData,
              useAgent: this.agentData ? true : false,
              agent: this.agentData,
              date: scheduleDateObj
            }
          });
          // redirect
        },
        (error: any) => {
          // handle error
          // show error
          const ref = this.dialogService.open(
            AlertMessageDialogComponent, {
              styleClass: 'alert-msg-dialog',
              data: {
                icon: 'pi-exclamation-triangle',
                message: 'There was a problem during the scheduling process.',
                subtitle: 'Please try scheduling again.',
                buttonOptions: {
                  ok: {
                    label: 'OK',
                    command: () => {
                      ref.close();
                    }
                  }
                }
              },
              header: 'Error during Scheduling',
              transitionOptions: '1ms'
            }
          );
        }
      );
    } else {
      this.isUserAuthenticated(true);
    }
  }

  openConfirmDialogue() {
    this.showConfirmationDialogue = true;
  }

  closeConfirmDialogue() {
    this.showConfirmationDialogue = false;
  }

  requestMoreInfo() {
    this.calendarService.resetCalendar();
    this.launchScheduleApptDialog(false);
  }

  launchScheduleApptDialog(isSchedule: boolean = true) {
    if (!this.isUserAuthenticated(isSchedule)) {
      this.calendarService.resetCalendar();
      return;
    }

    // fetch the id of the last user agent, by getting the profile info (again!)
    this.proxyService.getUserProfileInfo().subscribe((userProfile: any) => {
      this.profileInfo = userProfile;
      this.loginService.profileInfo = userProfile;

      // this is the workflow of scheduling for a freemium page user
      if (this.freemiumListingPage && this.agentUniqueId) {
        // get the agent from the unique qr code provided
        this.proxyService.getAgentProfileFromUniqueId(this.agentUniqueId).subscribe(
          (agent) => {
            this.lastUsedAgentId = agent.AgentId;
            this.initializeScheduleWorkflow(agent, isSchedule);
          },
          (error: any) => {
            // don't display an error, just schedule without an agent
            // if you got to this point, there was an issue with the uniqueQRCode, just let them schedule the appt without an agent
            // #this.displayGenericErrorMessage();
            this.initializeScheduleWorkflow(null, isSchedule);
          }
        );

        return;
      }

      // if you do have a last used agent, fetch its info and proceed with scheduling with the info
      const lastUsedAgent = this.loginService.getLastUsedAgent(1);
      if (lastUsedAgent) {

        this.lastUsedAgentId = lastUsedAgent.AgentId;

        this.proxyService.getSingleIA9Agent(lastUsedAgent.AgentPersonId).subscribe(
          (agent) => {
            this.initializeScheduleWorkflow(agent, isSchedule);
          },
          (error: any) => {
            this.displayGenericErrorMessage();
          }
        );
      } else if (this.agentQRCode) {
        // get the agent from the unique qr code provided
        this.proxyService.getAgentProfileFromUniqueId(this.agentQRCode).subscribe(
          (agent) => {
            this.lastUsedAgentId = agent.AgentId;
            this.initializeScheduleWorkflow(agent, isSchedule);
          },
          (error: any) => {
            // don't display an error, just schedule without an agent
            // if you got to this point, there was an issue with the uniqueQRCode, just let them schedule the appt without an agent
            // #this.displayGenericErrorMessage();
            this.initializeScheduleWorkflow(null, isSchedule);
          }
        );
      } else { // if not, schedule without a pre-set agent, because there was no agent provided via qrcode nor a last agent available
        this.initializeScheduleWorkflow(null, isSchedule);
      }
    }, () => {
      this.initializeScheduleWorkflow(null, isSchedule);
    });
  }

  initializeScheduleWorkflow(agent: any, isSchedule: boolean) {
    const dialogRef = this.dialogService.open(ScheduleAppDialogComponent, {
      header: isSchedule ? 'Schedule Request' : 'Request More Info',
      transitionOptions: '1ms',
      styleClass: 'schedule-appt-dialog',
      data: {
        ia9Agent: agent,
        listing: this.simpleListingData,
        showCalendarCarousel: isSchedule === true
      }
    });
  }

  isUserAuthenticated(isSchedule: boolean) {
    if (!this.tokenCacheService.token) {
      const ref = this.dialogService.open(isSchedule ? SchedulePremessageDialogComponent : RequestInfoGuestDialogComponent, {
        styleClass: 'schedule-premessage-dialog',
        data: {
          icon: 'pi-exclamation-triangle',
          title: 'You are on your way to finding your dream home!',
          message: 'In order for us to ' +
            (isSchedule ? 'schedule your appointment' : 'provide you information about this property') +
            ', we will need your contact information.',
          buttonOptions: {
            ok: {
              label: isSchedule ? 'Log In' : 'Submit',
              command: isSchedule ? () => {
                this.login();
              } : (formObj: any) => {
                const requestPayload = {
                  ...formObj,
                  ...{
                    mlsId: this.simpleListingData.mlsId,
                    ListingId: this.simpleListingData.listingId,
                    Lat: this.simpleListingData.geo.lat,
                    Lng: this.simpleListingData.geo.lng,
                    ListingAmount: this.simpleListingData.listPrice
                  }
                };
                this.proxyService.scheduleRequestGuest(requestPayload).subscribe((response: any) => {
                    ref.close();
                    const errorRef = this.dialogService.open(
                      AlertMessageDialogComponent, {
                        styleClass: 'alert-msg-dialog',
                        data: {
                          icon: 'pi-check-circle',
                          message: 'Your request has been successfully processed!',
                          subtitle: 'You will be in contact with a professional soon!',
                          buttonOptions: {
                            ok: {
                              label: 'OK',
                              command: () => {
                                errorRef.close();
                              }
                            }
                          }
                        },
                        header: 'Request Success!',
                        transitionOptions: '1ms'
                      }
                    );
                  },
                  (error) => {
                    if (error.status === 409) {
                      ref.close();
                      const errorRef = this.dialogService.open(
                        AlertMessageDialogComponent, {
                          styleClass: 'alert-msg-dialog',
                          data: {
                            icon: 'pi-exclamation-triangle',
                            message: 'Oops! It looks like this email or phone number is already associated with an account!',
                            subtitle: 'Please login and try again.',
                            buttonOptions: {
                              ok: {
                                label: 'OK',
                                command: () => {
                                  errorRef.close();
                                }
                              }
                            }
                          },
                          header: 'Error during Information Request',
                          transitionOptions: '1ms'
                        }
                      );
                    } else {
                      const ref = this.dialogService.open(
                        AlertMessageDialogComponent, {
                          styleClass: 'alert-msg-dialog',
                          data: {
                            icon: 'pi-exclamation-triangle',
                            message: 'There was a problem during the information request.',
                            subtitle: 'Please try sending your request again.',
                            buttonOptions: {
                              ok: {
                                label: 'OK',
                                command: () => {
                                  ref.close();
                                }
                              }
                            }
                          },
                          header: 'Error during Information Request',
                          transitionOptions: '1ms'
                        }
                      );
                    }
                  });
              }
            },
            cancel: {
              label: 'Cancel',
              command: () => {
                ref.close();
              }
            }
          }
        },
        header: (isSchedule ? 'Scheduling Appointment' : 'Requesting Info'),
        transitionOptions: '1ms'
      });

      return false;
    }

    return true;
  }

  displayGenericErrorMessage() {
    // error fetching the agents
    const errorRef = this.dialogService.open(AlertMessageDialogComponent, {
      styleClass: 'alert-msg-dialog',
      data: {
        icon: 'pi-exclamation-triangle',
        message: 'There was a problem processing your request.',
        subtitle: 'Please try again later.',
        buttonOptions: {
          ok: {
            label: 'OK',
            command: () => {
              errorRef.close();
            }
          }
        }
      },
      header: 'Error Processing Request',
      transitionOptions: '1ms'
    });
  }

  signupAsBuyer() {
    this.loginService.signUp();
  }

  login() {
    this.loginService.login();
  }

  processSpecialFields() {
    this.processBathroomsData();
  }

  processBathroomsData() {
    if (this.simpleListingData.property.bathrooms) {
      this.bathroomsData = this.simpleListingData.property.bathrooms + ' bathroom(s) ';
    } else {
      if (this.simpleListingData.property.bathsFull) {
        this.bathroomsData += this.simpleListingData.property.bathsFull + ' full bath(s)';
      }
      if (this.simpleListingData.property.bathsHalf) {
        this.bathroomsData += this.simpleListingData.property.bathsHalf + ' half bath(s)';
      }
    }
  }
  }
