import { Component, OnInit } from '@angular/core';
import { ProxyServiceService } from '../../services/proxy-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LightningData, LightningValidateResponse } from '../lightning-setup.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-ls-home',
  templateUrl: './ls-home.component.html',
  styleUrls: ['./ls-home.component.less']
})
export class LsHomeComponent implements OnInit {
  lightningData: LightningData;
  errorMessage: string;
  notNeren: boolean;
  queryParms: any;
  constructor(
    private proxyService: ProxyServiceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(result => {
      this.queryParms = result;
    });
    this.proxyService.getLightningData().subscribe(res => {
      if (!res) {
        return;
      }
      this.lightningData = res;
      // if (!this.lightningData.hasLightningSetup) {
      //   this.router.navigate(['lightning-setup/edit'], { queryParams: this.queryParms });
      // }

      //enable if agent id check needed

      if (!this.lightningData.mlsId) {
        this.toastr.error("Your are not an MLS Member, Please signup on MLS");
        this.router.navigate(['real-estate-agent/NEREN']);
      } else if (!this.lightningData.isEnrolled) {
        this.router.navigate(['real-estate-agent/NEREN'], { queryParams: this.queryParms });
      } else if (this.lightningData.isListingAgent && !this.lightningData.hasLightningSetup) {
        this.router.navigate(['lightning-setup/edit'], { queryParams: this.queryParms });
      } else if (!this.lightningData.isListingAgent) {
        this.router.navigate(['lightning-setup/setup'], { queryParams: this.queryParms });
      } else if (this.lightningData.isListingAgent) {
        this.router.navigate(['lightning-setup'], { queryParams: this.queryParms });
      }
    });

  }
  goto(page: string) {
    this.router.navigate(['lightning-setup/' + page], { queryParams: this.queryParms });
  }
}
