import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { TokenCacheService } from './token-cache.service';
import { ProxyServiceService } from './proxy-service.service';
import { Subscription } from "rxjs-compat/Subscription";
import { DialogService } from 'primeng/api';
import { TermsAndConditionsComponent } from '../dialogs/terms-and-conditions/terms-and-conditions.component';
import { Account } from 'msal';

@Injectable({
  providedIn: "root"
})
export class LoginService {
  readonly signupAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_register_web";
  readonly signinAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_signin_web";
  readonly passwordResetAuthority = "https://ia9b2c.b2clogin.com/tfp/ia9b2c.onmicrosoft.com/b2c_1_domii_reset_web"
  readonly scope = "https://IA9B2C.onmicrosoft.com/backend/user_impersonation";
  private subscription: Subscription;
  user: string;
  userObject: Account;
  token: string = "";
  profileInfo: any = null;
  profileUpdated: EventEmitter<any> = new EventEmitter<any>();
  profileUpdatedBroadcastFlag: boolean = false;

  constructor(
    private _route: Router,
    private msalService: MsalService,
    private dialogService: DialogService,
    private broadcastService: BroadcastService,
    private tokenCacheService: TokenCacheService,
    private proxyService: ProxyServiceService
  ) {
    this.msalService.handleRedirectCallback((authError, response) => {
      // check if msal error set
      if (authError) {
        // check if error code for forgot password is there
        if (authError.errorMessage.indexOf("AADB2C90118") > -1) {
          // change authority to password reset policy
          console.log(authError.errorMessage)
          this.msalService.loginRedirect({
            authority: this.passwordResetAuthority
          });
          return;
        }
      }
    });
    if (this.msalService.getAccount()) {
      this.tokenCacheService.userCache = this.msalService.getAccount();
      this.user = this.tokenCacheService.userCache.name;

      this.msalService.acquireTokenSilent({
        scopes: [this.scope],
        authority: this.signinAuthority,
        account: this.msalService.getAccount()
      });
    }

    // this.msalService.acquireTokenSilent({
    //     scopes: [this.scope],
    //     authority: this.signinAuthority,
    //     sid: 
    //   }).catch(error => {
    //     console.log(error)
    //   });

    this.broadcastService.subscribe("msal:acquireTokenSuccess", payload => {
      // do something here
      console.log('tokensuccess');
      this.token = payload.accessToken;
      this.tokenCacheService.token = payload.accessToken;
      this.userObject = this.msalService.getAccount();
      this.tokenCacheService.userCache = this.msalService.getAccount();
      // do the update user
      this.proxyService.updateUser().subscribe((response: any) => {
        // do anything with the response here
      });

      this.checkForProfileInfo();
    });

    this.broadcastService.subscribe("msal:loginFailure", payload => {
      console.log('loginfailure')
      // do something here
      if (
        payload._errorDesc &&
        payload._errorDesc.indexOf("AADB2C90118") !== -1
      ) {
        window.location.href = this.proxyService.mainResetUrl;
      }
    });

    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      // do something here
      console.log('loginsuccess');

    });
  }

  checkForProfileInfo() {
    // if there's no profile, go get it
    if (this.profileInfo == null) {
      this.proxyService.getUserProfileInfo().subscribe(
        response => {
          this.profileInfo = response;
          this.profileUpdated.emit(this.profileInfo);
        },
        error => {
          this.profileUpdated.emit(null);
        }
      );
    } else {
      // publish the fact that there is a profile info, don't just fetch it again
      this.profileUpdated.emit(this.profileInfo);
    }
  }

  isProfessionalUser() {
    return (
      this.profileInfo !== null &&
      this.profileInfo.UserLevel !== null &&
      this.profileInfo.UserLevel > 0
    );
  }

  agreeBeforeSignup(callback) {
    const ref = this.dialogService.open(TermsAndConditionsComponent, {
      header: "Accept the domii Terms and Conditions",
      transitionOptions: "1ms",
      data: {
        buttonOptions: {
          onAgree: () => {
            ref.close();
            if (callback instanceof Function) callback();
          },
          onDisagree: () => {
            ref.close();
          }
        }
      }
    });
  }

  signUpAsAgent() {
    if (this.token !== "") {
      this._route.navigate(["/agent-signup"]);
    } else {
      this.agreeBeforeSignup(() => {
        this._route.navigate(["/agent-signup"]);
      });
    }
  }

  signUp() {
    this.agreeBeforeSignup(() => {
      this.loginAndSignup(this.signupAuthority);
    });
  }

  signUpNoAgreement() {
    this.loginAndSignup(this.signupAuthority);
  }

  login() {
    this.loginAndSignup(this.signinAuthority);
  }

  loginAndSignup(auth: string) {
    this.msalService.loginRedirect({
      scopes: [this.scope],
      authority: auth
    });
    // this.msalService.loginRedirect({
    //   scopes: [this.scope]
    // });
    //this.msalService.acquireTokenPopup([this.scope], auth);
    //this.msalService.loginPopup([this.scope]); 
  }

  // fetches the id of the last used professional, provided its type (Realtor =1 , Banker= 2, etc.)
  getLastUsedAgent(agentType: number): any {
    let lastUsedAgent = null;

    if (
      this.profileInfo !== null &&
      this.profileInfo.LastAgentUsed.length > 0
    ) {
      let agent = this.profileInfo.LastAgentUsed.filter(
        agent => agent.AgentType == agentType
      );
      lastUsedAgent = agent !== null && agent.length > 0 ? agent[0] : null;
    }

    return lastUsedAgent;
  }

  logout() {
    this.msalService.authority = this.signupAuthority;
    this.msalService.logout();
  }

  cleanupService() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
