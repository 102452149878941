import { Component, OnInit, ViewEncapsulation } from '@angular/core';

declare const fbq: any;

@Component({
  selector: 'app-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SearchResultsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    fbq('track', 'Search');
  }

}
