import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AppInfoService } from '../services/app-info.service';

declare const fbq: any;

@Component({
  selector: 'app-qrcode-signup',
  templateUrl: './qrcode-signup.component.html',
  styleUrls: ['./qrcode-signup.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class QrcodeSignupComponent implements OnInit {

  constructor(public loginService: LoginService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService) { }

  ngOnInit() {
    const sessionType = sessionStorage['signup-type'];
    if (sessionType && sessionType === 'professional') {
      sessionStorage['signup-type'] = null;
      this.router.navigate(['/agent-signup']);
    }
    fbq('track', 'Lead');
  }

  onSignUpAsBuyer() {
    this.loginService.signUp();
  }

  onSignUpAsProfessional() {
    sessionStorage['signup-type'] = 'professional';
    this.loginService.signUp();
  }

}
