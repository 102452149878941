import { Component, OnChanges, OnInit, ViewEncapsulation, Input, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NumeralPipe } from 'ngx-numeral';
import { ProxyServiceService } from 'src/app/services/proxy-service.service';
import { ISearchFiltersModel } from 'src/app/models/search-filters';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFilterComponent implements OnInit {

  @Input() totalResults: number;
  @Input() filterOptions: any[] = [];
  @Input() searchTitle: string;
  @Output() filterTriggered: EventEmitter<string> = new EventEmitter<string>();

  constructor(private service: ProxyServiceService, public loginService: LoginService) { }

  // original lists
  prices: any[] = [];
  beds: any[] = []
  area: any[] = [];
  daysOnMarket: any[] = [];
  yearsBuilt: any[] = [];
  acres: any[] = [];
  sourcePropertyTypeList: any[] = [];
  sourcePropertyStatusList: any[] = [];
  propertyTypeNameList: any[] = [];
  propertyStatusNameList: any[] = [];

  propertyTypeList: any[] = [];
  propertyStatusList: any[] = [];
  propertyStatusSelection: string;

  // sorting options (supported by RETS)
  sortByOptions: any[] = [
    { name: "List Price, Asc", value: 'listprice' },
    { name: "List Price, Desc", value: '-listprice' },
    { name: "Beds, Asc", value: 'beds' },
    { name: "Beds, Desc", value: '-beds' },
    { name: "Baths, Asc", value: 'baths' },
    { name: "Baths, Desc", value: '-baths' }
  ];
  selectedSortingOption: any;

  // min and max lists (for filtering their values)
  minPricesList: any[] = [];
  maxPricesList: any[] = [];
  minBedsList: any[] = [];
  maxBedsList: any[] = [];
  minYearsBuiltList: any[] = [];
  maxYearsBuiltList: any[] = [];
  minAcresList: any[] = [];
  maxAcresList: any[] = [];
  minAreaList: any[] = [];
  maxAreaList: any[] = [];
  
  
  // selection values
  selectedMinPrice: any;
  selectedMaxPrice: any;
  selectedMaxDOM: any;
  selectedMinYear: any;
  selectedMaxYear: any;
  selectedGarageSpaces: any;
  selectedNumberOfBaths: any;
  selectedMinBeds: any;
  selectedMaxBeds: any;
  selectedMinArea: any;
  selectedMaxArea: any;
  selectedMinAcres: any;
  selectedMaxAcres: any;
  selectedPropertyType: any;
  selectedPropertyStatus: any[];

  // flag to hide / show more filters
  moreFiltersShown: boolean;

  // min / max values
  minPrice: number;
  maxPrice: number;
  minArea: number;
  maxArea: number;
  minBaths: number;
  maxBaths: number;
  minBeds: number;
  maxBeds: number;
  maxDOM: number;
  minYear: number;
  maxYear: number;
  minAcres: number;
  maxAcres: number;
  minGarageSpaces: number;
  maxGarageSpaces: number;

  savedSearchPayload: ISearchFiltersModel[] = [];
  filterQuery: string;
  display = false;
  selectedFrequency = 'Instant';

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    // process all things out of the filter options
    if (changes.filterOptions != null && changes.filterOptions.currentValue != null && changes.filterOptions.currentValue.length > 0) {
      this.setFilterValues();
    }
  }

  onToggleMoreFilters() {
    this.moreFiltersShown = !this.moreFiltersShown;
  }

  // value setters
  setFilterValues() {

    if (this.filterOptions.length > 0) {
      this.filterOptions.forEach((f: any) => {
        switch(f.Name) {
          case "minprice": 
            this.minPrice = f.Value;
            break;
          case "maxprice":
            this.maxPrice = f.Value;
            break;
          case "minarea":
            this.minArea = f.Value;
            break;
          case "maxarea":
            this.maxArea = f.Value;
            break;
          case "minbaths":
            this.minBaths = f.Value;
            break;
          case "maxbaths":
            this.maxBaths = f.Value;
            break;
          case "minbeds":
            this.minBeds = f.Value;
            break;
          case "maxbeds":
            this.maxBeds = f.Value;
            break;
          case "maxdom":
            this.maxDOM = f.Value;
            break;
          case "minyear":
            this.minYear = f.Value;
            break;
          case "maxyear":
            this.maxYear = f.Value;
            break;
          case "minacres":
            this.minAcres = f.Value;
            break;
          case "maxacres":
            this.maxAcres = f.Value;
            break;
          case "mingaragespaces":
            this.minGarageSpaces = f.Value;
            break;
          case "maxgaragespaces":
            this.maxGarageSpaces = f.Value;
            break;
          case "propertytype":
            this.sourcePropertyTypeList = f.Value;
            break;
          case "propertystatus": 
            this.sourcePropertyStatusList = f.Value;
            break;
          case "propertytypename":
            this.propertyTypeNameList = f.Value;
            break;
          case "propertystatusname":
            this.propertyStatusNameList = f.Value;
            break;
        }
      });

      // property types
      this.setPropertyTypesValues();

      // property status
      this.setPropertyStatusValues();

      // process prices
      this.setPricesValues();

      // set bed values
      this.setBedValues();

      // set max days on market
      this.setDaysOnMarket();

      // set year built
      this.setYearBuilt();

      // set lot size
      this.setLotSize();

      // set area size
      this.setAreaSize();
    }
  }

  resetFilters() {

    if (!this.filtersApplied()) {
      return;
    }
    
    // reset filters
    this.resetPropertyStatus();
    this.resetPropertyType();
    this.resetPrices();
    this.resetBeds();
    this.resetBaths();
    this.resetDaysOnMarket();
    this.resetYearBuilt();
    this.resetGarageSpaces();
    this.resetLotSize();
    this.resetArea();

    this.triggerFilter();
  }

  onApplyFiltersClick() {
    this.onToggleMoreFilters();
    this.triggerFilter();
  }

  filtersApplied() {
    return this.selectedMinPrice != null || this.selectedMaxPrice != null ||
    this.selectedMinBeds != null || this.selectedMaxBeds != null ||
    this.selectedNumberOfBaths != null ||
    this.selectedMaxDOM != null ||
    this.selectedMinYear != null || this.selectedMaxYear != null ||
    this.selectedGarageSpaces != null ||
    this.selectedMinAcres != null || this.selectedMaxAcres != null ||
    this.selectedMinArea != null || this.selectedMaxArea != null ||
    this.selectedPropertyType != null || this.selectedPropertyStatus != null;
  }

  sortByChange($event) {
    this.triggerFilter();
  }

  // the actual trigger of the filter
  // this will be done via the APPLY FILTERS button or any changes in any filter
  triggerFilter() {

      this.filterQuery = '';

      // sort
      let sortQuery = this.selectedSortingOption != null ? '&sort=' + this.selectedSortingOption.value : ''; 

      // prices
      let priceQuery = '';
      if (this.selectedMinPrice != null) {
        priceQuery += '&minprice=' + this.selectedMinPrice.value;
      }
      if (this.selectedMaxPrice != null) {
        priceQuery += '&maxprice=' + this.selectedMaxPrice.value;
      }

      //property type
      let propTypeQuery = '';
      if (this.selectedPropertyType != null) {
        propTypeQuery += '&propertytype=' + this.selectedPropertyType.value;
      }

      // property value
      let propStatusQuery = '';
      if (this.selectedPropertyStatus != null && this.selectedPropertyStatus.length > 0) {
        propStatusQuery += '&propertystatus=' + this.selectedPropertyStatus.map((e) => e.value).sort().join(',');;
      }

      // beds
      let bedQuery = '';
      if (this.selectedMinBeds != null) {
        bedQuery += '&minbeds=' + this.selectedMinBeds.value;
      }
      if (this.selectedMaxBeds != null) {
        bedQuery += '&maxbeds=' + this.selectedMinBeds.value;
      }

      // baths
      let bathQuery = this.selectedNumberOfBaths != null ? ('&minbaths=' + this.selectedNumberOfBaths) : '';

      // days on the market
      let domQuery = this.selectedMaxDOM != null ? ('&maxdom=' + this.selectedMaxDOM.value) : '';

      // year built
      let yearBuiltQuery = '';
      if (this.selectedMinYear != null) {
        yearBuiltQuery += '&minyear=' + this.selectedMinYear.value;
      }
      if (this.selectedMaxYear != null) {
        yearBuiltQuery += '&maxyear=' + this.selectedMaxYear.value;
      }

      // garage spaces
      let garageQuery = this.selectedGarageSpaces != null ? ('&minGarageSpaces=' + this.selectedGarageSpaces) : '';

      // lot size
      let lotSizeQuery = '';
      if (this.selectedMinAcres != null) {
        lotSizeQuery += '&minacres=' + this.selectedMinAcres.value;
      }
      if (this.selectedMaxAcres != null) {
        lotSizeQuery += '&maxacres=' + this.selectedMaxAcres.value;
      }

      // area size
      let areaSizeQuery = '';
      if (this.selectedMinArea != null) {
        areaSizeQuery += '&minarea=' + this.selectedMinArea.value;
      }
      if (this.selectedMaxArea != null) {
        areaSizeQuery += '&maxarea=' + this.selectedMaxArea.value;
      }

      this.filterQuery += sortQuery + 
                          propTypeQuery + 
                          propStatusQuery + 
                          priceQuery + 
                          bedQuery + 
                          bathQuery + 
                          domQuery + 
                          yearBuiltQuery + 
                          garageQuery + 
                          lotSizeQuery + 
                          areaSizeQuery;
      
      // emit the query
      this.filterTriggered.emit(this.filterQuery);
  }

  /* PROPERTY TYPE LOGIC */
  setPropertyTypesValues() {

    let pTypes = [];

    for(var i = 0; i < this.sourcePropertyTypeList.length; i++) {
      pTypes.push({ name: this.propertyTypeNameList[this.sourcePropertyTypeList[i]], value: this.sourcePropertyTypeList[i] });
    }

    this.propertyTypeList = pTypes;
  }

  onPropertyTypeChange(event: any) {
    this.selectedPropertyType = event.value;

    this.triggerFilter();
  }

  resetPropertyType() {
    this.selectedPropertyType = null;
  }

  /* PROPERTY STATUS LOGIC */
  setPropertyStatusValues() {
    let pValues = [];

    for(var i = 0; i < this.sourcePropertyStatusList.length; i++) {
      pValues.push({ name: this.propertyStatusNameList[this.sourcePropertyStatusList[i]], value: this.sourcePropertyStatusList[i] });
    }

    this.propertyStatusList = pValues;
  }

  onPropertyStatusChange(event: any) {
    this.propertyStatusSelection = event.value.sort((a, b) => a.value - b.value); //.join(', ');
    this.selectedPropertyStatus = event.value;

    this.triggerFilter();
  }

  resetPropertyStatus() {
    this.selectedPropertyStatus = null;
    this.propertyStatusSelection = '';
  }

  /* PRICES LOGIC */
  setPricesValues() {
    
    let increment = 25000;
    let priceValues : any[] = [];
    let format = '$0a';

    
    for(let i = this.minPrice + 50000; i <= this.maxPrice; i = i + increment) {
      
      if (i >= 5000000) {
        increment = 1000000;
        format = '$0a'
      }

      if (i >= 1000000 && i < 5000000) {
        increment = 250000;
        format = i % 1000000 == 0 ? '$0a' : '$0.00a'
      }

      if (i >= 500000 && i < 1000000) {
        increment = 50000;
      }

      priceValues.push({
        name: new NumeralPipe(i.toString()).format(format),
        value: i
      });
      
    }

    this.prices = priceValues;

    this.minPricesList = this.prices;
    this.maxPricesList = this.prices;
  }

  minPriceListChange(event: any) {
    if (event.value == null) {
      this.minPricesList = this.prices;
    }
    else {
      this.maxPricesList = this.prices.filter((p) => p.value > event.value.value);
    }

    this.triggerFilter();
  }

  maxPriceListChange(event: any) {
    if (event.value == null) {
      this.maxPricesList = this.prices;
      this.minPricesList = this.prices;
    }
    else {
      this.minPricesList = this.prices.filter((p) => p.value < event.value.value);
    }

    this.triggerFilter();
  }

  resetPrices() {
    this.selectedMinPrice = null;
    this.selectedMaxPrice = null;
    this.minPricesList = this.prices;
    this.maxPricesList = this.prices;
  }

  /* END OF PRICES LOGIC */

  /* BEDS LOGIC */

  setBedValues() {
    for(let i = this.minBeds + 1; i <= this.maxBeds; i++) {
      this.beds.push(
        { name: i.toString(), value: i }
      );
    }

    this.minBedsList = this.beds;
    this.maxBedsList = this.beds;
  }

  minBedsChange(event: any) {
    if (event.value == null) {
      this.minBedsList = this.beds;
    }
    else {
      this.maxBedsList = this.beds.filter((p) => p.value > event.value.value);
    }

    this.triggerFilter();
  }

  maxBedsChange(event: any) {
    if (event.value == null) {
      this.maxBedsList = this.beds;
      this.minBedsList = this.beds;
    }
    else {
      this.minBedsList = this.beds.filter((p) => p.value < event.value.value);
    }

    this.triggerFilter();
  }

  resetBeds() {
    this.selectedMinBeds = null;
    this.selectedMaxBeds = null;
    this.minBedsList = this.beds;
    this.maxBedsList = this.beds;
  }

  /* END OF BEDS LOGIC */

  /* BATHS LOGIC */
  numOfBathsChange(event: any) {
    this.triggerFilter();
  }
  
  resetBaths() {
    this.selectedNumberOfBaths = null;
  }
  /* END OF BATHS LOGIC */

  /* DAYS ON THE MARKET LOGIC */

  setDaysOnMarket() {
    this.daysOnMarket = [
      { name: "1 day", value: 1 },
      { name: "7 days", value: 7 },
      { name: "14 days", value: 14 },
      { name: "30 days", value: 30 },
      { name: "90 days", value: 90 },
      { name: "6 months", value: 180 },
      { name: "1 Year", value: 365 }
    ];
  }
  
  resetDaysOnMarket() {
    this.maxDOM = null;
  }

  daysOnTheMarketChange(event: any) {
    this.triggerFilter();
  }

  /* END OF MARKET LOGIC */

  /* YEAR BUILT LOGIC */

  setYearBuilt() {
    let yearIncrement = 1;

    for(let i = this.maxYear; i >= this.minYear; i = i - yearIncrement) {
      
      this.yearsBuilt.push({
        name: i, value: i
      });

      if (i <= 2000) {
        yearIncrement = 20;
      }
    }

    this.minYearsBuiltList = this.yearsBuilt;
    this.maxYearsBuiltList = this.yearsBuilt;
  }

  minYearBuiltChange(event: any) {
    if (event.value == null) {
      this.minYearsBuiltList = this.yearsBuilt;
    }
    else {
      this.maxYearsBuiltList = this.yearsBuilt.filter((p) => p.value > event.value.value);
    }

    this.triggerFilter();
  }

  maxYearBuiltChange(event: any) {
    if (event.value == null) {
      this.minYearsBuiltList = this.yearsBuilt;
      this.maxYearsBuiltList = this.yearsBuilt;
    }
    else {
      this.minYearsBuiltList = this.yearsBuilt.filter((p) => p.value < event.value.value);
    }

    this.triggerFilter();
  }

  resetYearBuilt() {
    this.selectedMinYear = null;
    this.selectedMaxYear = null;
    this.minYearsBuiltList = this.yearsBuilt;
    this.maxYearsBuiltList = this.yearsBuilt;
  }

  /* END OF YEAR BUILT LOGIC */

  /* GARAGE SPACES LOGIC */

  garageSpacesChange(event: any) {
    this.triggerFilter();
  }

  resetGarageSpaces() {
    this.selectedGarageSpaces = null;
  }

  /* END OF GARAGE SPACES LOGIC */

  /* LOT SIZE LOGIC */

  setLotSize() {
    let lotSizeIncrement = 1;
    for(let i = this.minAcres + 1; i <= this.maxAcres; i = i + lotSizeIncrement) {

      this.acres.push({
        name: i, value: i
      });

      if (i >= 10) {
        lotSizeIncrement = 10;
      }
    }

    this.minAcresList = this.acres;
    this.maxAcresList = this.acres;
  }

  minLotSizeChange(event: any) {
    if (event.value == null) {
      this.minAcresList = this.acres;
    }
    else {
      this.maxAcresList = this.acres.filter((p) => p.value > event.value.value);
    }

    this.triggerFilter();
  }

  maxLotSizeChange(event: any) {
    if (event.value == null) {
      this.maxAcresList = this.acres;
      this.minAcresList = this.acres;
    }
    else {
      this.minAcresList = this.acres.filter((p) => p.value < event.value.value);
    }

    this.triggerFilter();
  }

  resetLotSize() {
    this.minAcresList = this.acres;
    this.maxAcresList = this.acres;
    this.selectedMaxAcres = null;
    this.selectedMinAcres = null;
  }

  /* END OF LOT SIZE LOGIC */

  /* AREA LOGIC */

  setAreaSize() {
    let areaIncrement = 250;
    for(let i = this.minArea + 500; i <= this.maxArea; i = i + areaIncrement) {
      this.area.push({
        name: new NumeralPipe(i.toString()).format('0,0'), value: i
      });

      if (i >= 3500) {
        areaIncrement = 500;
      }

      if (i >= 5000) {
        areaIncrement = 1000;
      }
    }

    this.minAreaList = this.area;
    this.maxAreaList = this.area;
  }

  minAreaChange(event: any) {
    if (event.value == null) {
      this.minAreaList = this.area;
    }
    else {
      this.maxAreaList = this.area.filter((p) => p.value > event.value.value);
    }

    this.triggerFilter();
  }

  maxAreaChange(event: any) {
    if (event.value == null) {
      this.minAreaList = this.area;
      this.maxAreaList = this.area;
    }
    else {
      this.minAreaList = this.area.filter((p) => p.value < event.value.value);
    }

    this.triggerFilter();
  }

  resetArea() {
    this.selectedMinArea = null;
    this.selectedMaxArea = null;
    this.minAreaList = this.area;
    this.maxAreaList = this.area;
  }

  /* END OF AREA LOGIC */
  

  /* MIN MAX LOGIC FOR ALL - POSSIBLE REFACTOR TO THIS FOR SIMPLICITY */
  setMinLogic(event: any, minList: any[], maxList: any[], originalList: any[]) {
    if (event.value == null) {
      minList = originalList; // reset the minList when min value is reset
    }
    else {
      // filter out the ones that are lower, just show the ones larger from the one picked in the minlist
      maxList = originalList.filter((p) => p.value > event.value.value);
    }
  }

  setMaxLogic(event: any, minList: any[], maxList: any[], originalList: any[]) {
    if (event.value == null) {
      // reset both
      minList = originalList;
      maxList = originalList;
    }
    else {
      // remove from the minlist the ones that are larger than the max value picked
      minList = originalList.filter((p) => p.value < event.value.value);
    }
  }

  saveSearch() {

    const saveSearch: ISearchFiltersModel = {SearchTitle: '', Search: '', UpdateFrequency : ''};
    saveSearch.SearchTitle = this.searchTitle;
    saveSearch.Search = this.searchTitle + this.filterQuery;
    saveSearch.UpdateFrequency = 'Instant';

    this.savedSearchPayload.push(saveSearch);
    // this.service.saveSearch(this.savedSearchPayload).subscribe(res => {
    //   this.savedSearchPayload = res;
    // });
    this.display = true;
  }

  updateSearch() {
    this.savedSearchPayload[0].SearchTitle = this.searchTitle;
    this.savedSearchPayload[0].UpdateFrequency = this.selectedFrequency;
    //this.service.updateSavedSearch(this.savedSearchPayload).subscribe();
    this.display = false;
  }

}
