import { trigger, style, transition, query, animate, stagger } from '@angular/animations';
export const searchPageAnimations = {
    slideUpExpandIn: trigger('slideUpExpandIn', [
        transition('void => *', [
            // Set animation starting state
            style({
                width: 0,
                height: 0,
                opacity: 0,
            }),
            // Animate element to default/defined state
            animate('1500ms', style({
                height: '*',
                width: '*',
                opacity: '*',
            })),

            animate('1500ms ease')
        ])
    ]),
    expandFromRight: trigger('expandFromRight', [
      transition(':enter', [

        style({
          width: 0
        }),

        animate('600ms cubic-bezier(0.16, 1, 0.3, 1)', style({
          width: '*'
        })),
      ]),
      transition(':leave', [

          animate('600ms cubic-bezier(0.16, 1, 0.3, 1)', style({
              width: 0
          })),
      ])
    ]),

    markerLand: trigger('markerLand', [
        transition('void => *', [
            query(':enter', [
                style({
                    'margin-top': '30px',
                    opacity: 0
                }),
                stagger(100, [
                    animate('200ms cubic-bezier(0.33, 1, 0.68, 1)', style({
                        'margin-top': '*',
                        opacity: '1'
                    }))
                ])
            ], {
                optional: true
            })
        ])
    ])
};
