import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';
import * as $ from "jquery";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsComponent implements OnInit {
  
  buttonOptions: any;

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
      // visible height + pixel scrolled >= total height 
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        console.log("End");
      }
  }

  constructor(
    private dialogConfig: DynamicDialogConfig
  ) { }

  ngOnInit() {
    this.buttonOptions = this.dialogConfig.data.buttonOptions;
  }

}
