import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenCacheService {

  userCache: any;
  token: string;
  registrationComplete: boolean = false;

  constructor() { }

}
