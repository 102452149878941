import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ProxyServiceService } from '../services/proxy-service.service';
import { QuestionAnswer, QuestionResponse } from './feedbackform.model';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feedbackform',
  templateUrl: './feedbackform.component.html',
  styleUrls: ['./feedbackform.component.less']
})
export class FeedbackformComponent implements OnInit {
  prospectId: string;
  questionFormGroup: FormGroup;
  questionAnswers: QuestionAnswer[] = [];
  responseMessage: string = "";
  isSubmitted: boolean = false;
  isThanksSubmitted: boolean = false;
  isSuccess: boolean = true;
  //make empty when Api Integration...
  questionsArray: QuestionResponse[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private proxyService: ProxyServiceService,
  ) { }

  ngOnInit() {
    this.questionFormGroup = this.fb.group({
      questions: this.fb.array([])
    })

    this.prospectId = this.route.snapshot.params['prospectId'];

    this.proxyService.getFeedbackQuestions(this.prospectId).subscribe(response => {
      console.log(response.data);
      this.questionsArray = response.data;
      this.questionsArray.forEach(x => {
        if (x.ans != '') {
          this.isSubmitted = true;
        }
      })
      if (!this.isSubmitted) {
        this.generateForm();
      }
    }, error => {
      console.log(error);
      if (error && error.error) {
        this.responseMessage = error.error.description;
      }
      this.isSuccess = false;
      console.log("getFeedbackQuestions", this.responseMessage);
    });

  }

  generateForm() {
    this.questionsArray.forEach(t => {
      let questions = <FormArray>this.questionFormGroup.controls["questions"];
      questions.push(this.fb.group({
        value: [t.ans, [Validators.required]]
      }))
    })
  }

  onSubmit() {
    let questionAndAnswer = this.questionFormGroup.controls['questions'].value;
    questionAndAnswer.forEach((ans, index) => {
      this.questionAnswers.push(
        {
          qid: this.questionsArray[index].qid,
          ans: ans.value
        })
    });
    this.proxyService.postFeedback(this.prospectId, this.questionAnswers).subscribe(response => {
      this.isThanksSubmitted = true;
      this.isSuccess = response.data.isSuccess;
    }, error => {
      console.log("this.responseMessage", this.responseMessage);
      this.responseMessage = error.error.description || error.description || 'Request failed!';
      this.isSuccess = false;
    });
  }

}
