import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogService } from 'primeng/api';
import { ProfessionalSearchDialogComponent } from '../dialogs/professional-search-dialog/professional-search-dialog.component';
import * as $ from "jquery";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit {

  professionals: any[] = [
    {
      icon: 'realtor',
      name: 'real estate agent',
      label: 'Real Estate Agent'
    },
    {
      icon: 'banker',
      name: 'banker',
      label: 'Mortgage'
    },
    {
        icon: 'inspector',
        name: 'inspector',
        label: 'Home Inspector'
    },
    {
        icon: 'lawyer',
        name: 'lawyer',
        label: 'Lawyer'
    },
    {
        icon: 'insurance',
        name: 'insurance',
        label: 'Home Insurance'
    }
  ];

  constructor(private dialogService: DialogService) { }

  ngOnInit() {
  }

  onProfessionalClick(professional: string) {
    const ref = this.dialogService.open(ProfessionalSearchDialogComponent, {
      styleClass: 'professional-search-dialog',
      data: {
        selectedProfessional: professional
      },
      header: 'Find A Professional',
      transitionOptions: '1ms'
    });

    ref.onClose.subscribe((event: any) => {
      $(".pac-container").remove();
    });
  }
}
