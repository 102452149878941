import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-schedule-premessage-dialog',
  templateUrl: './schedule-premessage-dialog.component.html',
  styleUrls: ['./schedule-premessage-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulePremessageDialogComponent implements OnInit {

  buttonOptions: any;
  title: string;
  message: string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.message = this.config.data.message;
    this.title = this.config.data.title;
    this.buttonOptions = this.config.data.buttonOptions;
  }
}
