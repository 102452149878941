import { Component, OnInit, ElementRef } from '@angular/core';
import { AppInfoService } from '../services/app-info.service';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { ProxyServiceService } from '../services/proxy-service.service';
import { LoginService } from '../services/login.service';
import * as moment from 'moment';
import { state } from '@angular/animations';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { TokenCacheService } from '../services/token-cache.service';
import { BroadcastService } from '@azure/msal-angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { ToastrService } from 'ngx-toastr';
import { isDevMode } from '@angular/core';

declare const fbq: any;

@Component({
  selector: 'app-agent-signup-page',
  templateUrl: './agent-signup-page.component.html',
  styleUrls: ['./agent-signup-page.component.less']
})
export class AgentSignupPageComponent implements OnInit {

  clientTokenUrl =
    'https://ia9payment.azurewebsites.net/api/BraintreeFunction?code=Q2Gasxi/9qqRc4w/lMS8TtQpLO2VT4AOyNv6PRbLH443uw6d44AdKQ==';

  paymentToken: string;
  agent: any = {
    agentName: '',
    agentEmail: ''
  };
  professionalTypesList: any[] = [];
  mlsAffiliatesList: any[] = [];
  membershipsList: any[] = [];
  selectedProfessionalName: string;
  selectedStateName: string;
  selectedMlsAffiliate: string;
  selectedPlanValue: string;
  selectedLicenseStates: any[] = [];
  states: any[] = [];
  agentLicenses: any[] = [];
  userLoggedIn: boolean;
  isUserProfessional: boolean;
  hasUserPayed: boolean;
  chargeAmount: number;
  isProcessing = false;
  submitButtonText: string;
  paymentProcessed = false;
  showCompleteButton = false;
  isNEREN = false;
  isFreemium = false;
  updateMembership = false;
  dateObjects: NgbDateStruct[] = [];

  imagePlaceholder = '../../assets//img/default-user-avatar-thumbnail.png';

  realtorForm = this.fb.group({
    AgentType: ['', Validators.required],
    MembershipLevel: [-1, Validators.required],
    PlanId: [-1, Validators.required],
    PaymentToken: [''],
    LocationOption: [0, Validators.required],
    CompanyName: [''],
    Address1: ['', Validators.required],
    Address2: [''],
    City: ['', Validators.required],
    State: ['', Validators.required],
    Zip: ['', Validators.required],
    MlsAffiliation: ['', Validators.required],
    License: this.fb.array([], [Validators.required]),
    Photo: [this.imagePlaceholder],
    CompanyPhoto: [this.imagePlaceholder]
  });
  submitted = false;
  isDevEnv: boolean;
  constructor(
    private toastr: ToastrService,
    private el: ElementRef,
    private fb: FormBuilder,
    private msalService: MsalService,
    private serviceProxy: ProxyServiceService,
    public loginService: LoginService,
    private router: Router,
    private tokenCacheService: TokenCacheService,
    private broadcastService: BroadcastService,
    private scrollToService: ScrollToService,
    public appInfo: AppInfoService) { }

  ngOnInit() {
    this.isProcessing = true;
    this.isDevEnv = isDevMode();

    //if this page dont want redirection please comment out this line.
    if (this.isDevEnv) {
      window.open('https://account-staging.domii.io/profile', '_self');
    } else {
      window.open('https://account.domii.io/profile', '_self');
    }


    this.serviceProxy.getMlsAffiliates().subscribe((response) => {
      this.mlsAffiliatesList = [...response, { vendorName: 'Other ' }];
    });

    const user: any = this.msalService.getAccount();
    if (user) {
      this.agent.agentName =
        user.idToken.given_name + ' ' + user.idToken.family_name;
      this.agent.agentEmail = user.idToken.emails[0];
    }

    this.userLoggedIn = this.loginService.token !== '';

    if (this.userLoggedIn) {
      this.bindProfessionalProfile();
    }

    this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
      this.userLoggedIn = true;
      this.bindProfessionalProfile();
    });
    this.isProcessing = false;
  }
  // convenience getter for easy access to form fields
  get f() { return this.realtorForm.controls; }

  bindProfessionalProfile() {
    this.serviceProxy.getProfessionalProfileForEdit().subscribe((response) => {
      this.loadProfileData(response);
    }, error => {
      this.loadProfileData();
    });
  }

  signUp() {
    if (this.isDevEnv) {
      window.open('https://account-staging.domii.io/realtorsignup', '_self');
    } else {
      window.open('https://staging.domii.io/realtorsignup', '_self');
    }
    //this.loginService.signUpNoAgreement();
  }

  loadProfileData(response?: any) {

    this.serviceProxy.getProfessionalTypes().subscribe(values => {
      this.professionalTypesList = values.map(x => ({
        name: x.name,
        value: x.type
      }));

      this.states = this.serviceProxy.getStates();
      this.isUserProfessional = this.loginService.isProfessionalUser();
      this.submitButtonText = 'Submit Profile and Continue to Memberships';

      if (response && response.PersonId !== null) {
        this.License.patchValue([]);
        this.realtorForm.patchValue(response);
        this.selectedMlsAffiliate = response.MlsAffiliation;
        this.hasUserPayed = response.PaymentToken !== '';
        //this.showCompleteButton = this.hasUserPayed;
        this.realtorForm.markAsPristine();

        if (response.PlanId === 'freemiumPro' && response.MembershipLevel === 3) {
          this.isFreemium = true;
        }

        if (!this.hasUserPayed) {
          this.realtorForm.get('PaymentToken').setValidators([Validators.required]);
          this.realtorForm.patchValue({ MembershipLevel: null, planId: null });
        }

        this.getMemberships();
        this.submitButtonText = this.isUserProfessional && this.hasUserPayed === false ?
          'please select a membership from below' : 'Save Changes';

        this.selectedProfessionalName = this.professionalTypesList.find((professional) => {
          return professional.value === this.realtorForm.value.AgentType;
        }).name;

        this.selectedStateName = this.states.find((state) => {
          return state.value === this.realtorForm.value.State;
        }).name;

        this.License.clear();
        this.dateObjects = [];
        this.selectedLicenseStates = [];
        response.License.forEach(element => {
          this.License.push(this.fb.group(
            {
              LicenseState: element.LicenseState,
              LicenseNumber: element.LicenseNumber,
              LicenseExpiration: moment(element.LicenseExpiration).format('MM/DD/YYYY'),
              Photo: element.Photo
            }
          ));
          const expirationDate = moment(element.LicenseExpiration);
          if (element.LicenseState) {
            this.selectedLicenseStates.push({ name: this.states.find((state) => state.value === element.LicenseState).name });
          } else {
            this.selectedLicenseStates.push({ name: '' });
          }
          this.dateObjects.push({
            day: expirationDate.date(),
            month: expirationDate.month() + 1,
            year: expirationDate.year()
          });
        });
      } else {
        let selectedName = this.professionalTypesList.find((professional) => {
          return professional.value === 1;
        });
        this.selectProfessional(selectedName);
      }
      this.isProcessing = false;
    });
  }

  selectProfessional(professional: any) {
    this.realtorForm.patchValue({ AgentType: professional.value });
    this.selectedProfessionalName = professional.name;
    if (professional.value === 1) {
      this.realtorForm.controls['MlsAffiliation'].setValidators([Validators.required]);
      this.realtorForm.controls['MlsAffiliation'].updateValueAndValidity({ onlySelf: true });
    }
    this.getMemberships();
    this.formChanged();
  }

  selectState(state: any) {
    this.realtorForm.patchValue({ State: state.value });
    this.selectedStateName = state.name;
    this.formChanged();
  }

  selectLicenseState(state: any, index: number) {
    this.License.at(index).patchValue({ LicenseState: state.value });
    this.selectedLicenseStates[index].name = state.name;
    this.formChanged();
  }

  selectMlsAffiliation(affiliate: any) {
    this.realtorForm.patchValue({ MlsAffiliation: affiliate.vendorName });
    this.selectedMlsAffiliate = affiliate.vendorName;
    if (affiliate.vendorName === 'Other') {
      this.realtorForm.controls['MlsAffiliation'].patchValue(undefined);
    }
    this.isNEREN = affiliate.vendorName === 'NEREN' ? true : false;
    this.getMemberships();
    this.formChanged();
  }

  selectMembership(membership: any) {

    if (membership.membershipLevel === 2 || membership.membershipLevel === 3) {
      this.paymentProcessed = true;
      this.showCompleteButton = true;
      this.realtorForm.controls.PaymentToken.patchValue('free');
    } else {
      this.paymentProcessed = false;
      this.showCompleteButton = false;
    }
    this.realtorForm.patchValue({ PlanId: membership.planId });
    this.realtorForm.patchValue({ MembershipLevel: membership.membershipLevel });
    this.selectedPlanValue = membership.planValue;
  }

  formChanged() {
    this.realtorForm.markAsDirty();
    if (!this.isUserProfessional && !this.hasUserPayed) {
      this.submitButtonText = 'Submit Profile and Continue to Memberships';
    } else if (this.isUserProfessional && !this.paymentProcessed && !this.hasUserPayed) {
      this.submitButtonText = 'Select a Membership from Below';
    } else {
      this.submitButtonText = 'Save Profile';
    }
  }

  processImage(imageInput: any, type: string, index?: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (type === 'profile') {
        this.realtorForm.patchValue({ Photo: reader.result });
      } else if (type === 'company') {
        this.realtorForm.patchValue({ CompanyPhoto: reader.result });
      } else if (type === 'license') {
        this.License.at(index).patchValue({ Photo: reader.result });
      }
      this.formChanged();
    };

    reader.readAsDataURL(file);
  }

  getMemberships() {
    this.serviceProxy.getMemberships(this.realtorForm.value.AgentType, this.realtorForm.value.MlsAffiliation).subscribe((memberships) => {
      this.membershipsList = memberships;
    });
  }

  createPurchase = (nonce: string, chargeAmount: number) => {
    this.chargeAmount = chargeAmount;
    fbq('track', 'AddPaymentInfo');
    fbq('track', 'Purchase', { value: chargeAmount, currency: 'USD' });
    return this.serviceProxy.createPurchase(
      nonce,
      chargeAmount,
      this.agent,
      this.selectedPlanValue
    );
  }

  onPaymentStatus(response): void {
    if (response.message) {
      // this.paymentResponse.message = response.message;
    } else {
      this.realtorForm.patchValue({ PaymentToken: response.target.defaultPaymentMethod.token });
      // this.paymentResponse.message = "Validation Sucessful!";
      this.paymentProcessed = true;
      this.showCompleteButton = true;
    }
  }

  addMLSLicenseControl() {
    const today = moment();
    this.License.push(this.fb.group(
      {
        LicenseState: '',
        LicenseNumber: '',
        LicenseExpiration: '',
        Photo: ''
      }
    ));
    this.selectedLicenseStates.push({
      name: ''
    });
    this.dateObjects.push({
      month: today.month(),
      day: today.date(),
      year: today.year()
    });
  }

  removeLicense(index: number) {
    this.License.removeAt(index);
    this.selectedLicenseStates.splice(index, 1);
    this.dateObjects.splice(index, 1)
    this.formChanged();
  }

  get License() {
    return this.realtorForm.get('License') as FormArray;
  }
  invalidFieldFocus() {
    this.toastr.warning('Please fill all required fields');
    for (const key of Object.keys(this.f)) {
      if (this.f[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        if (invalidControl) {
          invalidControl.focus();
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        break;
      }
    }
  }

  registerProfile() {
    this.submitted = true;
    if (!this.realtorForm.valid) {
      this.invalidFieldFocus();
      return;
    }
    this.isProcessing = true;
    this.submitButtonText = 'Submitting Profile...';
    this.cleanFormData();
    this.serviceProxy.registerAgent(this.realtorForm.value).subscribe((response: any) => {
      this.isUserProfessional = true;
      this.isProcessing = false;
      this.submitButtonText = 'please select a membership from below';
      this.realtorForm.patchValue({ MembershipLevel: null, planId: null });
      this.realtorForm.markAsPristine();

    }, error => {
      this.submitButtonText = 'There was an error submitting your profile';
    });
  }

  cleanFormData() {
    this.realtorForm.value.License.forEach((license, index) => {
      license.LicenseExpiration = moment()
        .date(this.dateObjects[index].day)
        .month(this.dateObjects[index].month - 1)
        .year(this.dateObjects[index].year).format();
    });
    if (this.realtorForm.value.Photo && this.realtorForm.value.CompanyPhoto === this.imagePlaceholder) {
      this.realtorForm.value.Photo = '';
      this.realtorForm.value.CompanyPhoto = '';
    }
  }

  updatePaymentAndProfile() {
    this.isProcessing = true;
    this.cleanFormData();
    this.serviceProxy.updateProfessionalProfile(this.realtorForm.value).subscribe((response: any) => {

      this.realtorForm.markAsPending();
      this.tokenCacheService.registrationComplete = true;
      this.showCompleteButton = true;
      fbq('track', 'SubmitApplication');
      fbq('track', 'CompleteRegistration', {
        value: this.chargeAmount,
        currency: 'USD'
      });

      if (this.realtorForm.value.MembershipLevel === 3) {
        this.serviceProxy.postPromo({
          Name: this.loginService.userObject.idToken.given_name + ' ' + this.loginService.userObject.idToken.family_name,
          PhoneNumber: this.loginService.userObject.idToken.extension_Phone,
          Email: this.loginService.userObject.idToken.emails[0]
        }).subscribe(response => {
          console.log('PromoData Submitted!');
        });
      }
      this.router.navigate(['/agent-signup-confirmation']);
    });
  }

  updateProfile() {
    this.isProcessing = true;
    this.cleanFormData();
    return;
    this.serviceProxy.updateProfessionalProfile(this.realtorForm.value).subscribe((response: any) => {
      this.realtorForm.markAsPristine();
      this.isProcessing = false;
      this.submitButtonText = 'Profile changes saved!';
    });
  }

  resetPassword() {
    window.location.href = this.serviceProxy.mainResetUrl;
  }

  toggleUpdateMembership() {
    this.updateMembership = !this.updateMembership;
    const config: ScrollToConfigOptions = {
      target: 'payment-section',
      easing: 'easeInOutCubic',
      offset: -120,
      duration: 650
    };
    this.selectedPlanValue = '';
    setTimeout(() => {
      this.scrollToService.scrollTo(config);
    }, 300);
  }

}
