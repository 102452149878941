import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  lat: number;
  lng: number;
  zoom = 13;
  searchString = '';
  listings: any[];
  cityPoints: any[];
  filterString = '';
  selectedListing: any;
  listingsToggle = true;
  listingsState: any[];
  lastSelectedListingIndex: number;
  city: string;
  state: string;
  zipCode: string;
  placesId: string;
  currentPos = { lat: undefined, lng: undefined };
  deviceLocation = { lat: undefined, lng: undefined };
  currentZoom: number;
  isSavedSearch = false;
  isSearchSaved = false;
  listingContainerWidthOffset = 0;

  isStatefulReload = true;

  constructor() { }
}
