import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { AppInfoService } from '../services/app-info.service';

@Component({
  selector: 'app-qrcode-access-confirmation',
  templateUrl: './qrcode-access-confirmation.component.html',
  styleUrls: ['./qrcode-access-confirmation.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class QrcodeAccessConfirmationComponent implements OnInit {

  payload: any;
  associatedAgent: any;

  constructor(public router: Router,
    public appInfo: AppInfoService, 
    public loginService: LoginService) { 
    this.payload = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {
    if (!this.payload) {
      this.router.navigate(['/']);
    }
    else {
      this.associatedAgent = this.payload.associatedAgent;
    }
  }
}
