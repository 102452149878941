import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { DataViewModule } from "primeng/dataview";
import {SpinnerModule} from 'primeng/spinner';
import {FileUploadModule} from 'primeng/fileupload';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import { DropdownModule } from "primeng/dropdown";
import { LightboxModule } from "primeng/lightbox";
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    PaginatorModule, 
    TableModule, 
    DataViewModule, 
    SpinnerModule, 
    FileUploadModule, 
    RadioButtonModule, 
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    LightboxModule,
    MultiSelectModule,
    DialogModule
  ],

  exports: [
    PaginatorModule, 
    TableModule, 
    DataViewModule, 
    SpinnerModule, 
    FileUploadModule, 
    RadioButtonModule,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    LightboxModule,
    MultiSelectModule,
    DialogModule
  ]
})
export class PrimeNgModule {}
