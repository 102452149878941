import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, AfterViewChecked, Inject } from '@angular/core';
import { SearchType } from '../common-search/common-search-type-enum';
import { Router } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { IPropertyListingModel } from '../models/property-listing';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';
import { DOCUMENT } from '@angular/common';



declare const fbq: any;

@Component({
  selector: 'app-qrcode-rider',
  templateUrl: './qrcode-rider.component.html',
  styleUrls: ['./qrcode-rider.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class QrcodeRiderComponent implements OnInit, OnDestroy {

  searchType: SearchType = SearchType.Property;
  isPerformingSearch: boolean;
  notificationAvailable = false;
  personName = '';
  search = '';
  exactListingSub: Subscription;
  showModal = false;

  constructor(
    public service: ProxyServiceService,
    private router: Router,
    private loginService: LoginService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.searchType = SearchType.Property;

    this.exactListingSub = this.service.currentListings.subscribe(
      (proplist: IPropertyListingModel[]) => {
        this.isPerformingSearch = false;
        if (proplist.length > 0) {
          if (proplist.length === 1) {
            this.showModal = true;
            const listingId = proplist[0].listingId;
            this.router.navigate(['listing/', listingId]);
          } else {
            this.notificationAvailable = true;
          }
        } else {
          if (this.search) {
            this.notificationAvailable = true;
          }
        }
      },
      error => {
        this.notificationAvailable = true;
      });

    fbq('track', 'Lead');
    this.document.documentElement.scrollTop = this.document.body.scrollHeight;
  }

  ngOnDestroy() {
    this.exactListingSub.unsubscribe();
  }

  closeModal() {
    this.showModal = false;
  }

  onSearchPerformed() {
    this.notificationAvailable = false;
    this.isPerformingSearch = true;
    this.service.getPropertyListings(this.search).subscribe();
  }
}
