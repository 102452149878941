import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-agent-signup-saving-dialog',
  templateUrl: './agent-signup-saving-dialog.component.html',
  styleUrls: ['./agent-signup-saving-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AgentSignupSavingDialogComponent implements OnInit {

  constructor(private dialogConfig: DynamicDialogConfig) { }

  @Input() mainTitle: string;
  @Input() message: string;

  ngOnInit() {
    this.mainTitle = this.dialogConfig.data.mainTitle;
    this.message = this.dialogConfig.data.message;
  }
}
