import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {DynamicDialogRef} from 'primeng/api';
import {DynamicDialogConfig} from 'primeng/api';

@Component({
  selector: 'app-alert-message-dialog',
  templateUrl: './alert-message-dialog.component.html',
  styleUrls: ['./alert-message-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AlertMessageDialogComponent implements OnInit {

  message: string;
  icon: string;
  subtitle: string;
  parameter: string;
  buttonOptions: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.icon = this.config.data.icon;
    this.message = this.config.data.message;
    this.subtitle = this.config.data.subtitle;
    this.parameter = this.config.data.parameter;
    this.buttonOptions = this.config.data.buttonOptions;
  }

}
