import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProxyServiceService } from 'src/app/services/proxy-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-promocode-dialog',
  templateUrl: './promocode-dialog.component.html',
  styleUrls: ['./promocode-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PromocodeDialogComponent implements OnInit {

  promoCodeValue: string = '';
  validatingCode: boolean;
  uniqueAgentId: string = '';
  invalidPromocode: boolean;

  constructor(public proxyService: ProxyServiceService, 
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef) { }

  ngOnInit() {
    this.uniqueAgentId = this.config.data.uniqueAgentId;
  }

  validateCode() {
    this.invalidPromocode = false;
    this.validatingCode = true;
    this.proxyService.validatePromoCode(encodeURI(this.promoCodeValue), this.uniqueAgentId)
    .subscribe((response) => {
      this.validatingCode = false;
      this.dialogRef.close(response);
    },
    error => {
      this.validatingCode = false;
      this.invalidPromocode = true;
    });
  }
}
