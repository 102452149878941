import { Component, OnInit } from '@angular/core';
import { TokenCacheService } from '../services/token-cache.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AppInfoService } from '../services/app-info.service';

@Component({
  selector: 'app-agent-signup-confirmation',
  templateUrl: './agent-signup-confirmation.component.html',
  styleUrls: ['./agent-signup-confirmation.component.less']
})
export class AgentSignupConfirmationComponent implements OnInit {

  constructor(private tokenCacheService: TokenCacheService,
    private router: Router,
    private msalService: MsalService,
    public appInfo: AppInfoService) { }

  //user: string;

  ngOnInit() {

    if (!this.tokenCacheService.registrationComplete) {
      this.router.navigate(['/']);
    }

    //this.user = this.msalService.getUser().name;
    this.tokenCacheService.registrationComplete = false;
  }
}
