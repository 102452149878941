import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  AppleStoreLink: string = 'https://itunes.apple.com/us/app/ia9/id1467412622';
  GooglePlayLink: string = 'https://play.google.com/store/apps/details?id=com.ia9.mobile';

  constructor() { }
}
