import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { LoginService } from '../services/login.service';
import * as jsPDF from 'jspdf';
import { AppInfoService } from '../services/app-info.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-promocert-page',
  templateUrl: './promocert-page.component.html',
  styleUrls: ['./promocert-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PromocertPageComponent implements OnInit {

  public routeSub: any;
  public userLoggedIn: boolean;
  public promoCertValue: string;
  public validatedCashback: any;
  public payload: any;
  public showAppInstall: boolean;

  constructor(private loginService: LoginService,
              private proxyService: ProxyServiceService,
              private router: Router,
              private appInfo: AppInfoService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient) {
    }
  
  ngOnInit() {

      this.routeSub = this.activatedRoute.params.subscribe(params => {
        this.promoCertValue = params.certificate;
  
        this.proxyService.validateCertificate(this.promoCertValue)
          .subscribe((response) => {
            this.payload = response !== null && response.length > 0 ? response[0] : null;
          },
          error => {
            this.payload = null;
          });
      });

      this.activatedRoute.queryParams.subscribe(queryParams => {
        this.showAppInstall = !queryParams.showAppInstall || (queryParams.showAppInstall && queryParams.showAppInstall === "true");
      });
  }

  cleanUpCertificateText(certText: string): string {
    certText = certText.replace(/\\n/g, '<br/>');
    return certText;
  }

  onPDFGenerate() {

    // Landscape export
    var doc = new jsPDF({
      orientation: 'landscape'
    });

    const makeCertificate = () => {
      if (this.payload.fullName) {
        doc.setFontSize(25);
        doc.text(this.payload.fullName, 150, 157, null, null, 'center');
      }

      if (this.payload.certificateId) {
        doc.setFontSize(12);
        doc.text('Certificate Id No. ' + this.payload.certificateId, 150, 167, null, null, 'center');
      }

      doc.setFontSize(12);
      doc.text(new Date().toDateString(), 228, 205);

      if (this.payload.certificateValue) {
        doc.setFontSize(80);
        doc.setTextColor('black');
        doc.text(this.payload.certificateValue, 230, 60, null, null, 'center');
      }

      if (this.payload.certificateText) {
        doc.setFontSize(20);
        doc.setTextColor('black');
        let certText = this.payload.certificateText.split('\\n');
        doc.text(certText.join('\n'), 230, 80, null, null, 'center');
      }

      if (this.payload.disclaimerText) {
        doc.setFontSize(10);
        doc.setTextColor('black');
        let certText = this.payload.disclaimerText.split('\\n');
        doc.text(certText.join('\n'), 150, 125, null, null, 'center');
      }

      if (this.payload.description) {
        doc.setFontSize(7);
        doc.setTextColor('Black');
        doc.text('*The ' + this.payload.description + ' is to be paid by your domii Member Agent, not domii inc.', 234, 100, null, null, 'center');
      }
      doc.save('certificate.pdf');
    };

    this.http.get('assets/img/Award Certificate FINAL jpg.jpg', {responseType: 'blob'}).subscribe((response: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        doc.addImage(reader.result, 'JPEG', 0, 0, 300, 210);
        makeCertificate();
      };

      reader.readAsDataURL(response);
    });
  }
}
