import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { IPropertyListingModel } from 'src/app/models/property-listing';

@Component({
  selector: 'app-schedule-app-dialog',
  templateUrl: './schedule-app-dialog.component.html',
  styleUrls: ['./schedule-app-dialog.component.less'],
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleAppDialogComponent implements OnInit {
  isCalendarCarousel: boolean;
  isAgentSelection: boolean;
  isConfirmAppt = false;
  ia9Agent: any;
  disableBack: boolean;

  lastUsedAgent: any = null;
  useAgent: boolean;
  mlsProperty: IPropertyListingModel;

  constructor(private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig) {
  }

  ngOnInit() {
    this.ia9Agent = this.config.data.ia9Agent;
    this.mlsProperty = this.config.data.listing;
    this.isCalendarCarousel = this.config.data.showCalendarCarousel;
    this.isConfirmAppt = !this.config.data.showCalendarCarousel;
    this.disableBack = !this.config.data.showCalendarCarousel;
  }

  backToSchedule(backVal: boolean) {
    this.isAgentSelection = !this.isAgentSelection;
    this.isCalendarCarousel = backVal;
  }

  selectAgentOpt(agentVal: boolean) {
    this.isCalendarCarousel = !this.isCalendarCarousel;
    this.isAgentSelection = agentVal;
  }

  selectConfirmAppt(confirmationObj: any) {
    this.isAgentSelection = false;
    this.isCalendarCarousel = !this.isCalendarCarousel;
    this.isConfirmAppt = confirmationObj.confirmAppt;
    this.lastUsedAgent = confirmationObj.lastUsedAgent;
    this.useAgent = confirmationObj.useAgent;
  }

  onScheduleAppointment(agentRequested: boolean) {

    this.dialogRef.close({
      lastUsedAgent: agentRequested ? this.lastUsedAgent : null,
      useAgent: agentRequested ? this.useAgent : false,
      property: this.mlsProperty
    });
  }
}
