import { Component, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ProxyServiceService } from '../../services/proxy-service.service';
import { IPropertyListingModel } from '../../models/property-listing';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.less']
})
export class FilterSearchComponent implements OnInit {

  search: string;
  constructor(private proxyService: ProxyServiceService) { }

  ngOnInit() {
  }

  getListings() {
    const searchQuery = this.search.trim();
    this.proxyService.getPropertyListings(searchQuery).subscribe();
  }

}
