import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ProxyServiceService } from '../services/proxy-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-qrcode-agent',
  templateUrl: './qrcode-agent.component.html',
  styleUrls: ['./qrcode-agent.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class QrcodeAgentComponent implements OnInit {

  userLoggedIn: boolean;
  isUserProfessional: boolean;
  profileInfo: any = null;
  professionalInfo: any = null;
  fetchingData: boolean = false;

  constructor(private loginService: LoginService,
    private serviceProxy: ProxyServiceService) { }

  ngOnInit() {

    this.loginService.profileUpdated.subscribe((profileInfo: any) => {

      this.fetchingData = true;
      this.userLoggedIn = this.loginService.token !== '';
      this.isUserProfessional = this.loginService.isProfessionalUser();
      this.profileInfo = profileInfo;

      this.serviceProxy.getProfessionalProfileForEdit().subscribe((response) => {
        this.fetchingData = false;
        this.professionalInfo = response;
      }, (error) => {
        this.fetchingData = false;
        this.professionalInfo = null;
      });
      
    });
    
    this.loginService.checkForProfileInfo();

    // the only way to hide the hubspot from within this component
    setTimeout(() => {
      $("#hubspot-messages-iframe-container").remove();
    }, 500);
  }

  signupAsAgent() {
    this.loginService.signUpAsAgent();
  }

}
