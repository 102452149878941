import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { IPropertyListingModel } from '../models/property-listing';
import {} from 'googlemaps';

@Component({
  selector: "app-maps-component",
  templateUrl: "./maps-component.component.html",
  styleUrls: ["./maps-component.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class MapsComponentComponent implements OnInit {
  @Input() mapData: IPropertyListingModel;
  constructor() {}

  ngOnInit() {
    const myLatLng = { lat: this.mapData.geo.lat, lng: this.mapData.geo.lng };

      const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 17,
          center: myLatLng
      });

    const marker = new google.maps.Marker({
      position: myLatLng,
        map: map,
      title: this.mapData.address.full
    });
  }
}
