import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-request-info-guest-dialog',
  templateUrl: './request-info-guest-dialog.component.html',
  styleUrls: ['./request-info-guest-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RequestInfoGuestDialogComponent implements OnInit {

  buttonOptions: any;
  title: string;
  message: string;
  infoForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  phone: FormControl;
  leadDescription: FormControl;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.message = this.config.data.message;
    this.title = this.config.data.title;
    this.buttonOptions = this.config.data.buttonOptions;
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.firstName = new FormControl("", [
      Validators.required
    ]);
    this.lastName = new FormControl("", [
      Validators.required
    ]);
    this.email = new FormControl("", [
      Validators.required,
      Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")
    ]);
    this.phone = new FormControl("", [
      Validators.required,
      // Validators.pattern("1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?")
      Validators.pattern("[0-9\+\-]{10,12}")
    ]);
    this.leadDescription = new FormControl("");
  }

  createForm() {
    this.infoForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      leadDescription: this.leadDescription
    });
  }
}
