import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { NgxLoadingServiceService } from './services/ngx-loading-service.service';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {

  constructor(private router: Router, private loadingService: NgxLoadingServiceService) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-137731768-1', {
        page_path: event.urlAfterRedirects
      });
    });
  }
  loading = false;
  ngOnInit() {
    this.loadingService.getLoading().subscribe(val => {
      setTimeout(() => {
        this.loading = val.some(x => x);
      });
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // find all available dialogs in the page, and just remove them
    const pdialogs: HTMLCollectionOf<Element> = document.getElementsByTagName('p-dynamicdialog');
    for (let i = 0; i < pdialogs.length; i++) {
      pdialogs[i].remove();
    }
  }
}
