import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-schedule-saving-dialog',
  templateUrl: './schedule-saving-dialog.component.html',
  styleUrls: ['./schedule-saving-dialog.component.less']
})
export class ScheduleSavingDialogComponent implements OnInit {

  mainTitle: string;
  message: string;

  constructor(private dialogConfig: DynamicDialogConfig) { }

  ngOnInit() {

    this.mainTitle = this.dialogConfig.data.mainTitle;
    this.message = this.dialogConfig.data.message;
  }

}
