import { Component, OnInit, ViewChild } from '@angular/core';
import { ProxyServiceService } from '../../services/proxy-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LightningData, LightningValidateResponse } from '../lightning-setup.model';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleComponent } from './popup/schedule/schedule.component';
import { LoginService } from '../../services/login.service';
import { AppInfoService } from '../../services/app-info.service';
import { isDevMode } from '@angular/core';
@Component({
  selector: 'app-ls-setup',
  templateUrl: './ls-setup.component.html',
  styleUrls: ['./ls-setup.component.less']
})
export class LsSetupComponent implements OnInit {
  currentPage = 0;
  lightningData: LightningData;
  errorMessage: string;
  notNeren: boolean;
  moment = moment;
  realativeMonthOffset = 0;
  currentMonth: moment.Moment;
  currentDate: number;
  datesCollection: any[];
  lightningTimes: any[];
  selectedLightningTimeIndex: number;
  selectedLightningDate: moment.Moment;
  queryParms: any;
  //selectedLightningDateData: any;
  //selectedLightningTime: any;
  isDevEnv: boolean;
  @ViewChild('landingModal', { static: true }) landingModal: any;
  constructor(
    private loginService: LoginService,
    public appInfo: AppInfoService,
    private proxyService: ProxyServiceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.modalService.open(this.landingModal, { size: 'lg', backdrop: 'static' });
    this.isDevEnv = isDevMode();
    this.activeRoute.queryParams.subscribe(result => {
      this.queryParms = result;
    });



    this.proxyService.getLightningData().subscribe(res => {
      if (!res) {
        return;
      }
      this.lightningData = res;
      if (!this.lightningData.mlsId) {
        this.router.navigate(['real-estate-agent/NEREN']);
      }
      if (!this.lightningData.isEnrolled || !this.lightningData.hasLightningSetup) {
        this.router.navigate(['lightning-setup'], { queryParams: this.queryParms });
      }
      this.calculateMonth();
    });
  }
  weekdayFilter(day: string): any[] {
    return this.datesCollection.filter(date => { return date.weekDay === day })
  }

  selectLightningDate(day: any) {

    this.selectedLightningDate = day.moment as moment.Moment;
    const popup = this.modalService.open(ScheduleComponent);
    popup.componentInstance.selectedLightningDate = day.moment as moment.Moment;
    popup.componentInstance.selectedLightningDateData = day.lightningTimes
    popup.componentInstance.lightningData = this.lightningData;
    popup.result.then(res => {
      this.selectedLightningDate = undefined;
      console.log(res);
    });
  }
  getLightningTimes() {

    this.proxyService.getLightningPropertySchedule(this.lightningData.listingId,
      moment().date(1).add(this.realativeMonthOffset, 'M').format('YYYY-MM-DDTHH:mm:ss'),
      moment().date(1).add(this.realativeMonthOffset, 'M').add(moment().daysInMonth() - 1, 'days').format('YYYY-MM-DDTHH:mm:ss'))
      .then(result => {

        this.lightningTimes = result.data;
        //console.log(result.data.length);
        this.datesCollection.forEach(date => {
          date.lightningTimes = [];
          for (const lightningTime of result.data) {
            if (moment(date.moment).isSame(lightningTime.sdt, 'day') && moment(lightningTime.sdt).isAfter(moment())) {
              date.lightningTimes.push(lightningTime);
            }
          }

          for (const time of result.data) {
            if (time.avl) {
              this.selectedLightningTimeIndex = result.data.indexOf(time);
              break;
            }
          }
        });
        //console.log(this.datesCollection);
      });
  }
  calculateMonth(): void {
    if (this.realativeMonthOffset > 0) {
      this.currentDate = moment().add(this.realativeMonthOffset, 'M').date(1).date();
      console.log('Adjusted Date')
    } else {
      this.currentDate = moment().add(this.realativeMonthOffset, 'M').date();
      console.log('No date adjustment');
    }
    this.currentMonth = moment().add(this.realativeMonthOffset, 'M')

    let startOffset = moment().add(this.realativeMonthOffset, 'M').date(1).day() - 1;
    //console.log(startOffset);
    //console.log(moment().add(this.realativeMonthOffset, 'M').date(1).day());
    this.datesCollection = [];
    for (let day = 0; moment().add(this.realativeMonthOffset, 'M').daysInMonth() + (startOffset) + 1 > day; day++) {
      //console.log(`Day - Offset: ${day - startOffset}`);
      this.datesCollection.push({
        month: moment().add(this.realativeMonthOffset, 'M').date(day - startOffset).format('MMMM'),
        day: moment().add(this.realativeMonthOffset, 'M').date(day - startOffset).get('date'),
        weekDay: moment().add(this.realativeMonthOffset, 'M').date(day - startOffset).format('dddd'),
        year: moment().add(this.realativeMonthOffset, 'M').date(day - startOffset).year(),
        moment: moment().add(this.realativeMonthOffset, 'M').date(day - startOffset)
      })
    }
    this.getLightningTimes();
    //console.log(this.datesCollection);
  }

  nextMonth() {
    this.realativeMonthOffset += 1;
    this.calculateMonth();
  }

  previousMonth() {
    this.realativeMonthOffset -= 1;
    this.calculateMonth();
  }
  
  login() {
    this.loginService.login();
  }

  redirectToAccountSignup() {
    if (this.isDevEnv) {
      window.open('https://account-staging.domii.io', '_self');
    } else {
      window.open('https://account.domii.io', '_self');
    }
  }
  
  redirectToHome() {
    if (this.isDevEnv) {
      window.open('https://staging.domii.io', '_self');
    } else {
      window.open('https://domii.io', '_self');
    }
  }

}
