import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LightningData } from 'src/app/lightning-setup/lightning-setup.model';
import { ProxyServiceService } from '../../../../services/proxy-service.service';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['../../ls-setup.component.less']
})
export class ScheduleComponent implements OnInit {
  currentStep = 0;
  moment = moment;
  selectedLightningDate: moment.Moment;
  selectedLightningDateData: any;
  selectedLightningTime: any;
  lightningData: LightningData;
  constructor(public activeModal: NgbActiveModal, private proxyService: ProxyServiceService) { }

  ngOnInit() {
  }
  selectLightningTime(lightningData: any) {
    this.selectedLightningTime = lightningData;
  }
  confirmSchedule() {
    const payload = {
      aid: this.lightningData.agentId,
      mid: this.lightningData.mlsId,
      pid: this.lightningData.listingId,
      sdt: this.selectedLightningTime.sdt,
      edt: this.selectedLightningTime.edt,

    };
    this.proxyService.bookLightningShowing(payload).then(result => {
      if (result.isSuccess) {
        this.currentStep = 2;
        console.log('Showing Booked!')
        this.selectedLightningTime.avl = false;
      }
    });
  }

}
