// ♰ for the greater glory of God ♰
import { Component, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent, GoogleMapsAPIWrapper, AgmGeocoder, GeocoderResult } from '@agm/core';
import { ProxyServiceService } from '../services/proxy-service.service';
import { IPropertyListingModel } from '../models/property-listing';
import * as moment from 'moment';
import { Options, LabelType } from 'ng5-slider';
import { searchPageAnimations } from '../animations/search-page-animations';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { ActivatedRoute } from '@angular/router';
import { ISearchFiltersModel } from '../models/search-filters';
import { MapService } from '../services/map.service';
import { SavedSearchAnimations } from '../animations/saved-search-animations';
/// <reference types="@types/googlemaps" />
import { Location } from '@angular/common';

@Component({
	selector: 'app-search-page',
	templateUrl: './search-page.component.html',
	styleUrls: ['./search-page.component.less'],
	animations: [
		searchPageAnimations.expandFromRight,
		searchPageAnimations.markerLand,
		SavedSearchAnimations.slideUpIn
	]
})
export class SearchPageComponent implements OnInit {
	searchSuggestions: any[];
	autoComplete = new google.maps.places.AutocompleteService();
	showResults = false;
	isLoading = true;
	map: GoogleMapsAPIWrapper;
	showSaveSearchOverlay = false;
	notificationInterval = 'Daily';
	isNotificationEmail = true;
	isNotificationMobile = true;
	saveSearchName: string;
	moment = moment;
	showOpenhouses = false;

	filters = {
		price: {
			labels: ['minPrice', 'maxPrice'], min: 0, max: 10000000, boundaries: {
				floor: 0, ceil: 10000000, step: 5000,
				translate: (value: number, label: LabelType): string => {
					switch (label) {
						case LabelType.Low:
							return '<b>Min price:</b> $' + this.formatNumber(value);
						case LabelType.High:
							return '<b>Max price:</b> $' + this.formatNumber(value);
						default:
							return '$' + value;
					}
				},
				combineLabels: (minValue: string, maxValue: string): string => {
					return 'from ' + minValue + ' up to ' + maxValue;
				}
			}
		},
		area: {
			labels: ['minArea', 'maxArea'], min: 0, max: 100000, boundaries: {
				floor: 0, ceil: 100000, step: 100,
				translate: (value: number, label: LabelType): string => {
					switch (label) {
						case LabelType.Low:
							return '<b>Min area: </b>' + value + ' ft²';
						case LabelType.High:
							return '<b>Max area: </b>' + value + ' ft²';
						default:
							return '' + value;
					}
				},
				combineLabels: (minValue: string, maxValue: string): string => {
					return 'from ' + minValue + ' up to ' + maxValue;
				}
			}
		},
		baths: { labels: ['minBaths', 'maxBaths'], min: 0, max: 10, boundaries: { floor: 0, ceil: 10 } },
		beds: { labels: ['minBeds', 'maxBeds'], min: 0, max: 10, boundaries: { floor: 0, ceil: 10 } },
		//dom: { labels: ['maxDom'], max: 365, boundaries: { floor: 0, ceil: 365 } },
		year: { labels: ['minYear', 'maxYear'], min: 1900, max: moment().year(), boundaries: { floor: 1900, ceil: moment().year() } },
		acres: { labels: ['minAcres', 'maxAcres'], min: 0, max: 100, boundaries: { floor: 0, ceil: 100 } },
		garageSpaces: { labels: ['minGarageSpaces', 'maxGarageSpaces'], min: 0, max: 10, boundaries: { floor: 0, ceil: 10 } },
		propertyType: { labels: ['propertyType'], types: ['Single-Family', 'Multi-Family', 'Condo', 'Townhouse', 'Land', 'Commercial'], selected: [], min: -1, max: 5, boundaries: { floor: -1, ceil: 5 } },
		propertyStatus: { labels: ['propertyStatus'], types: ['Active', 'Active Under Contract', 'Pending'], selected: [], min: -1, max: 2, boundaries: { floor: -1, ceil: 2 } }
	};

	viewport = {
		nE: { lat: 0, lng: 0 },
		nW: { lat: 0, lng: 0 },
		sE: { lat: 0, lng: 0 },
		sW: { lat: 0, lng: 0 }
	};

	timer: any;
	filtersToggled = false;

	ranges = [
		{ divider: 1e9, suffix: 'B' },
		{ divider: 1e6, suffix: 'M' },
		{ divider: 1e3, suffix: 'K' }];

	styles: any[] = [
		{ featureType: 'water', stylers: [{ color: '#B3D9FB' }] },
		//{ featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#EDEBE8'}] },
		{ featureType: 'poi.business', stylers: [{ visibility: 'off' }] }
	];

	constructor(private mapsAPILoader: MapsAPILoader, private proxyService: ProxyServiceService,
		private geoCoder: AgmGeocoder, private scrollToService: ScrollToService,
		private route: ActivatedRoute, public mapService: MapService) {

		this.isLoading = true;
		this.refreshListings();

		this.proxyService.currentListings.subscribe((proplist: IPropertyListingModel[]) => {
			console.log(proplist);
			this.isLoading = false;
			if (!this.mapService.isSavedSearch && !this.mapService.isStatefulReload) {
				this.mapService.listings = proplist.sort((a, b) => a.listPrice - b.listPrice);
			}

			if (this.mapService.searchString === '') {
				this.mapService.cityPoints = [];
				this.mapService.city = '';
			}

			if (proplist.length !== 0 && !this.mapService.isStatefulReload) {
				console.log('Clearing selected listing state');
				this.mapService.listingsState = [];

				console.log("listingsState1: ", this.mapService.listingsState);

				for (let i = 0; i <= proplist.length; i++) {
					this.mapService.listingsState.push({ selected: false });
				}

				console.log("listingsState2: ", this.mapService.listingsState);

				if (this.mapService.city && this.mapService.currentZoom >= 20) {
					console.log('ATTEMPTING TO LOCATE CLOSEST POINT');
					proplist.forEach(element => {
						const pointPos = { lat: element.geo.lat, lng: element.geo.lng };
						if ((pointPos.lat < this.mapService.currentPos.lat + 0.0002 && pointPos.lat > this.mapService.currentPos.lat - 0.0002)
							&& (pointPos.lng < this.mapService.currentPos.lng + 0.0003 && pointPos.lng > this.mapService.currentPos.lng - 0.0003)) {
							this.displayProperty(proplist.indexOf(element));
						}
					});
				}
			}
		});
	}

	mapReady(map) {
		this.mapService.isStatefulReload = true;
		this.map = map;
		if (this.mapService.currentPos.lat && this.mapService.currentPos.lng) {
			console.log('Panning to last map state...');
			//this.mapService.listings = [...this.mapService.listings];
			this.map.panTo({ lat: this.mapService.currentPos.lat, lng: this.mapService.currentPos.lng });
			this.map.setZoom(this.mapService.currentZoom);
			setTimeout(() => {
				this.mapService.isStatefulReload = false;
			}, 1000);
		} else {
			this.mapService.isStatefulReload = false;
		}
		this.mapsAPILoader.load().then(() => {
			if (!this.mapService.isSavedSearch && !this.mapService.currentPos.lat && !this.mapService.currentPos.lng
				&& !this.route.snapshot.queryParams.searchString) {
				this.panToCurrentPosition();
			}
		});
	}

	ngOnInit() {

		if (Object.keys(this.route.snapshot.queryParams).length > 2) {
			let savedSearch = (this.route.snapshot.queryParams.hasOwnProperty('cities')) ? this.route.snapshot.queryParams.cities : this.route.snapshot.queryParams.city + ',' + this.route.snapshot.queryParams.city;
			this.search(savedSearch);

		} else if (this.route.snapshot.queryParams.savedSearchId) {
			this.mapService.isSavedSearch = true;
			this.mapService.isSearchSaved = true;
			this.mapService.city = this.route.snapshot.queryParams.city;
			this.mapService.state = this.route.snapshot.queryParams.state;
			this.isLoading = true;
			console.log('Saved Search! Getting Results!');
			this.geoCoder.geocode({ address: this.mapService.city + ', ' + this.mapService.state }).subscribe((result: GeocoderResult[]) => {
				this.map.panTo({ lat: result[0].geometry.location.lat(), lng: result[0].geometry.location.lng() });
			});
			this.proxyService.getCityPolyPoints(this.mapService.city, this.mapService.state).subscribe((result: any) => {
				this.mapService.cityPoints = result.filter((obj) => {
					return obj.type === 'administrative';
				})[0].geojson.coordinates[0];
			});
			this.proxyService.getSavedSearch(this.route.snapshot.queryParams.savedSearchId).subscribe((result) => {
				console.log('Got Results!');
				this.mapService.listings = result;
				this.mapService.listingsState = [];

				for (let i = 0; i <= result.length; i++) {
					this.mapService.listingsState.push({ selected: false });
				}
				this.isLoading = false;
			});
		} else {
			console.log("HELLO THERE!!!");
			this.mapService.isSavedSearch = false;
			this.mapService.isSearchSaved = false;
			this.mapService.searchString = this.route.snapshot.queryParams && this.route.snapshot.queryParams.searchString ?
				this.route.snapshot.queryParams.searchString : this.mapService.searchString;
				
			console.log("this.mapService.searchString: ", this.mapService.searchString);
			navigator.geolocation.getCurrentPosition((position) => {
				console.log("POSITION: ", position);
				this.mapService.deviceLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
			});

			if (this.mapService.searchString) {
				console.log('QUERY PARAM DETECTED');
				this.getSuggestions();
				setTimeout(() => {
					this.search(this.searchSuggestions[0].description.split(', USA')[0]);
				}, 1000);
			}
		}
	}

	panToCurrentPosition() {
		navigator.geolocation.getCurrentPosition((position) => {
			this.map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
			this.isLoading = false;
			this.mapService.deviceLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
		});
	}

	setBaths(value: number) {
		if (value < 5) {
			this.filters.baths.max = value;
			this.filters.baths.min = 0;
		} else {
			this.filters.baths.max = 10;
			this.filters.baths.min = value;
		}
		this.processFilters();
	}

	setBeds(value: number) {
		if (value < 5) {
			this.filters.beds.max = value;
			this.filters.beds.min = 0;
		} else {
			this.filters.beds.max = 10;
			this.filters.beds.min = value;
		}
		this.processFilters();
	}

	setGarage(value: number) {
		if (value < 5) {
			this.filters.garageSpaces.max = value;
			this.filters.garageSpaces.min = 0;
		} else {
			this.filters.garageSpaces.max = 10;
			this.filters.garageSpaces.min = value;
		}
		this.processFilters();
	}

	setStatus(value: number) {
		if (!this.filters.propertyStatus.selected.includes(value)) {
			this.filters.propertyStatus.selected.push(value);
			this.filters.propertyStatus.min = 1;
		} else {
			this.filters.propertyStatus.selected.splice(this.filters.propertyStatus.selected.indexOf(value), 1);
			this.filters.propertyStatus.selected = [...this.filters.propertyStatus.selected];
			if (this.filters.propertyStatus.selected.length === 0) {
				this.filters.propertyStatus.min = 0;
			}
		}
		this.processFilters();
	}

	setPropertyType(value: number) {
		if (!this.filters.propertyType.selected.includes(value)) {
			this.filters.propertyType.selected.push(value);
			this.filters.propertyType.min = 1;
		} else {
			this.filters.propertyType.selected.splice(this.filters.propertyType.selected.indexOf(value), 1);
			this.filters.propertyType.selected = [...this.filters.propertyType.selected];
			if (this.filters.propertyType.selected.length === 0) {
				this.filters.propertyType.min = 0;
			}
		}
		this.processFilters();
	}


	formatNumber(n) {
		if (n) {
			for (let i = 0; i < this.ranges.length; i++) {
				if (n >= this.ranges[i].divider) {
					return (n / this.ranges[i].divider).toString() + this.ranges[i].suffix;
				}
			}
			return n.toString();
		} else {
			return 'Unspecified';
		}
	}

	getSuggestions() {
		if (this.mapService.searchString) {
			this.showResults = true;
			this.autoComplete.getPlacePredictions({
				input: this.mapService.searchString,
				types: ['geocode'],
				componentRestrictions: { country: 'US' }
			}, this.processSuggestions);
		}
	}

	displayProperty(index: number) {
		const config: ScrollToConfigOptions = {
			target: 'listing' + index,
			container: 'scroll-container',
			easing: 'easeInOutCubic',
		};
		if (this.mapService.listingsToggle === false) {
			this.mapService.listingsState[index].selected = true;
			this.mapService.listingsToggle = true;
			setTimeout(() => {
				const listingHeight = document.getElementById('listing' + index).clientHeight / 2;
				const listingContainerHeight = (document.getElementById('scroll-container').clientHeight / 2) >
					listingHeight ? document.getElementById('scroll-container').clientHeight / 2 : listingHeight;
				config.offset = -(listingContainerHeight - listingHeight);
				this.scrollToService.scrollTo(config);
				const listingMarker = document.getElementById('marker' + index);
				listingMarker.parentElement.style.zIndex = '99';
			}, 1000);
		} else {
			this.mapService.listingsState[index].selected = !this.mapService.listingsState[index].selected;
			setTimeout(() => {
				const listingHeight = document.getElementById('listing' + index).clientHeight / 2;
				const listingContainerHeight = (document.getElementById('scroll-container').clientHeight / 2) >
					listingHeight ? document.getElementById('scroll-container').clientHeight / 2 : listingHeight;
				config.offset = -(listingContainerHeight - listingHeight);
				this.scrollToService.scrollTo(config);
				const listingMarker = document.getElementById('marker' + index);
				listingMarker.parentElement.style.zIndex = '99';
			}, 200);
		}
	}

	saveSearch() {
		const savedSearch: ISearchFiltersModel = {
			Search: this.mapService.filterString,
			SearchTitle: this.saveSearchName !== undefined ? this.saveSearchName : this.mapService.city + ', ' + this.mapService.state,
			City: this.mapService.city,
			State: this.mapService.state,
			SendEmail: this.notificationInterval !== 'Never' ? this.isNotificationEmail : false,
			SendNotification: this.notificationInterval !== 'Never' ? this.isNotificationMobile : false,
			placesId: this.mapService.placesId,
			SearchType: 1,
			UpdateFrequency: this.notificationInterval
		};

		this.proxyService.saveSearch(savedSearch).subscribe((result) => {
			this.mapService.isSearchSaved = true;
			this.showSaveSearchOverlay = false;
		});
	}

	selectProperty(index: number) {
		if (index !== this.mapService.lastSelectedListingIndex && this.mapService.listingsState[index].selected === true) {
			this.mapService.listingsState[index].selected = false;
			document.getElementById('marker' + index).parentElement.style.zIndex = 'auto';
			this.mapService.lastSelectedListingIndex = index;
		} else if (index === this.mapService.lastSelectedListingIndex && this.mapService.listingsState[index].selected === false) {
			this.mapService.listingsState[index].selected = true;
			document.getElementById('marker' + index).parentElement.style.zIndex = '99';
			this.mapService.lastSelectedListingIndex = index;
		} else if (index === this.mapService.lastSelectedListingIndex && this.mapService.listingsState[index].selected === true) {
			this.mapService.listingsState[index].selected = false;
			document.getElementById('marker' + index).parentElement.style.zIndex = 'auto';
			this.mapService.lastSelectedListingIndex = index;
		} else if (index !== this.mapService.lastSelectedListingIndex && this.mapService.listingsState[index].selected === false) {
			this.mapService.listingsState[index].selected = true;
			document.getElementById('marker' + index).parentElement.style.zIndex = '99';
			this.mapService.lastSelectedListingIndex = index;
		}
	}

	processFilters(event?: any) {
		this.mapService.filterString = '';
		Object.keys(this.filters).forEach((key, index) => {
			if (this.filters[key].min !== this.filters[key].boundaries.floor) {
				if (key === 'beds' && this.filters[key].min === 5 || key === 'baths' && this.filters[key].min === 5 ||
					key === 'garageSpaces' && this.filters[key].min === 5) {
					this.mapService.filterString += '&' + this.filters[key].labels[0].toLowerCase() + '=' + this.filters[key].min;
				} else if (key === 'propertyType' && this.filters[key].selected.length !== 0
					|| key === 'propertyStatus' && this.filters[key].selected.length !== 0) {
					this.filters[key].selected.forEach((item) => {
						this.mapService.filterString += '&' + this.filters[key].labels[0].toLowerCase() + '=' + item;
					});
				} else if (!['propertyStatus', 'propertyType', 'beds', 'baths', 'garageSpaces'].includes(key)) {
					this.mapService.filterString += '&' + this.filters[key].labels[0].toLowerCase() + '=' + this.filters[key].min;
				}
			}
			if (this.filters[key].max !== this.filters[key].boundaries.ceil) {
				this.mapService.filterString += '&' + this.filters[key].labels[1].toLowerCase() + '=' + this.filters[key].max;
			}
		});
	}

	setFilter(value: any, property: string) {
		if (property === 'status') {
			this.filters.propertyStatus.min = value;
		} else if (property === 'type') {
			this.filters.propertyType.min = value;
		}
		this.processFilters({ value: value });
	}

	getBounds(event) {

		const northEast = {
			lat: event.getNorthEast().lat(),
			lng: event.getNorthEast().lng()
		};

		const southWest = {
			lat: event.getSouthWest().lat(),
			lng: event.getSouthWest().lng()
		};

		this.viewport = {
			nE: northEast,
			nW: { lat: northEast.lat, lng: southWest.lng },
			sE: { lat: southWest.lat, lng: northEast.lng },
			sW: southWest
		};

		this.viewChange();
	}

	viewChange(event?: number) {
		if (event) {
			this.mapService.currentZoom = event;
		}
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (!this.mapService.isSavedSearch && !this.mapService.isStatefulReload) {
				console.log('View Changed');
				this.isLoading = true;
				this.refreshListings();
			}
		}, 600);
	}

	centerChange(event) {
		this.mapService.currentPos = { lat: event.lat, lng: event.lng };
	}

	refreshListings() {
		if (this.mapService.city) {
			this.proxyService.getPropertyListingsMap(this.mapService.city + ',' + this.mapService.state, this.viewport, this.mapService.filterString).subscribe();
		} else {
			this.proxyService.getPropertyListingsMap(this.mapService.searchString.replace(/\s/g, ''), this.viewport, this.mapService.filterString).subscribe();
		}
	}

	processSuggestions = (payload: any[]) => {
		this.searchSuggestions = payload;
	}

	search(place?: string) {
		console.log("PLACE: ", place);
		this.isLoading = true;
		if (place) {
			this.mapService.searchString = place.split(', USA')[0];
			this.geoCoder.geocode({ address: this.mapService.searchString }).subscribe((result: GeocoderResult[]) => {

				console.log("RESULT SEARCH: ", result);
				this.mapService.city = result[0].address_components.filter((obj) => {
					return obj.types.includes('locality');
				})[0].short_name;
				this.mapService.state = result[0].address_components.filter((obj) => {
					return obj.types.includes('administrative_area_level_1');
				})[0].short_name;

				this.mapService.placesId = result[0].place_id;

				if (result[0].types.includes('premise') || result[0].types.includes('street_address')) {
					this.mapService.zipCode = result[0].address_components.filter((obj) => {
						return obj.types.includes('postal_code');
					})[0].long_name;
					this.map.panTo({ lat: result[0].geometry.location.lat(), lng: result[0].geometry.location.lng() });
					this.map.setZoom(20);
				} else {
					this.map.panTo({ lat: result[0].geometry.location.lat(), lng: result[0].geometry.location.lng() });
					const search = this.mapService.searchString.split(',');
					this.map.setZoom(13);
					this.proxyService.getCityPolyPoints(search[0], search[1]).subscribe((result: any) => {
						this.mapService.cityPoints = result.filter((obj) => {
							return obj.type === 'administrative';
						})[0].geojson.coordinates[0];
					});
				}
			});
		} else {
			this.proxyService.getLatLngService(this.mapService.searchString).subscribe((result: any) => {
				this.map.panTo({ lat: result.results[0].geometry.lat, lng: result.results[0].geometry.lng });
				this.proxyService.getPropertyListingsMap(this.mapService.searchString.replace(/\s/g, ''), this.viewport).subscribe();
			});
		}
	}

	keydown(event) {
		if (event.key === 'Enter') {
			this.showResults = false;
			this.mapService.isSavedSearch = false;
			this.mapService.isSearchSaved = false;
			this.search(this.searchSuggestions[0].description.split(', USA')[0]);
		}
	}

}
