import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ProxyServiceService } from '../services/proxy-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { AppInfoService } from '../services/app-info.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isDevMode } from '@angular/core';



@Component({
  selector: 'app-neren-page',
  templateUrl: './neren-page.component.html',
  styleUrls: ['./neren-page.component.less']
})
export class NerenPageComponent implements OnInit {
  agentId: any = null;
  agentMid: any = null;
  agentOid: any = null;
  agentCode: any = null;
  agentInfo: any;
  mailText: string;
  isDevEnv: boolean;
  @ViewChild('myconfirmmodal', { static: false }) public myconfirmmodal: TemplateRef<any>; // Note: TemplateRef
  @ViewChild('landingModal', { static: true }) landingModal: any;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public appInfo: AppInfoService,
    config: NgbModalConfig,
    private proxyServiceService: ProxyServiceService,
    private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.modalService.open(this.landingModal, { size: 'lg', backdrop: 'static' });
    this.isDevEnv = isDevMode();
    console.log(document.referrer);
    this.loginService.profileUpdated.subscribe(profileInfo => {
      this.router.navigateByUrl('/agent-signup');
    });

    this.agentId = this.activatedRoute.snapshot.queryParams['aid'];
    this.agentMid = this.activatedRoute.snapshot.queryParams['mid'];
    this.agentCode = this.activatedRoute.snapshot.queryParams['code'];
    this.agentOid = this.activatedRoute.snapshot.queryParams['oid'];
    if (this.agentId && this.agentMid) {
      let payload = {
        aid: this.agentId,
        mid: this.agentMid
      }
      this.proxyServiceService.getAgentDetails(payload).subscribe(x => {
        this.agentInfo = x.data;
      })
    }
  }

  enrollAgent() {


    this.proxyServiceService.enrollAgentId(this.agentId, this.agentCode).subscribe(x => {
      console.log(x);
      this.close('enrollAgent');
      this.modalService.open(this.myconfirmmodal);

      setTimeout(() => {
        this.modalService.dismissAll(this.myconfirmmodal);
        if (this.agentId && this.agentMid, this.agentOid, this.agentCode) {
          let queryParams = {
            oid: this.agentOid,
            mid: this.agentMid,
            aid: this.agentId,
            code: this.agentCode,
          }
          this.router.navigate(['lightning-setup'], { queryParams: queryParams });
        }
      }, 3000);
    });
  }

  mailMe(mailid) {
    this.mailText = "mailto:" + mailid; // add the links to body
    window.location.href = this.mailText;
  }

  gotoInstantAccessVideo() {
    window.open('https://drive.google.com/file/d/17Ogq_QI0fuXbxalC6cMuUVTioBAOa-Mi/view', '_blank');
  }
  gotoInstantAccessVideo30sec() {
    window.open('https://drive.google.com/file/d/17JFHiThjMRBNX47f5LxYYyzUOcJCxAGv/view', '_blank');
  }

  redirectToAccountSignup() {
    if (this.isDevEnv) {
      window.open('https://account-staging.domii.io', '_self');
    } else {
      window.open('https://account.domii.io', '_self');
    }
  }


  signup() {
    this.loginService.signUpNoAgreement();
  }

  login() {
    this.loginService.login();
  }
  open(content) {
    this.modalService.open(content);
  }

  close(content) {
    this.modalService.dismissAll(content);
  }
  
  redirectToHome() {
    if (this.isDevEnv) {
      window.open('https://staging.domii.io', '_self');
    } else {
      window.open('https://domii.io', '_self');
    }
  }

}
