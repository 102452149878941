import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { ProxyServiceService } from '../services/proxy-service.service';
import { LoginService } from '../services/login.service';
import { ISearchFiltersModel } from '../models/search-filters';
import { SavedSearchAnimations } from '../animations/saved-search-animations';
import * as moment from 'moment';
import { BroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'app-saved-searches-page',
  templateUrl: './saved-searches-page.component.html',
  styleUrls: ['./saved-searches-page.component.less'],
  animations: [SavedSearchAnimations.slideUpIn]
})
export class SavedSearchesPageComponent implements OnInit {

  savedSearches: ISearchFiltersModel[] = [];
  searchStringParams: any[] = [];
  searchTitle: string;
  moment = moment;
  ranges = [
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' }];
  favorites = [];
  isSavedSearches = true;
  isFavorites = false;

  constructor(private router: Router,
              private serviceProxy: ProxyServiceService,
              public loginService: LoginService,
              private broadcastService: BroadcastService) { }

  ngOnInit() {
    // only logged in users can view this page
    // if (!this.loginService.user) {
    //   this.router.navigate(['/']);
    // }

    if (this.loginService.token) {
      this.serviceProxy.getsavedSearches().subscribe(search => {
        this.savedSearches = search;
        this.savedSearches.reverse();

        this.savedSearches.forEach(search => {
          this.searchStringParams.push(this.parseQuery(search.Search));
        });

        // this.savedSearches.sort((a, b) => {
        //   return new Date(b[0].LastSearchDate).getTime() - new Date(a[0].LastSearchDate).getTime();
        // });

      });
      this.serviceProxy.getFavorites().subscribe((favorites) => {
        this.favorites = favorites;
      })
    } else {
      this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
        this.serviceProxy.getsavedSearches().subscribe(search => {
          this.savedSearches = search;
          this.savedSearches.reverse();

          this.savedSearches.forEach(search => {
            this.searchStringParams.push(this.parseQuery(search.Search));
          });

          this.serviceProxy.getFavorites().subscribe((favorites) => {
            this.favorites = favorites;
          })

          // this.savedSearches.sort((a, b) => {
          //   return new Date(b[0].LastSearchDate).getTime() - new Date(a[0].LastSearchDate).getTime();
          // });

        });
      });
    }
  }

  enableFavorites() {
    this.isSavedSearches = false;
    setTimeout(() => {
      this.isFavorites = true;
    }, 500);
  }

  enableSavedSearches() {
    this.isFavorites = false;
    setTimeout(() => {
      this.isSavedSearches = true;
    }, 500);
  }

  formatNumber(n) {
    if (n) {
      for (let i = 0; i < this.ranges.length; i++) {
        if (parseInt(n) >= this.ranges[i].divider) {
          return (parseInt(n) / this.ranges[i].divider).toString() + this.ranges[i].suffix;
        }
      }
      return n.toString();
    } else {
      return '-';
    }
  }

  parseQuery(queryString: string) {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  deleteSavedSearch(id: string) {
    this.serviceProxy.deleteSavedSearch(id).subscribe((result) => {
      const deletedSave = this.savedSearches.find(search => {
        return search.SearchId === id;
      });

      this.savedSearches.splice(this.savedSearches.indexOf(deletedSave), 1);
    });
  }

  updateUpdateFrequency(value: string, search: ISearchFiltersModel, index: number) {
    search.UpdateFrequency = value;

    if (value === 'Never') {
      search.SendEmail = false;
      search.SendNotification = false;
    }
    
    if (value !== 'Never' && search.SendEmail === false && search.SendNotification === false) {
      search.SendEmail = true;
    }
    this.serviceProxy.updateSavedSearch(search).subscribe((result) => {
      this.savedSearches[index].UpdateFrequency = value;
    });
  }

  updateNotificationPreference(value: string, search: ISearchFiltersModel, index: number) {
    if (value === 'push') {
      search.SendNotification = !search.SendNotification;
    } else if (value === 'email') {
      search.SendEmail = !search.SendEmail;
    }

    if (search.SendEmail === false && search.SendNotification === false) {
      search.UpdateFrequency = 'Never';
    }

    if (search.UpdateFrequency === 'Never' && search.SendEmail === true ||
    search.UpdateFrequency === 'Never' && search.SendNotification === true) {
      search.UpdateFrequency = 'Daily';
    }

    this.serviceProxy.updateSavedSearch(search).subscribe((result) => {
      this.savedSearches[index] = search;
    });
  }
}
