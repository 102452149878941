import { Component, OnInit } from '@angular/core';
import { ProxyServiceService } from '../services/proxy-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointment-confirm',
  templateUrl: './appointment-confirm.component.html',
  styleUrls: ['./appointment-confirm.component.less']
})
export class AppointmentConfirmComponent implements OnInit {
  responseMessage: string = '';
  errorMessage: string = 'There is an error. please check back later!';

  constructor(private proxyService: ProxyServiceService, private route: ActivatedRoute) { }

  ngOnInit() {
    const cmd = this.route.snapshot.queryParams.cmd;
    const eventId = this.route.snapshot.queryParams.eventId;
    this.proxyService.getApptConfirmation(cmd, eventId).subscribe(response => {
      if (response) {
        this.responseMessage = response;
      }
    }, error => {
      if (error && error.error) {
        this.errorMessage = error.error;
      }
    });
  }

}
