import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/api';
import { SearchType } from 'src/app/common-search/common-search-type-enum';
import { ProxyServiceService } from 'src/app/services/proxy-service.service';
import { AlertMessageDialogComponent } from '../alert-message-dialog/alert-message-dialog.component';
import { ConfirmProfessionalScheduleComponent } from '../confirm-professional-schedule/confirm-professional-schedule.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-professional-search-dialog',
  templateUrl: './professional-search-dialog.component.html',
  styleUrls: ['./professional-search-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ProfessionalSearchDialogComponent implements OnInit {

  searchType = SearchType.Professional;
  selectedProfessional: string;
  isPerformingSearch: boolean;
  filteredProfessionals: any[] = [];
  selectedProfessionalObj: any;

  constructor(public config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public service: ProxyServiceService,
    public dialogService: DialogService,
    public loginService: LoginService) { }

  ngOnInit() {
    this.searchType = SearchType.Professional;
    this.selectedProfessional = this.config.data.selectedProfessional;

    this.isPerformingSearch = true;
    this.service.getProfessionalMemberships(null, this.selectedProfessional).subscribe((professionals: any[]) => {
      this.isPerformingSearch = false;
      if (professionals !== null && professionals.length > 0) {
        this.selectedProfessionalObj = professionals[0];
        this.config.header = "Find a Professional - " + this.selectedProfessionalObj.name;
      }
    });
  }

  onAutoCompletePerformed(event: any) {
    this.service.getProfessionalMemberships(null, event.query).subscribe((professionals: any[]) => {
      this.filteredProfessionals = professionals;
    });
  }
  
  onSearchPerformed(event: any) {
    this.isPerformingSearch = true;
  }

  onProfessionalSearchPerformed(event: any) {

    if (!this.isUserAuthenticated()) {
      return;
    }

    this.isPerformingSearch = true;

    let lastUsedAgent = this.loginService.getLastUsedAgent(event.value.professionalSearchField.type);
    let lastUsedAgentId = lastUsedAgent ? lastUsedAgent.AgentId : null;

    let schedulePayload = {
      LeadSource: 'Web',
      LeadReason: 'Info',
      AgentId: lastUsedAgentId,
      LeadType: event.value.professionalSearchField.type,
      LeadDescription: '',
      MlsAgent:'',
      ListingState: '',
      RequestDate: '',
      RequestedHours: 0.0,
      Lat: event.value.lat,
      Lng: event.value.lng,
      Address: event.value.professionalSearchAddressField
    };

    this.service.scheduleAppointment(schedulePayload).subscribe((response: any) => {
      this.isPerformingSearch = false;

      const ref = this.dialogService.open(ConfirmProfessionalScheduleComponent, {
        data: {
          user: this.loginService.user,
          professional: event.value.professionalSearchField,
          buttonOptions: {
            close: {
              label: 'Close',
              command: () => {
                this.dialogRef.close();
                ref.close();
              }
            }
          }
        },
        transitionOptions: '1ms',
      });
    },
    (error: any) => {
      let e = error;
      this.isPerformingSearch = false;

      const ref = this.dialogService.open(AlertMessageDialogComponent, {
        styleClass: 'alert-msg-dialog',
        data: {
          icon: 'pi-exclamation-triangle',
          message: 'There was a problem scheduling an IA9 agent at the moment.',
          subtitle: 'Please try again shortly.',
          buttonOptions: {
            ok: {
              label: 'OK',
              command: () => { ref.close() }
            }
          }
        },
        header: 'Error Scheduling Professional',
        transitionOptions: '1ms',
      });
    });
  }

  isUserAuthenticated() {
    if (!this.loginService.token) {
      const ref = this.dialogService.open(AlertMessageDialogComponent, {
        styleClass: 'alert-msg-dialog',
        data: {
          icon: 'pi-exclamation-triangle',
          message:
            'You must be logged in in order to schedule an IA9 Professional.',
          subtitle: 'Click on the button below in order to log in.',
          buttonOptions: {
            ok: {
              label: 'OK, Log Me In',
              command: () => {
                this.loginService.login();
              }
            }
          }
        },
        header: 'Error Scheduling Professional',
        transitionOptions: '1ms'
      });

      return false;
    }

    return true;
  }
}
