import { Component, OnInit } from "@angular/core";
import { Contact } from "src/app/models/contactus";
import { ProxyServiceService } from "src/app/services/proxy-service.service";

@Component({
  selector: "app-contact-section",
  templateUrl: "./contact-section.component.html",
  styleUrls: ["./contact-section.component.less"]
})
export class ContactSectionComponent implements OnInit {
  contact: Contact = {
    name: "",
    email: "",
    comments: ""
  };
  hideForm: boolean = false;
  formValidated: boolean = false;

  constructor(private contactus: ProxyServiceService) {}

  ngOnInit() {
    this.onFormValidation();
  }

  submitForm(form): void {
    this.contact.name = form.value.name;
    this.contact.email = form.value.email;
    this.contact.comments = form.value.comments;

    this.contactus.submitContactForm(this.contact).subscribe();
    this.clearForm();
    this.hideForm = true;
  }

  clearForm(): void {
    this.contact.name = "";
    this.contact.email = "";
    this.contact.comments = "";
  }

  validateForm() {
    this.onFormValidation();
  }

  onFormValidation() {
    this.formValidated =
      this.contact.name !== "" &&
      this.contact.email !== "" &&
      this.contact.comments !== "";
  }
}
