import { Component, OnInit } from "@angular/core";
import { ProxyServiceService } from "../../services/proxy-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LightningData,
  LightningValidateResponse,
} from "../lightning-setup.model";
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
@Component({
  selector: "app-ls-edit",
  templateUrl: "./ls-edit.component.html",
  styleUrls: ["./ls-edit.component.less"],
})
export class LsEditComponent implements OnInit {
  lightningData: LightningData;
  errorMessage: string;
  notNeren: boolean;
  queryParms: any;
  currentHeader: string;
  currentStep = 0;
  errorStartTime = false;
  errorEndTime = false;
  steps = [
    {
      header: "Listing Agent Information",
      isComplete: false,
    },
    {
      header: "Listing Information",
      isComplete: false,
    },
    {
      header: "Seller Information",
      isComplete: false,
    },
    {
      header: "Showing Schedule",
      isComplete: false,
    },
    {
      header: "Schedule Recap",
      isComplete: false,
    },
    // ,
    // {
    //   header: 'Blackout Dates and Showing Instructions',
    //   isComplete: false,
    // }
  ];
  weekdays = [
    {
      label: "Sunday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Monday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Tuesday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Wednesday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Thursday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Friday",
      times: [],
      rangeLables: [],
    },
    {
      label: "Saturday",
      times: [],
      rangeLables: [],
    },
  ];
  blackoutDates = [];
  blackoutDate: NgbDate | null = null;
  schedule = {
    dayOfWeek: {
      sunday: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
    },
    blackoutDates: [],
  };
  agentForm = this.fb.group({
    aname: [{ value: "", disabled: true }, Validators.required],
    aeml: [
      { value: "", disabled: true },
      Validators.compose([Validators.required, Validators.email]),
    ],
    aphn: [{ value: "" }, [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/
    )]],
    bnm: [{ value: "", disabled: true }, Validators.required],
    aid: ["", Validators.required],
  });

  listingForm = this.fb.group({
    mid: [null, Validators.required],
    pid: [null, Validators.required],
  });
  sellerForm = this.fb.group({
    snme: ["", Validators.required],
    seml: ["", Validators.compose([Validators.required, Validators.email])],
    sphn: [
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10), Validators.pattern(/^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/
    ),
      ]),
    ],
    secphn: [""],
    showingOpt: ["baccept"],
    baccept: [false],
    snme2: [""],
    seml2: ["", Validators.compose([Validators.email])],
    sphn2: [""],
    secphn2: [""],
  });
  dateForm = this.fb.group({
    st: ["09:00:00", Validators.required],
    et: ["21:00:00", Validators.required],
    pscs: this.fb.array([]),
    inst: ["", Validators.required],
  });

  // convenience getter for easy access to form fields
  get agentF() {
    return this.agentForm.controls;
  }
  get sellerF() {
    return this.sellerForm.controls;
  }
  get lightF() {
    return this.listingForm.controls;
  }
  get dateF() {
    return this.dateForm.controls;
  }

  startTime: NgbTimeStruct = {
    hour: 8,
    minute: 0,
    second: 0,
  };
  endTime: NgbTimeStruct = {
    hour: 21,
    minute: 0,
    second: 0,
  };
  selectAllDefaultTime = false;
  fromDate: NgbDate;
  fromDateDisplay: any;
  toDateDisplay: any;
  toDate: NgbDate | null = null;
  lightningComplete = false;
  selectedCalendarDay = 0;
  isDaySelected = false;
  moment = moment;
  agentId: any;
  isSubmitted: boolean = false;
  weekDatesAndTimes = [];
  propertyShowing = null;
  weekdaysValid: boolean = false;
  constructor(
    public formatter: NgbDateParserFormatter,
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    private proxyService: ProxyServiceService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.currentHeader = this.steps[0].header;
    // set default value
    this.fromDate = this.calendar.getToday();
    this.fromDateDisplay = this.formatDate(this.fromDate);
    this.toDate = calendar.getNext(calendar.getToday(), "d", 180);
    this.toDateDisplay = this.formatDate(this.toDate);
  }

  ngOnInit() {
    this.activeRoute.queryParamMap.subscribe((result) => {
      this.agentId = result.get("aid");
    });

    this.proxyService.getLightningData().subscribe((res) => {
      if (!res) {
        return;
      }
      this.lightningData = res;
      if (!this.lightningData.isListingAgent) {
        this.router.navigate(["lightning-setup"], {
          queryParams: this.queryParms,
        });
      }
      this.propertyShowing = this.lightningData.propertyShowing;
      //steps0
      this.agentForm.patchValue({
        aname: `${this.lightningData.agentFirstName} ${this.lightningData.agentLastName}`,
        aeml: this.lightningData.agentEmail
          ? this.lightningData.agentEmail
          : "",
        aphn: this.lightningData.mobileNo ? this.lightningData.mobileNo : "",
        bnm: this.lightningData.businessName
          ? this.lightningData.businessName
          : "",
        aid: this.agentId ? this.agentId : "",
      });
      this.agentForm.updateValueAndValidity();

      this.listingForm.setValue({
        mid: this.lightningData.mlsId ? this.lightningData.mlsId : "",
        pid: this.lightningData.listingId ? this.lightningData.listingId : "",
      });
      this.listingForm.updateValueAndValidity();

      //steps2
      if (this.lightningData.propertyShowing) {
        this.sellerForm.patchValue({
          snme: this.lightningData.propertyShowing.snme,
          seml: this.lightningData.propertyShowing.seml,
          sphn: this.lightningData.propertyShowing.sphn,
          secphn: this.lightningData.propertyShowing.secphn,
          snme2: this.lightningData.propertyShowing.snme2,
          seml2: this.lightningData.propertyShowing.seml2,
          sphn2: this.lightningData.propertyShowing.sphn2,
          secphn2: this.lightningData.propertyShowing.secphn2,
        });
        if (
          this.lightningData.propertyShowing.bsms &&
          this.lightningData.propertyShowing.beml
        ) {
          this.sellerForm.controls["showingOpt"].setValue("bsms");
        }
        if (this.lightningData.sellerMustConfirm) {
          this.sellerForm.controls["showingOpt"].setValue("baccept");
        }
        if (this.lightningData.propertyShowing.iamng) {
          this.sellerForm.controls["showingOpt"].setValue("iamng");
        }
        //step3
        this.dateForm.setValue({
          st: this.lightningData.propertyShowing.st,
          et: this.lightningData.propertyShowing.et,
          pscs: [],
          inst: this.lightningData.propertyShowing.inst,
        });
        var endDate = this.formatter.parse(
          this.lightningData.propertyShowing.et
        );
        var startDate = this.formatter.parse(
          this.lightningData.propertyShowing.st
        );
        this.fromDate = new NgbDate(
          startDate.year,
          startDate.month,
          startDate.day
        );
        this.fromDateDisplay = this.formatDate(this.fromDate);
        this.toDate = new NgbDate(endDate.year, endDate.month, endDate.day);
        this.toDateDisplay = this.formatDate(this.toDate);
        this.schedule = this.lightningData.propertyShowing.iatext;
        this.schedule.blackoutDates.forEach((element) => {
          var d = moment(element);
          this.blackoutDates.push({
            date: d,
            label: element,
          });
        });

      }
      this.sellerForm.updateValueAndValidity();
    });
    this.activeRoute.queryParams.subscribe((result) => {
      this.queryParms = result;
    });
    // this.sellerForm.controls["showingOpt"].valueChanges.subscribe((value) => {
    // if (value == "baccept") {
    if (this.sellerForm.controls["showingOpt"].value === "baccept") {
      this.endTime.hour = 21;
      this.dateForm.get("et").setValue(
        moment()
          .date(this.toDate.day)
          .month(this.toDate.month - 1)
          .year(this.toDate.year)
          .hour(21)
          .second(0)
          .minute(0)
          .format("YYYY-MM-DDTHH:mm:ss")
      );
      this.setWeekdays(true);
    } else {
      this.setWeekdays();
    }
    // });
  }

  showingOption() {
    if (this.sellerForm.controls["showingOpt"].value === "baccept") {
      this.endTime.hour = 21;
      this.dateForm.get("et").setValue(
        moment()
          .date(this.toDate.day)
          .month(this.toDate.month - 1)
          .year(this.toDate.year)
          .hour(21)
          .second(0)
          .minute(0)
          .format("YYYY-MM-DDTHH:mm:ss")
      );
      this.setWeekdays(true);
    } else {
      this.setWeekdays();
    }
  }

  startTimeChanged() {
    if (!this.startTime) {
      this.errorStartTime = true;
    } else if (this.startTime.hour < 8) {
      this.errorStartTime = true;
    } else if (this.startTime.hour > 21) {
      this.errorStartTime = true;
    } else if (this.startTime.hour == 21 && this.startTime.minute > 0) {
      this.errorStartTime = true;
    } else {
      this.errorStartTime = false;
    }
  }

  endTimeChanged() {
    if (this.startTime) {
      if (this.startTime < this.endTime) {
        this.errorEndTime = true;
      }
    }
    if (!this.endTime) {
      this.errorEndTime = true;
    } else if (this.endTime.hour < 8) {
      this.errorEndTime = true;
    } else if (this.endTime.hour > 21) {
      this.errorEndTime = true;
    } else if (this.endTime.hour == 21 && this.endTime.minute > 0) {
      this.errorEndTime = true;
    } else {
      this.errorEndTime = false;
    }
  }

  gotoNEREN() {
    this.router.navigate(["real-estate-agent/NEREN"], {
      queryParams: this.queryParms,
    });
  }

  setWeekdays(setDefault = false) {
    this.weekdays.forEach((x) => {
      x.times = [];
    });
    const startTime = moment()
      .second(0)
      .minute(this.startTime.minute)
      .hour(this.startTime.hour);
    const endTime = moment()
      .second(0)
      .minute(this.endTime.minute)
      .hour(this.endTime.hour);
    if (setDefault) {
      this.weekdays.forEach((x) => {
        // --macstermind-- console.log("X", x);
        this.schedule.dayOfWeek[x.label.toLocaleLowerCase()] = "8:00AM-9:00PM";
        x.times.push({
          label: "8:00AM-9:00PM",
          start: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        });
        x.rangeLables.push("8:00AM-9:00PM");
      });
      // --macstermind-- console.log("I'm Default: ", this.weekdays);
      // --macstermind-- console.log("START: " + moment(startTime).format("YYYY-MM-DD HH:mm:ss"));
      // --macstermind-- console.log("END: " + moment(endTime).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      // --macstermind-- console.log("I am here!", this.lightningData.propertyShowing.iatext);
      // --macstermind-- console.log("I am here!", this.lightningData.propertyShowing.iatext.dayOfWeek.sunday.split(","));
      if (this.lightningData.propertyShowing.iatext) {
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.sunday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.sunday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[0].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.sunday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.sunday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[0].times.push({
              label: this.lightningData.propertyShowing.iatext.dayOfWeek.sunday,
              start: start,
              end: end,
            });
          }
          this.weekdays[0].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.sunday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.monday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.monday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[1].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.monday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.monday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[1].times.push({
              label: this.lightningData.propertyShowing.iatext.dayOfWeek.monday,
              start: start,
              end: end,
            });
          }
          this.weekdays[1].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.monday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[2].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[2].times.push({
              label:
                this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday,
              start: start,
              end: end,
            });
          }
          this.weekdays[2].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.tuesday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[3].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[3].times.push({
              label:
                this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday,
              start: start,
              end: end,
            });
          }
          this.weekdays[3].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.wednesday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.thursday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.thursday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[4].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.thursday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.thursday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[4].times.push({
              label:
                this.lightningData.propertyShowing.iatext.dayOfWeek.thursday,
              start: start,
              end: end,
            });
          }
          this.weekdays[4].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.thursday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.friday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.friday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[5].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.friday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.friday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[5].times.push({
              label: this.lightningData.propertyShowing.iatext.dayOfWeek.friday,
              start: start,
              end: end,
            });
          }
          this.weekdays[5].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.friday.split(
              ","
            );
        }
        if (this.lightningData.propertyShowing.iatext.dayOfWeek.saturday) {
          let broken =
            this.lightningData.propertyShowing.iatext.dayOfWeek.saturday.split(
              ","
            );
          if (broken.length) {
            broken.forEach((x) => {
              let startData = x.split("-")[0];
              let endData = x.split("-")[1];
              let startAm = startData.slice(4, 6);
              let startH = Number(startData.slice(0, 4).split(":")[0]);
              let startM = Number(startData.slice(0, 4).split(":")[1]);
              let endAm = endData.slice(4, 6);
              let endtH = Number(endData.slice(0, 4).split(":")[0]);
              let endM = Number(endData.slice(0, 4).split(":")[1]);
              if (startAm == "PM") {
                startH = startH + 12;
              }
              if (endAm == "PM") {
                endtH = endtH + 12;
              }
              let start = moment().second(0).minute(startM).hour(startH);
              let end = moment().second(0).minute(endM).hour(endtH);
              this.weekdays[6].times.push({
                label: x,
                start: start,
                end: end,
              });
            });
          } else {
            let startData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.saturday.split(
                "-"
              )[0];
            let endData =
              this.lightningData.propertyShowing.iatext.dayOfWeek.saturday.split(
                "-"
              )[1];
            let startAm = startData.slice(4, 6);
            let startH = Number(startData.slice(0, 4).split(":")[0]);
            let startM = Number(startData.slice(0, 4).split(":")[1]);
            let endAm = endData.slice(4, 6);
            let endtH = Number(endData.slice(0, 4).split(":")[0]);
            let endM = Number(endData.slice(0, 4).split(":")[1]);
            if (startAm == "PM") {
              startH = startH + 12;
            }
            if (endAm == "PM") {
              endtH = endtH + 12;
            }
            let start = moment().second(0).minute(startM).hour(startH);
            let end = moment().second(0).minute(endM).hour(endtH);

            this.weekdays[6].times.push({
              label:
                this.lightningData.propertyShowing.iatext.dayOfWeek.saturday,
              start: start,
              end: end,
            });
          }
          this.weekdays[6].rangeLables =
            this.lightningData.propertyShowing.iatext.dayOfWeek.saturday.split(
              ","
            );
        }
      }
    }
    this.OnWeekdaysValid();
  }

  get baccept(): boolean {
    return this.sellerForm.get("showingOpt").value == "baccept";
  }

  formatDate(data) {
    return moment(this.formatter.format(data)).format("MMMM Do YYYY");
  }

  onFromDateSelection(date: NgbDate, d: any) {
    this.fromDate = date;
    //this.fromDate = this.validateInput(this.fromDate, moment(this.formatter.format(date)).format('MMMM Do YYYY'));
    this.fromDateDisplay = this.formatDate(date);
    this.dateForm.get("st").setValue(
      moment()
        .date(this.fromDate.day)
        .month(this.fromDate.month - 1)
        .year(this.fromDate.year)
        .hour(9)
        .second(0)
        .minute(0)
        .format("YYYY-MM-DDTHH:mm:ss")
    );
    this.dateForm.get("et").setValue(null);
    this.toDate = null;
    this.toDateDisplay = null;
  }

  onToDateSelection(date: NgbDate, d: any) {
    this.toDate = date;
    //this.toDate = this.validateInput(this.toDate, moment(this.formatter.format(date)).format('MMMM Do YYYY'));
    this.toDateDisplay = this.formatDate(date);
    this.dateForm.get("et").setValue(
      moment()
        .date(this.toDate.day)
        .month(this.toDate.month - 1)
        .year(this.toDate.year)
        .hour(17)
        .second(0)
        .minute(0)
        .format("YYYY-MM-DDTHH:mm:ss")
    );
  }

  selectWeekDay(index: number) {
    this.selectedCalendarDay = index;
    this.isDaySelected = true;
  }

  checkForDupes(time: any): boolean {
    for (let weekTime of this.weekdays[this.selectedCalendarDay].times) {
      if (
        moment(time.start).isBetween(weekTime.start, weekTime.end) ||
        moment(time.end).isBetween(weekTime.start, weekTime.end)
      ) {
        return true;
      }
    }
  }

  mailMe(mailid) {
    let mailText = "mailto:" + mailid; // add the links to body
    window.location.href = mailText;
  }

  addTimeRange() {
    const label = `${moment()
      .second(0)
      .minute(this.startTime.minute)
      .hour(this.startTime.hour)
      .format("h:mmA")}-${moment()
        .second(0)
        .minute(this.endTime.minute)
        .hour(this.endTime.hour)
        .format("h:mmA")}`;

    switch (this.selectedCalendarDay) {
      case 0:
        this.schedule.dayOfWeek.sunday = label;
        break;
      case 1:
        this.schedule.dayOfWeek.monday = label;
        break;
      case 2:
        this.schedule.dayOfWeek.tuesday = label;
        break;
      case 3:
        this.schedule.dayOfWeek.wednesday = label;
        break;
      case 4:
        this.schedule.dayOfWeek.thursday = label;
        break;
      case 5:
        this.schedule.dayOfWeek.friday = label;
        break;
      case 6:
        this.schedule.dayOfWeek.saturday = label;
        break;
    }
    const startTime = moment()
      .second(0)
      .minute(this.startTime.minute)
      .hour(this.startTime.hour);
    const endTime = moment()
      .second(0)
      .minute(this.endTime.minute)
      .hour(this.endTime.hour);

    const timeObj = {
      start: startTime,
      end: endTime,
      label,
    };

    if (!this.checkForDupes(timeObj)) {
      this.weekdays[this.selectedCalendarDay].times.push(timeObj);
      this.weekdays[this.selectedCalendarDay].rangeLables.push(label);
    }
    this.OnWeekdaysValid();
  }

  onChangeSelectAllDefaultTime(eve) {
    if (this.selectAllDefaultTime) {
      this.endTime.hour = 21;
      this.dateForm.get("et").setValue(
        moment()
          .date(this.toDate.day)
          .month(this.toDate.month - 1)
          .year(this.toDate.year)
          .hour(21)
          .second(0)
          .minute(0)
          .format("YYYY-MM-DDTHH:mm:ss")
      );
    }
    this.setWeekdays(true);
  }

  removeAddedShedule(timeIdx: number, dayIdx: number): void {
    this.weekdays[dayIdx].times.splice(timeIdx, 1);
    this.weekdays[dayIdx].rangeLables.splice(timeIdx, 1);
  }

  removeTime(index: number): void {
    this.weekdays[this.selectedCalendarDay].times.splice(index, 1);
    this.weekdays[this.selectedCalendarDay].rangeLables.splice(index, 1);
    switch (this.selectedCalendarDay) {
      case 0:
        this.schedule.dayOfWeek.sunday = "";
        break;
      case 1:
        this.schedule.dayOfWeek.monday = "";
        break;
      case 2:
        this.schedule.dayOfWeek.tuesday = "";
        break;
      case 3:
        this.schedule.dayOfWeek.wednesday = "";
        break;
      case 4:
        this.schedule.dayOfWeek.thursday = "";
        break;
      case 5:
        this.schedule.dayOfWeek.friday = "";
        break;
      case 6:
        this.schedule.dayOfWeek.saturday = "";
        break;
    }
    this.OnWeekdaysValid();
  }

  onBlackoutDateSelection(date: NgbDate) {
    this.blackoutDate = date;
  }

  checkForBlackoutDupes(date: any): boolean {
    for (let day of this.blackoutDates) {
      if (moment(date).isSame(day.date, "day")) {
        return true;
      }
    }
  }

  addBlackoutDates() {
    const date = moment()
      .date(this.blackoutDate.day)
      .month(this.blackoutDate.month - 1)
      .year(this.blackoutDate.year);
    if (!this.checkForBlackoutDupes(date)) {
      this.blackoutDates.push({
        date,
        label: date.format("MMM D, YYYY"),
      });
    }
  }

  removeBlackoutDate(index: number) {
    this.blackoutDates.splice(index, 1);
  }

  OnWeekdaysValid() {
    this.weekdaysValid = this.weekdays.some((x) => !x.times.length);
  }

  get nextBtnValid() {
    let valid = false;
    switch (this.currentStep) {
      case 0: {
        valid = this.agentForm.valid;
        break;
      }
      case 1: {
        valid = true;
        break;
      }
      case 2: {
        valid = this.sellerForm.valid && this.dateForm.valid;
        break;
      }
      case 3: {
        valid =
          !this.errorStartTime &&
          !this.errorEndTime &&
          this.dateForm.get("st").value &&
          this.dateForm.get("et").value &&
          this.weekdays.some((x) => x.times.length);
        break;
      }
      case 4: {
        valid = true;
        break;
      }
      default: {
        valid = false;
      }
    }
    return valid;
  }

  get submitBtnValid() {
    return (
      this.agentForm.valid &&
      this.sellerForm.valid &&
      this.listingForm.valid &&
      this.dateForm.valid
    );
  }

  nextStep() {
    if (this.currentStep < 4) {
      if (!this.nextBtnValid) {
        return;
      }
      this.currentStep += 1;
      this.isSubmitted = false;
      this.currentHeader = this.steps[this.currentStep].header;
      this.steps[this.currentStep - 1].isComplete = true;
      /* setTimeout(() => {
        this.hoizontallyScroll();
      }, 300); */
    }
  }

  previousStep() {
    if (this.currentStep !== 0) {
      this.currentStep -= 1;
      this.currentHeader = this.steps[this.currentStep].header;
      this.steps[this.currentStep].isComplete = false;
      /* setTimeout(() => {
        this.hoizontallyScroll();
      }, 300); */
    }
  }

  buildPscs(): Promise<void> {
    return new Promise((resolve, reject) => {
      let startDate = moment([
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day,
      ]);
      const endDate = moment([
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day,
      ]);
      const days = endDate.diff(startDate, "d");

      let passedDays = 0;
      for (let i = 0; i < days + 1; i++) {
        const dayOfWeek = startDate.day();
        let isBlackout = false;
        // --macstermind-- console.table(this.weekdays[dayOfWeek].times);
        for (let time of this.weekdays[dayOfWeek].times) {
          // --macstermind-- console.log("TIME START: " + new Date(time.start).getHours());
          // --macstermind-- console.log(typeof time.start);
          let startTime = new Date(time.start);
          let endTime = new Date(time.end);
          let timeStart = moment()
            .month(startDate.month())
            .year(startDate.year())
            .date(startDate.date())
            .hour(startTime.getHours())
            .minute(startTime.getMinutes())
            .seconds(0);

          let timeEnd = moment()
            .month(startDate.month())
            .year(startDate.year())
            .date(startDate.date())
            .hour(endTime.getHours())
            .minute(endTime.getMinutes())
            .seconds(0);

          if (endTime.getMinutes() !== 0 && endTime.getHours() === 21) {
            console.log("found bad time!");
          }

          for (let date of this.blackoutDates) {
            if (moment(date.date).isSame(timeStart, "date")) {
              isBlackout = true;
              //console.log('Found blackout date!');
            }
          }

          if (!isBlackout) {
            while (
              timeStart.isBefore(timeEnd) &&
              timeStart.isSameOrAfter(timeStart)
            ) {
              const timeSlot = {
                sdt: timeStart.format("YYYY-MM-DDTHH:mm:ss"),
                edt: timeStart.add(15, "m").format("YYYY-MM-DDTHH:mm:ss"),
              };

              // --macstermind-- console.log("TIME SLOT END DATE: " + timeSlot.edt);
              // --macstermind-- console.log("TIME SLOT END DATE: " + timeEnd);
              if (moment(timeSlot.edt).isSameOrBefore(timeEnd)) {
                this.weekDatesAndTimes.push(timeSlot);
              } else {
                console.log("Error");
              }
            }
          }
        }
        startDate = startDate.add(1, "d");
        passedDays += 1;
      }
      //console.log(passedDays);
      resolve();
    });
  }

  get pscs() {
    return this.dateForm.get("pscs") as FormArray;
  }

  submitLightningShowing() {
    // --macstermind-- console.log("WEEKDAYS BEFORE: ", this.weekdays);
    this.pscs.clear();
    this.weekDatesAndTimes = [];
    this.buildPscs().then((x) => {
      const weeklySchedule = {
        sunday: this.weekdays[0].rangeLables.join(","),
        monday: this.weekdays[1].rangeLables.join(","),
        tuesday: this.weekdays[2].rangeLables.join(","),
        wednesday: this.weekdays[3].rangeLables.join(","),
        thursday: this.weekdays[4].rangeLables.join(","),
        friday: this.weekdays[5].rangeLables.join(","),
        saturday: this.weekdays[6].rangeLables.join(","),
      };
      const blackouts = [];
      this.schedule.blackoutDates = [];
      for (let date of this.blackoutDates) {
        this.schedule.blackoutDates.push(date.date.format("MMM D, YYYY"));
      }
      // --macstermind-- console.log("weeklySchedule", weeklySchedule);
      this.schedule.dayOfWeek.sunday = weeklySchedule.sunday;
      this.schedule.dayOfWeek.monday = weeklySchedule.monday;
      this.schedule.dayOfWeek.tuesday = weeklySchedule.tuesday;
      this.schedule.dayOfWeek.wednesday = weeklySchedule.wednesday;
      this.schedule.dayOfWeek.thursday = weeklySchedule.thursday;
      this.schedule.dayOfWeek.friday = weeklySchedule.friday;
      this.schedule.dayOfWeek.saturday = weeklySchedule.saturday;

      const sellTemp = this.sellerForm.value;
      if (sellTemp.showingOpt == "bsms") {
        sellTemp["bsms"] = true;
        sellTemp["beml"] = true;
      }
      if (sellTemp.showingOpt == "baccept") {
        sellTemp["baccept"] = true;
      } else {
        sellTemp["baccept"] = false;
      }
      if (sellTemp.showingOpt == "iamng") {
        sellTemp["iamng"] = true;
      }
      delete sellTemp["showingOpt"];
      const payload = {
        ...this.agentForm.value,
        ...this.listingForm.value,
        ...sellTemp,
        ...this.dateForm.value,
        iatext: this.schedule,
        //pscs: this.weekDatesAndTimes,
        // propertyShowing: this.propertyShowing
      };
      // --macstermind-- console.log(payload);
      this.proxyService.registerLightningProperty(payload).subscribe(res => {
        if (res && res.isSuccess) {
          this.lightningComplete = true;
        } else {
          this.lightningComplete = false;
        }
      });
    });
  }
}
