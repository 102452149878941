import {Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {ProxyServiceService} from 'src/app/services/proxy-service.service';
import {Router} from '@angular/router';
import {IPropertyListingModel} from 'src/app/models/property-listing';
import {DialogService} from 'primeng/api';
import {AlertMessageDialogComponent} from 'src/app/dialogs/alert-message-dialog/alert-message-dialog.component';
import {Subscribable, Subscription} from 'rxjs';
import { SearchType } from 'src/app/common-search/common-search-type-enum';
import { TokenCacheService } from 'src/app/services/token-cache.service';
import { LoginService } from 'src/app/services/login.service';
import { ConfirmProfessionalScheduleComponent } from 'src/app/dialogs/confirm-professional-schedule/confirm-professional-schedule.component';
import { AppInfoService } from 'src/app/services/app-info.service';

@Component({
  selector: 'app-banner-section',
  templateUrl: './banner-section.component.html',
  styleUrls: ['./banner-section.component.less'],
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None
})
export class BannerSectionComponent implements OnInit, OnDestroy {
  search: string = '';
  isPerformingSearch: boolean;
  filteredProfessionals: any[] = [];

  options: any = {
    types: [],
    componentRestrictions: {country: 'US'}
  };

  searchOptions: any[] = [
    {label: 'Search for Homes', isSelected: true, value: SearchType.Property},
    {label: 'Search for Professionals', isSelected: false, value: SearchType.Professional}
  ];
  selectedSearchOption: any = this.searchOptions.find(c => c.isSelected);
  subsCurrentListings: Subscription;

  constructor(
      private service: ProxyServiceService,
      private router: Router,
      public dialogService: DialogService,
      public tokenCacheService: TokenCacheService,
      public loginService: LoginService,
      public appInfo: AppInfoService
    ){

      this.subsCurrentListings = this.service.currentListings.subscribe(
        (proplist: IPropertyListingModel[]) => {
          this.isPerformingSearch = false;
          if (proplist.length > 0) {
            this.router.navigate(['search-results']);
          } else {
            if (this.search) {
              const ref = this.dialogService.open(AlertMessageDialogComponent, {
                styleClass: 'alert-msg-dialog',
                data: {
                  icon: 'pi-exclamation-triangle',
                  message: 'Sorry, we couldn\'t find ',
                  subtitle: 'Try again using a more valid search.',
                  parameter: this.search,
                  buttonOptions: {
                    ok: {
                      label: 'OK',
                      command: () => { ref.close() }
                    }
                  }
                },
                header: 'Error during search',
                transitionOptions: '1ms',
              });
            }
          }
        });
    }

  onSelectSearchOption(option: any) {
    this.searchOptions.forEach(o => (o.isSelected = false));
    option.isSelected = true;
    this.selectedSearchOption = option;
  }

  onSearchPerformed(event: any) {
    this.isPerformingSearch = true;
    this.search = event.value.trim();
    this.service.getPropertyListings(this.search, '', event.metadata).subscribe();
  }

  onProfessionalSearchPerformed(event: any) {

    if (!this.isUserAuthenticated()) {
      return;
    }

    this.isPerformingSearch = true;

    let lastUsedAgent = this.loginService.getLastUsedAgent(event.value.professionalSearchField.type);
    let lastUsedAgentId = lastUsedAgent ? lastUsedAgent.AgentId : null;

    let schedulePayload = {
      LeadSource: 'Web',
      LeadReason: 'Info',
      AgentId: lastUsedAgentId,
      LeadType: event.value.professionalSearchField.type,
      LeadDescription: '',
      MlsAgent:'',
      ListingState: '',
      RequestDate: '',
      RequestedHours: 0.0,
      Lat: event.value.lat,
      Lng: event.value.lng,
      Address: event.value.professionalSearchAddressField
    };

    this.service.scheduleAppointment(schedulePayload).subscribe((response: any) => {
      this.isPerformingSearch = false;

      const ref = this.dialogService.open(ConfirmProfessionalScheduleComponent, {
        data: {
          user: this.loginService.user,
          professional: event.value.professionalSearchField,
          buttonOptions: {
            close: {
              label: 'Close',
              command: () => { ref.close() }
            }
          }
        },
        transitionOptions: '1ms',
      });
    },
    (error: any) => {
      let e = error;
      this.isPerformingSearch = false;

      const ref = this.dialogService.open(AlertMessageDialogComponent, {
        styleClass: 'alert-msg-dialog',
        data: {
          icon: 'pi-exclamation-triangle',
          message: 'There was a problem scheduling an IA9 agent at the moment.',
          subtitle: 'Please try again shortly.',
          buttonOptions: {
            ok: {
              label: 'OK',
              command: () => { ref.close() }
            }
          }
        },
        header: 'Error Scheduling Professional',
        transitionOptions: '1ms',
      });
    });
  }

  ngOnInit() {
    // pre-load the professional memberships
    this.service.getProfessionalMemberships(0, '').subscribe((professionals: any[]) => {
      this.filteredProfessionals = professionals;
    });
  }

  ngOnDestroy() {
    // todo: unsubscribe from the service
    this.subsCurrentListings.unsubscribe();
  }

  goToDownloadPage() {
    this.router.navigate(['download']);
  }

  goToRiders(){
    this.router.navigate(['qrcode-rider']);
  }

  onAutoCompletePerformed(event: any) {
    this.service.getProfessionalMemberships(null, event.query).subscribe((professionals: any[]) => {
      this.filteredProfessionals = professionals;
    });
  }

  isUserAuthenticated() {
    if (!this.tokenCacheService.token) {
      const ref = this.dialogService.open(AlertMessageDialogComponent, {
        styleClass: 'alert-msg-dialog',
        data: {
          icon: 'pi-exclamation-triangle',
          message:
            'You must be logged in in order to schedule an IA9 Professional.',
          subtitle: 'Click on the button below in order to log in.',
          buttonOptions: {
            ok: {
              label: 'OK, Log Me In',
              command: () => {
                this.loginService.login();
              }
            }
          }
        },
        header: 'Error Scheduling Professional',
        transitionOptions: '1ms'
      });

      return false;
    }

    return true;
  }
}
